export const NEWSLETTER = [

  {
    name: 'Newsletter June 2024',
    image: 'https://www.nitw.ac.in/july2024newsletter/assets/images/ns1/1.png',
    link: ' https://www.nitw.ac.in/july2024newsletter/ '
  },
  {
    name: 'Newsletter March 2024',
    image: 'https://nitw.ac.in/api/static/files/March_Newsletter_2024_2024-5-9-11-23-13.jpg',
    link: ' https://www.nitw.ac.in/mar2024newsletter/ '
  },



    {
        name: 'Newsletter December 2023',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_December_2023_2024-2-12-11-42-4.png',
        link: 'https://nitw.ac.in/decnewsletter/'
      },


// 2
      {
        name: 'Newsletter September 2023',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_September_2023_2024-2-14-18-8-29.jpg',
        link: 'https://nitw.ac.in/septnewsletter/'
      },


   // 3 
      {
        name: 'Newsletter June 6 2023',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_June_6_2023_2024-2-14-18-21-29.jpg',
        link: 'https://nitw.ac.in/jun2023newsletter/'
      },

// 4

      {
        name: 'Newsletter June 12 2023',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_June_12_2023_2024-2-14-18-21-49.jpg',
        link: 'https://nitw.ac.in/jun22023decnewsletter/'
      },

// 5
      {
        name: 'Newsletter Jan - March 2023',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_Jan_-_March_2023_2024-2-14-18-22-9.jpg',
        link: 'https://nitw.ac.in/marchnewsletter/'
      },


// 6

      {
        name: 'Newsletter Oct - Dec 2022',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_Oct_-_Dec_2022_2024-2-14-18-22-27.jpg',
        link: 'https://nitw.ac.in/dec2022newsletter'
      },

// 7

      {
        name: 'Newsletter July - Sept 2022',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_July_-_Sept_2022_2024-2-14-18-22-45.jpg',
        link: 'https://nitw.ac.in/sept2022newsletter/'
      },

// 8


      {
        name: 'Newsletter September 2022',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_September_2022_2024-2-14-18-23-2.jpg',
        link: 'https://nitw.ac.in/sep2022newsletter/'
      },

// 9


      {
        name: 'Newsletter July 2022',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_July_2022_2024-2-14-18-23-17.jpg',
        link: 'https://nitw.ac.in/jul2022newsletter'
      },


// 10


      {
        name: 'Newsletter March 2022',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_March_2022_2024-2-14-18-23-37.jpg',
        link: 'https://nitw.ac.in/mar2022newsletter'
      },


// 11


      {
        name: 'Newsletter December 2021',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_December_2021_2024-2-14-18-23-56.jpg',
        link: 'https://nitw.ac.in/dec2021newsletter'
      },


// 12



      {
        name: 'Newsletter October 2021 ',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_October_2021_2024-2-14-18-24-16.jpg',
        link: 'https://nitw.ac.in/oct2021newsletter'
      },


// 13



      {
        name: 'Newsletter Issue 2 - 2021  ',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_Issue_2_-_2021_2024-2-14-18-24-35.jpg',
        link: 'https://nitw.ac.in/issue22021newsletter'
      },

// 14

      {
        name: 'Newsletter March 2021      ',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_March_2021_2024-2-14-18-24-55.jpg',
        link: 'https://nitw.ac.in/mar2021newsletter'
      },

// 15

      {
        name: 'Newsletter November 2020    ',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_November_2020_2024-2-14-18-25-12.jpg',
        link: 'https://nitw.ac.in/nov2020newsletter'
      },

// 16
      {
        name: 'Newsletter August 2020    ',
        image: 'https://nitw.ac.in/api/static/files/Newsletter_August_2020_2024-2-14-18-25-27.jpg',
        link: 'https://nitw.ac.in/aug2020newsletter/'
      },
    // Your newsletter data here
  ];
  export const R_D_NEWSLETTER = [

    {
      name: 'September 2024',
      image: 'https://nitw.ac.in/api/static/files/randdnov1_2024-11-13-10-52-46.jpg',
      link: ' https://www.nitw.ac.in/sep2024rdnews/ '
    }
  ]
  
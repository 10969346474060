import { useState } from "react";
import '../css/cseDeptStyle.css';
const ChemicalDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/Chemical_Engineering_Department_2023-10-16-10-44-11.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected="false"> <span>Awards and Honours</span> </a>
                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>

                            </div>
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ch-vidyasagars"
                                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-vidyasagars" alt="" className="img-fluid" width="190" height="220" />
                                <h6>Prof. Vidyasagar Shilapuram</h6>
                            </a>
                                <p className="justified-text"> Head of the Department <br /> <a href="mailto:chemical_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>chemical_hod@nitw.ac.in</a> <br /> 9490165346 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                                        <p className="justified-text">"Welcome to the Department of Chemical Engineering, a hub of innovation and transformation since 1964. The department offers a diverse array of academic programs, including B.Tech., M.Tech., and Ph.D., designed to meet industry demands and attract top talent. Our accomplished faculty, blending experience and youth, excel in teaching and groundbreaking research. The laboratories equipped with state-of-the-art facilities support experiential learning and industry-aligned curriculum.</p>

                                        <p className="justified-text">We bridge theory and practice through research initiatives and collaborations with leading industries. Our alumni hold key positions globally, reflecting our commitment to shaping influential leaders. We've reached milestones like our Golden Jubilee in 2014–15 and hosted renowned international conferences. The department garnered accolades over the years, including a prestigious 5-year accreditation from the National Board of Accreditation (NBA) for the B.Tech. program in 2015, subsequently another 6-year accreditation in 2022, affirming our dedication to excellence. Our M.Tech. program also earned a similar 5-year accreditation in 2016, emphasizing our commitment to providing world-class education.</p>


                                        <p className="justified-text">The department has evolved from an initial intake of 30 students to the current capacity of 126 in B.Tech, with dynamic specializations in tune with industry trends in M.Tech and a well-funded PhD program. We support PMRF fellowships, QIP, industry-sponsored PhD scholars, and post-doctoral fellows. Our state-of-the-art building, complete with green ambience and aesthetic landscaping, stands as a symbol of architectural elegance.</p>


                                        <p className="justified-text">
                                            Welcome to the world of chemical engineering, where intellectual rigor, experiential learning, and industry engagement come together. Join us as we push the boundaries of what's possible and continue redefining the art and science of chemical engineering."
                                        </p>
                                    </div>
                                                                        {/* NUMBERING START*/}
                                                                        <div class="row nirf-ranking-counter text-center">
                         
                         
                        
                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>23</span></h2>
                                 
                                 <h6 class="mt-0"><b>Faculty</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>7</span></h2>
                                 <h6 class="mt-0"><b>Staff</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>23</span></h2>
                                 <h6 class="mt-0"><b>PhD Research Scholars</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>454</span></h2>
                                 <h6 class="mt-0"><b>Undergraduate Students</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>38</span></h2>
                                 <h6 class="mt-0"><b>Postgraduate Students</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>422</span></h2>
                                 <h6 class="mt-0"><b>Publications</b></h6>
                             </div>
                         </div>



                       
                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>
                                            <p className="  mb-4">To attain global recognition in research and training students for meeting the challenging needs of chemical & allied industries and society.</p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                                                className="img-fluid" />
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">Providing high quality undergraduate and graduate education in tune with changing needs of industry.</p>
                                            <p className="  mb-2">Generating knowledge and developing technology through quality research in frontier areas of chemical and interdisciplinary fields.</p>
                                            <p className="  mb-2">Fostering industry-academia relationship for mutual benefit and growth.</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardCE/ChemicalEngineering"> Notices/Announcements</a>
                                        </h4>
                                    </div>
                                </div>
                            
                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                                    {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div className="row">


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>B.Tech</h3>
                                                        <p className="justified-text">

                                                            The Department offers 4 Year B.Tech. program in Chemical Engineering. The current intake of the course is 126. The admission is through common counselling process conducted by Joint Seat Allocation Authority (JoSAA) for the JEE Main qualified candidates. Foreign nationals are also admitted to this programme through schemes such as DASA, SII etc. The curriculum provides students with a thorough grasp of chemical engineering fundamentals, advanced techniques, and real-world applications. It aligns with industry needs, preparing graduates for immediate impact. Join us on an exciting academic journey to foster innovation, resilience, and expertise as a future chemical engineer in a dynamic world.

                                                        </p>

                                                        <p className="justified-text">The department offers Honors in Chemical Engineering, which is an optional credential that students can achieve by completing an extra 18 credits within Chemical Engineering.


                                                        </p>

                                                        <p className="justified-text">
                                                            The department also offers Minor in Chemical Engineering for the students of other disciplines. To earn this additional credential, students must complete 18 credits of courses in Chemical Engineering.


                                                        </p>
                                                    </div>
                                                    <p className="more"> <a className="more-info-link"
                                                        href="https://nitw.ac.in/api/static/files/Chemical_Engineering_2023-10-9-16-2-11.pdf" target="_blank"> B.Tech Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                                                    <p className="more"> <a className="more-info-link"
                                                        href="https://nitw.ac.in/api/static/files/Chemical_Engineering_2023-10-9-16-23-4.pdf" target="_blank"> B.Tech Honors Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                                                    <p className="more"> <a className="more-info-link"
                                                        href="https://nitw.ac.in/api/static/files/Chemical_Engineering_2023-10-9-16-24-2.pdf" target="_blank"> B.Tech Minors Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        {/* <h3>Postgraduate Program</h3>
                                                        <p className="justified-text">The Department of Chemical Engineering offers two Postgraduate Programs – M. Tech. in Chemical Engineering and M.Tech. in Systems and Control Engineering. From 2021-2022 academic year, the department has started a PG Diploma course in Systems and Control Engineering .</p> */}
                                                        {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                                                        <h3>M.Tech (Sustainable Energy Generation and Storage Technologies) </h3>
                                                        <p className="justified-text">
                                                            Welcome to our new and distinguished multidisciplinary M.Tech. program in 'Sustainable Energy Generation and Storage Technologies (SEG&ST)’ launched in 2023-24. This interdisciplinary program is open to individuals from diverse backgrounds, including Chemical Engineering, Energy Engineering, Petrochemical Engineering, Electrical Engineering, Mechanical Engineering, Metallurgical and Materials Engineering, Civil Engineering, Biotechnology, and allied disciplines. The curriculum offers a range of exciting electives for a personalized learning experience. Join us to pioneer change and shape a greener, more sustainable future in the field of energy innovation.
                                                        </p>

                                                    </div>
                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Sustainable_Energy_Generation_&_Storage_Technologies_2023-10-9-16-30-36.pdf"
                                                        target="_blank">M.Tech (SEG&ST) Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                    {/* <p className="more"> <a className=" more-info-link"
                                                        href="CSEDeptAssets/docs/re-reivsed-m-tech-cse-syllabus-20-8-21v10.pdf"
                                                        target="_blank"> M.Tech CSE Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}

                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M.Tech (Chemical Engineering)</h3>
                                                        <p className="justified-text"> M.Tech in Chemical Engineering (ChE) program offers a transformative learning experience through advanced research, specialized coursework, and practical applications in the complex realms of chemical engineering. Originally launched in 1968 as M.Tech. in Chemical Plant Engineering, it evolved in 1987 to focus on Computer Aided Process & Equipment Design (CAPED), and in 2015, it became M.Tech. in Chemical Engineering to meet industry demands. Admission is through CCMT for GATE-qualified students, with a few self-finance seats available.</p>

                                                    </div>
                                                    <p className="more"> <a className="more-info-link"
                                                        href="https://nitw.ac.in/api/static/files/Chemical_Engineering_2023-10-9-16-26-45.pdf"
                                                        target="_blank"> M.Tech (ChE) Scheme and Syllabus<i className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M.Tech (System and Control Engineering)</h3>
                                                        <p className="justified-text">
                                                            M.Tech. in Systems and Control Engineering (SCE) is designed to equip engineers with specialized skills in process control and systems engineering. Originally launched as M.Tech. in Process Control in 2016, it evolved into M.Tech. in Systems and Control Engineering in 2021 to meet industry needs. This is an interdisciplinary program admitting students with different backgrounds such as Instrumentation & Control Engineering, Electrical Engineering, Electronics and Instrumentation, Chemical Engineering, etc. It integrates education and research to stay updated in the field of Control and Automation. Join us to master systems and control engineering and shape the future of industries.

                                                        </p>

                                                    </div>
                                                    <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Systems_and_Control_Engineering_2023-10-9-16-28-35.pdf"
                                                        target="_blank"> M.Tech (SCE) Scheme and Syllabus   <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>PG. Diploma in Systems and Control Engineering </h3>
                                                        <p className="justified-text">The program spans for a period of one year and is with effect from 2021-2022 academic year. The is a self sponsored program. This is an interdisciplinary program admitting students with different backgrounds such as Instrumentation & Control Engineering, Electrical Engineering, Electronics and Instrumentation, Chemical Engineering, etc…Education and research are closely integrated within this PG diploma program, which helps to stay aware of the latest developments in the field of Control and Automation. This course is also open to people working in industry. The program is only offered under self-sponsored category with an annual intake of 10 seats. </p>
                                                        {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>
                                                    {/* <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/Sustainable_Energy_Generation_and_Storage_Technologies_2023-7-28-16-16-14.pdf"
                                                        target="_blank">Sustainable Energy Generation & Storage Technologies  <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D</h3>
                                                        <p className="justified-text">Embark on an intellectual journey with our Ph.D. program in Chemical Engineering department. Explore a wide range of cutting-edge research areas, including sustainable energy, advanced carbon materials, polymer composites, 3D printing, water treatment, molecular simulations, fuel cells, batteries, electrocatalysis, process optimization, biochemical engineering, petrochemical engineering, enzymatic processes, and AI & ML applications. Our program is funded through research projects and/or by Ministry of Education and is also recognized under QIP, offering a comprehensive research experience guided by accomplished mentors. </p>

                                                        {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>
                                                    {/* <p className="more"> <a className="buttton more-info-link"
                                                        href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                        target="_blank"> Rules and Regulations of Ph.D. Program <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>



                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div className="row laboratory-facilities">
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            
                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_2_2023-11-20-10-51-18.jpg" alt="" className="img-fluid mb-5"/>  
                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_3_2023-11-20-10-51-41.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_4_2023-11-20-10-52-4.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_5_2023-11-20-10-52-28.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_6_2023-11-20-10-52-48.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_7_2023-11-20-10-53-10.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_8_2023-11-20-10-53-34.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_9_2023-11-20-10-53-54.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_10_2023-11-20-10-54-18.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_11_2023-11-20-10-54-42.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_12_2023-11-20-10-55-7.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_13_2023-11-20-10-55-29.jpg" alt="" className="img-fluid mb-5"/>  

                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_14_2023-11-20-10-55-54.jpg" alt="" className="img-fluid mb-5"/>  


                                            <img src="https://nitw.ac.in/api/static/files/chemical_lab_15_2023-11-20-10-56-14.jpg" alt="" className="img-fluid mb-5"/>  

                                        </div>
                                      
                                            </div>
                                        </div>
                                




                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 className=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}

                                            <section id="profile-info">

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ch-sarat"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-sarat"
                                                                        target="_blank">Prof. A. Sarath Babu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9014023004 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:sarat@nitw.ac.in">sarat@nitw.ac.in</a> </p>
                                                                    <p className="justified-text">Modeling, Simulation and Optimization; Process Simulation using Aspen; Reaction Engineering; Thermodynamics; Energy management and CO2 Capture.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ch-avv"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16322/16322.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-avv"
                                                                        target="_blank">Prof. A. Venu Vinod</a>
                                                                    </h5> <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 2621 (O), 9440764497 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:avv@nitw.ac.in ">avv@nitw.ac.in </a>
                                                                    </p>
                                                                    <p className="justified-text">Fluidized bed bioreactors; Biological Wastewater Treatment; Fluidized bed Bioreactors; CFD; Heat Transfer using Nanofluids; Spout-fluid beds.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ch-kola"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-kola"
                                                                        target="_blank">Prof. Anand Kishore Kola</a> </h5>
                                                                    <span>Professor </span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9966541095, 8332969398 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a>
                                                                    </p>
                                                                    <p className="justified-text">Environmental Engineering, Energy, Advanced Separation processes, Modeling, Simulation and Optimization, Biochemical Engineering, Waste water treatment, Membrane separations, Reactive distillation, Inverse fluidization, Pharmaceuticals and nanotechnology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-anjanapa"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17014/17014.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-anjanapa"
                                                                            target="_blank">Prof. Anjana P A</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 08702462611 (Office) ; 8754306539 (Mobile) <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:anjanapa@nitw.ac.in ">anjanapa@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">High-temperature oxidation reaction; Catalysts for Energy and Environmental applications; Advanced oxidation processes</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-udaybhaskar"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16422/16422.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-udaybhaskar"
                                                                            target="_blank">Prof. G. Uday Bhaskar Babu</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969404 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:udaybhaskar@nitw.ac.in ">udaybhaskar@nitw.ac.in </a> </p>
                                                                        <p className="justified-text">Modelling and Simulation; Process Intensification; Process Control: Data-driven control, Factional order modelling and control, Fuel cell - Modelling and Control strategies, Waste water treatment
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-leela.manohar"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Dr._Leela_Manohar_2023-11-29-16-36-5.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-leela.manohar"
                                                                            target="_blank">Prof. Leela Manohar Aeshala</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8351988928 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:leela.manohar@nitw.ac.in ">leela.manohar@nitw.ac.in  </a> </p>
                                                                        <p className="justified-text">Conversion of CO2 to Fuel, Polymer Electrolytes, Electrocatalysts
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-manohar"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16930/16930.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-manohar"
                                                                            target="_blank">Prof. Manohar Kakunuri</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7288967777 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:manohar@nitw.ac.in">manohar@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Synthesis of Carbon Nanomaterials (Carbon thin films, Nanoparticles, and Nanofibers), Catalytic Graphitization, Carbon Xerogels, Energy Storage Devices (Li/Na/Al-ion Batteries, Supercapacitors), Electrospinning, Micropatterning (Photolithography), Soft lithography, Multi-scale Structures for Tunable Surface Wettability.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-murali"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Murali_Mohan_Seepana_2023-10-4-11-52-22.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-murali"
                                                                            target="_blank">Prof. Murali Mohan Seepana</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:murali@nitw.ac.in ">murali@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Energy Storage and Energy Conversion Technologies; Wastewater Treatment; Membrane Synthesis & Separations
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-naresht"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17015/17015.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-naresht"
                                                                            target="_blank">Prof. Naresh Thota</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9849448745  <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:naresht@nitw.ac.in ">naresht@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Reactive Distillation, Process Modelling and Simulations, Water Treatment Process Control, Molecular simulations, Self-assembly, Amphiphilic Peptides, Drug Delivery, Controlled Release Cargos, Water Desalination. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-phanikumar"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17108/17108.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-phanikumar"
                                                                            target="_blank">Prof. Phani kumar Pentyala</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9444321432<br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:phanikumar@nitw.ac.in ">phanikumar@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> Computational Catalysis, Computational Materials Science, Carbon Capture, Hybrid-MOF Materials, High-entropy Alloys, Machine Learning in Material Properties Finding.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-pskr
"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17012/17012.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-pskr
"
                                                                            target="_blank">Prof. Puttapati Sampath Kumar</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8497968889 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:pskr@nitw.ac.in ">pskr@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> Energy & Fuels, Gas Sensors, Waste to Energy, Safety Engineering , Energy Storage Devices.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-pvsuresh"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-pvsuresh"
                                                                            target="_blank">Prof. P. V. Suresh</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702462628 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:pvsuresh@nitw.ac.in">pvsuresh@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Fuel cells, Chemical Looping Combustion - CO2 capture technology, Computational Fluid Dynamics, Flow Batteries, Modelling and Simulation</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-praveen"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17065/17065.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-praveen"
                                                                            target="_blank">Prof. Praveen Kumar Bommineni</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9652941841 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:praveen@nitw.ac.in ">praveen@nitw.ac.in
                                                                            </a> </p>
                                                                        <p className="justified-text">Nucleation, crystallization and self assembly of colloidal particles, phase diagram calculations using computer simulations.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-raghuraj"
                                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyan_K_2023-10-9-17-23-49.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-raghuraj"
                                                                            target="_blank">Prof. Raghu Raja Pandiyan K</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8002486897 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:raghuraj@nitw.ac.in">raghuraj@nitw.ac.in</a>
                                                                        </p>
                                                                        <p className="justified-text">High Performance Thermoset Composites; Liquid Composite Moulding Processes; Materials Process Modeling and Simulation; Materials Performance Analysis; ASPEN Process Simulations; Bio-Polyol Hybrid Heat Transfer Nano-Fluids; Metal Nano Particles Biosynthesis. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-rajmohan"
                                                                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-rajmohan"
                                                                            target="_blank">Prof. Rajmohan K S</a> </h5>
                                                                        <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9994646610 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:rajmohan@nitw.ac.in ">rajmohan@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text"> Nanomaterials & Membranes for Energy & Water , High Temperature Fuel Cells, Redox Flow Batteries & Advanced Metal-air Batteries , Hydrogen Production & Storage, Biochemical Engineering, AOP, Hydrodynamic cavitation, Hybrid membrane processes, CO2 Reduction into hydrocarbons. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-ramyaaraga"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17069/17069.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-ramyaaraga"
                                                                            target="_blank">Prof. Ramya Araga</a>
                                                                        </h5> <span>Assistant Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 7702097777<br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:ramyaaraga@nitw.ac.in "> ramyaaraga@nitw.ac.in  </a>
                                                                        </p>
                                                                        <p className="justified-text">Synthesis of Nanomaterials; Advanced Oxidation Processes (AOPs); Photocatalysis; Wastewater Treatment</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
{/* 
                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16460"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16460/16460.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16460"
                                                                            target="_blank">Prof. Seshagiri Rao Ambati (On-Lien) </a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 0870 2462633 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:seshagiri@nitw.ac.in ">
                                                                                seshagiri@nitw.ac.in  </a> </p>
                                                                        <p className="justified-text">Process Control; Reinforcement Learning; Process Dynamics; Wastewater Treatment; Hybrid Energy Systems. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-shirish"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-shirish"
                                                                            target="_blank">Prof. Sonawane Shirish Hari</a>
                                                                        </h5> <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8702468626
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:shirish@nitw.ac.in "> shirish@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Acoustic and hydrodynamic cavitation; Nanomaterial synthesis using various physical and chemical methods; Nanomaterials for energy storage devices; Wastewater treatment using nanomaterials; Nanoemulsions and functional nanolattices; Advanced polymeric coatings and hybrid membrane processes; Process intensification using microreactors</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-srinath"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16325/16325.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-srinath"
                                                                            target="_blank">Prof. Srinath Suranani</a> </h5>
                                                                        <span>Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 9908888676
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto: srinath@nitw.ac.in "> srinath@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Thermal conversion of solid waste, bio fuels, Catalysis, Process Intensification , Process modelling and simulation, Nano technology, Micro reactors. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-ramsagar"
                                                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16465/16465.jpg" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-ramsagar"
                                                                            target="_blank">Prof. V. Ramsagar</a> </h5>
                                                                        <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 8332969406
                                                                            <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                            <a href="mailto:ramsagar@nitw.ac.in "> ramsagar@nitw.ac.in </a> </p>
                                                                        <p className="justified-text"> CO2 Capture Technologies – Calcium Looping & Chemical Looping Combustion, Green Energy Generation Technologies, Chemical Process Scheduling, Process Modelling & Simulation, Hybrid Separations, Heat Exchanger Networks</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 profile-info-individual">
                                                        <div className="row">
                                                            <div className="col-md-3"> <a
                                                                href="https://erp.nitw.ac.in/ext/profile/ch-vidyasagars"
                                                                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-vidyasagars" alt=""
                                                                    className="img-fluid" width="150" height="180" /> </a> </div>
                                                            <div className="col-md-9">
                                                                <div className="card ">
                                                                    <div className="data">
                                                                        <h5> <a href="https://erp.nitw.ac.in/ext/profile/ch-vidyasagars"
                                                                            target="_blank">Prof. Vidyasagar Shilapuram</a>
                                                                        </h5> <span>Associate Professor</span>
                                                                        <p className="contact-info"> <i className="fa fa-phone"
                                                                            aria-hidden="true"></i> 0870 2462634 <br /> <i
                                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                    href="mailto:vidyasagars@nitw.ac.in "> vidyasagars@nitw.ac.in </a>
                                                                        </p>
                                                                        <p className="justified-text">Chemical Engineering and allied areas.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>




                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_A._Jagadeesh_Kumar_2023-10-6-12-52-11.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri A. Jagadeesh Kumar</h6> <span>Senior Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969564 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jagadeesh@nitw.ac.in">jagadeesh@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_Md._Farooq_Azam_2023-10-6-12-53-54.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri Md. Farooq Azam</h6> <span>Junior Asst. (Upg)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969567 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mdfarooqazam@nitw.ac.in">mdfarooqazam@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_Bharat_Singh_2023-10-6-12-55-3.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri Bharat Singh</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8004444037 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bharats@nitw.ac.in">bharats@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Dr._S._Satyannarayana_2023-10-6-12-56-42.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Dr. S. Satyannarayana</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8688435550 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:satyann@nitw.ac.in">satyann@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_M._Uma_Maheshwar_Reddy_2023-10-6-12-57-36.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri M. Uma Maheshwar Reddy</h6> <span>Sr. Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9154762431 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:mandau@nitw.ac.in">mandau@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_N._Goutham_2023-10-6-12-58-42.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri N. Goutham</h6> <span>Technician
                                                                </span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7702516189 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ngoutham@nitw.ac.in">ngoutham@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_G._Ilaiah_2023-10-6-13-0-32.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri G. Ilaiah</h6>
                                                                <span>Caretaker SG-II</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9177155317 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ilaiahgaja@nitw.ac.in">ilaiahgaja@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_N._Sammaiah_2023-10-6-13-1-33.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri N. Sammaiah</h6> <span>Sr. Caretaker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9398676745 </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">

                                            <div className="row phd-std-row">

                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Yogesh_Patil_2023-11-1-13-9-56.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Advanced Oxidation Processes(AOPs) for wastewater treatment </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Yogesh Patil </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8668501914 <br /> <span> <a href="mailto:yp712123@student.nitw.ac.in ">yp712123@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Indranil_Dey_2023-11-1-16-42-44.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Control strategy for wastewater treatment plant  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Indranil Dey </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8670533812 <br /> <span> <a href="mailto:indracool.1990@gmail.com">indracool.1990@gmail.com</a></span> </p>


                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/K_Sunil_Kumar_2023-11-1-16-44-34.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Studies on Stage separation system  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">K Sunil Kumar  </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9492924910 <br /> <span> <a href="mailto:ksunildrdo@gmail.com">ksunildrdo@gmail.com</a></span> </p>


                                                    </div>
                                                </div>



{/* 
                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Irshad_2023-11-1-16-46-27.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Energy storage systems  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Irshad </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7207219424 <br /> <span> <a href="mailto:irshad.ash.khan@gmail.com">irshad.ash.khan@gmail.com</a> </span></p>


                                                    </div>
                                                </div> */}

                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Upare_Vishal_Baburao_2023-11-1-16-47-39.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Study of Nanostructured Catalyst for Carbon Monoxide Oxidation Reaction</b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Upare Vishal Baburao </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9623235854 <br /><span>  <a href="mailto:uv721082@student.nitw.ac.in">uv721082@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Snigdha_Saha_2023-11-1-16-49-4.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Vanadium Redox Flow Batteries </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Snigdha Saha  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8319245176 <br /> <span> <a href="mailto:snigdhasaha1023@gmail.com">snigdhasaha1023@gmail.com</a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Mullapudi_Siva_2023-11-1-16-50-17.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Identification and Control strategies to improve performance of PEM Fuelcell System </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Mullapudi Siva</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7730826079 <br /> <span> <a href="mailto:ms3456phd@student.nitw.ac.in">ms3456phd@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/V_Tarun_2023-11-1-16-51-36.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Advanced composites </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">V Tarun </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8897340165 <br /> <span> <a href="mailto:tarun_jrf0623@nitw.ac.in">tarun_jrf0623@nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Rayavarapu_Appala_Naidu_2023-11-1-16-52-46.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Molecular simulation of soft and hard particles  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Rayavarapu Appala Naidu</h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8247881158 <br /> <span> <a href="mailto:ra22chr1r01@student.nitw.ac.in">ra22chr1r01@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Rahul_B_Gaikwad_2023-11-1-16-54-6.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Identification of process using relay feedback approach  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Rahul B Gaikwad </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7095621899 <br /> <span> <a href="mailto:grahul6@student.nitw.ac.in">grahul6@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Sivaprakash_S_2023-11-1-16-55-19.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Development of Electrically conductive adhesives  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Sivaprakash S </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9940733520 <br /> <span> <a href="mailto:ss21chrer06@student.nitw.ac.in">ss21chrer06@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>






                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/A_Adithya_2023-11-1-16-57-31.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Process Control  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">A Adithya </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9704112891 <br /> <span> <a href="mailto:aadithya.nitw@gmail.com">aadithya.nitw@gmail.com</a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Kiran_Donthula_2023-11-1-16-58-54.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Energy Storage and Conversion  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Kiran Donthula </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9032145552 <br /> <span> <a href="mailto:kirandonthulaa@gmail.com">kirandonthulaa@gmail.com</a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Malkapuram_Surya_Teja_2023-11-1-17-0-9.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Wastewater Treatment </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Malkapuram Surya Teja </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 6301254117 <br /> <span> <a href="mailto:ms720069@student.nitw.ac.in">ms720069@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Geddam_Rajesh_2023-11-1-17-1-30.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Modelling and simulation of waste water treatment plants </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Geddam Rajesh </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9000637850 <br /> <span> <a href="mailto:gr21chrer04@student.nitw.ac.in">gr21chrer04@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/B._Dinesh_2023-11-1-17-3-5.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Energy storage (Supercapacitors)  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">B. Dinesh </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7386043755 <br /> <span> <a href="mailto:bd720072@student.nitw.ac.in">bd720072@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/D_Pandarinath_2023-11-1-17-4-37.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Fire hazard analysis of pyrophoric materials  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">D Pandarinath </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8106560506 <br /> <span> <a href="mailto:dp22chr1p01@student.nitw.ac.in">dp22chr1p01@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Vaishnavi_Srinivasagopal_2023-11-1-17-5-58.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Biochemistry and wastewater treatment  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Vaishnavi Srinivasagopal </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9944104531 <br /> <span> <a href="mailto:vs23chr1r02@student.nitw.ac.in ">vs23chr1r02@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Thota_Kamala_2023-11-1-17-6-36.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Oil/water separation using nanofibers  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title">Thota Kamala  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9550802244<br /> <span> <a href="mailto:tk720068@student.nitw.ac.in ">tk720068@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Amala_J_2023-11-1-17-7-56.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Catalysts  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Amala J  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9496955280<br /> <span> <a href="mailto:aj22chr2r01@student.nitw.ac.in">aj22chr2r01@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Bidesh_Kirtania_2023-11-1-17-9-6.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Energy and Environment  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bidesh Kirtania </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8331842340<br /> <span> <a href="mailto:bideshk@student.nitw.ac.in">bideshk@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Bhagya_Lakshmi_Chinnam_2023-11-1-17-10-27.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Waste water treatment  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Bhagya Lakshmi Chinnam </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9177078029<br /> <span> <a href="mailto:BC712121@student.nitw.ac.in">BC712121@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Mrs._Suvarna_P._Dhongade_2023-11-1-17-11-48.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Waste water treatment  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Mrs. Suvarna P. Dhongade </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9960520092<br /> <span> <a href="mailto:sp22chr1q01@student.nitw.ac.in">sp22chr1q01@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>


                                                {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Zade_Anita_Dnyanba_2023-11-1-17-12-56.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Polymer Composites </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Zade Anita Dnyanba </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9834510255<br /> <span> <a href="mailto:azade@student.nitw.ac.in">azade@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div> */}





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Irshad_2023-11-1-17-15-15.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Energy storage systems  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Irshad </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7207219424<br /> <span> <a href="mailto:irshad@student.nitw.ac.in">irshad@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Priyanka_Anil_Jagnade_2023-11-1-17-16-46.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Design and Development of Al-ion Hybrid Capacitor </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Priyanka Anil Jagnade </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9552313143<br /> <span> <a href="mailto:jp23chr1r06@student.nitw.ac.in ">jp23chr1r06@student.nitw.ac.in  </a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Shabnam_Siddiqui_2023-11-1-17-18-9.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>3D Printing and Waste Water Treatment  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Shabnam Siddiqui </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8095749186<br /> <span> <a href="mailto:shabnamsiddiqui.che@bmsce.ac.in ">shabnamsiddiqui.che@bmsce.ac.in  </a> </span></p>


                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/P_Dilipkumar_2023-11-1-17-23-19.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Colloidal solution </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> P Dilipkumar  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8885858259<br /> <span> <a href="mailto:dilipkmr834@gmail.com  ">dilipkmr834@gmail.com   </a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Easam_Ramkishore_2023-11-1-17-25-3.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Photocatalyis  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Easam Ramkishore  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8332993163<br /> <span> <a href="mailto:kishore.dhora@gmail.com">kishore.dhora@gmail.com </a> </span></p>


                                                    </div>
                                                </div>





                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Hanumanthu_Jenne_2023-11-1-17-26-8.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Process control  </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Hanumanthu Jenne  </h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7013570933<br /> <span> <a href="hj712127@student.nitw.ac.in">hj712127@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ravindar_Nunavath_2023-11-1-17-27-46.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Industrial separation   </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ravindar Nunavath</h6>
                                                        <span>Part-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9436543811<br /> <span> <a href="rn23chr1p01@student.nitw.ac.in">rn23chr1p01@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Ragamaye_Tigiripalli_2023-11-1-17-29-16.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Chemical engineering   </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Ragamaye Tigiripalli </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 8341929916<br /> <span> <a href="mailto:rtigiripalli@student.nitw.ac.in ">rtigiripalli@student.nitw.ac.in </a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Dharavath_Narendhar_2023-11-1-17-30-32.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Pharmaceutics </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Dharavath Narendhar </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7780264947<br /> <span> <a href="mailto:dnaren22@gmail.com">dnaren22@gmail.com </a> </span></p>


                                                    </div>
                                                </div>




                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/Dubashi_Bharath_2023-11-1-17-31-41.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>Pharmaceutics </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> Dubashi Bharath  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 7893178965<br /> <span> <a href="mailto:Bharathdubashi@gmail.com">Bharathdubashi@gmail.com</a> </span></p>


                                                    </div>
                                                </div>



                                                <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                                                    <div className="phd-std-member">
                                                        <div className="phd-std-img">
                                                            <img src="https://nitw.ac.in/api/static/files/A_Ajay_Raj_2023-11-1-17-32-43.jpg" className="img-fluid image" alt="" width="150" height="180" />
                                                            <div className="overlay">
                                                                <div className="std-details ">
                                                                    <p className="justified-text">
                                                                        Research Area : <b>SOFC electrolyte material </b>
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h6 className="std-title"> A Ajay Raj  </h6>
                                                        <span>Full-Time Research Scholar</span>
                                                        <hr />

                                                        <p className="contact-info"> 9676953112<br /> <span> <a href="mailto:aa22chr1r04@student.nitw.ac.in">aa22chr1r04@student.nitw.ac.in</a> </span></p>


                                                    </div>
                                                </div>











                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">
                                        <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                            aria-labelledby="v-pills-Students-tab">
                                            <div className="row std-society">
                                                <div className="col-md-3 col-6">
                                                    <div className="card "> <a
                                                        href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16906"
                                                        target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt="" className="img-fluid" />
                                                    </a>
                                                        <div className="data"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16906"
                                                            target="_blank">
                                                            <h6>Prof. K. S. Rajmohan</h6> <span> Professor In-Charge<br></br> Chemical Engineering Students’ Society (ChESS) and IIChE Student Chapter </span>
                                                            <hr />
                                                        </a>
                                                            <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9994646610 <br /> <i
                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                    href="mailto:rajmohan@nitw.ac.in "
                                                                    style={{ color: "#808080" }}>rajmohan@nitw.ac.in </a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <div className="card "> <a
                                                        href="https://erp.nitw.ac.in/ext/profile/ch-raghuraj"
                                                        target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyan_K_2023-10-9-17-23-49.jpg" alt="" className="img-fluid" />
                                                    </a>
                                                        <div className="data"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/ch-raghuraj"
                                                            target="_blank">
                                                            <h6>Prof. Raghu Raja Pandiyan K</h6> <span> Treasurer <br></br> Chemical Engineering Students’ Society (ChESS) and IIChE Student Chapter </span>
                                                            <hr />
                                                        </a>
                                                            <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 8002486897 <br /> <i
                                                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                    href="mailto:raghuraj@nitw.ac.in"
                                                                    style={{ color: "#808080" }}>raghuraj@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">



                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Sustainable Energy Technologies
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16325/16325.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Srinath Suranani</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462624 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:srinath@nitw.ac.in">srinath@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Shirish H Sonawane</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462626 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:shirish@nitw.ac.in">shirish@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16322/16322.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Venu Vinod</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462621 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:avv@nitw.ac.in">avv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Sarath Babu </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462610 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sarat@nitw.ac.in">sarat@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. Anand Kishore</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462623 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. V. Suresh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462628 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsuresh@nitw.ac.in">pvsuresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vidyasagar Shilapuram</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462634 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vidyasagars@nitw.ac.in">vidyasagars@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16429/16429.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S. Murali Mohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:murali@nitw.ac.in">murali@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16422/16422.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Uday Bhaskar Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462629 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:udaybhaskar@nitw.ac.in">udaybhaskar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16465/16465.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V. Ramsagar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462632 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramsagar@nitw.ac.in">ramsagar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. S. Rajmohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462631 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rajmohan@nitw.ac.in">rajmohan@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16930/16930.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Manohar Kakunuri</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462627 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:manohar@nitw.ac.in">manohar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17014/17014.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Anjana P A </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462611 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:anjanapa@nitw.ac.in">anjanapa@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17015/17015.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Naresh Thota</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462647 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:naresh@nitw.ac.in">naresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17069/17069.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Ramya </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462620 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramyaaraga@nitw.ac.in">ramyaaraga@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17012/17012.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Sampath Kumar </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462622 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pskr@nitw.ac.in">pskr@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Separations and Process Intensification</h4>
                                            </header>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Shirish H Sonawane</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462626 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:shirish@nitw.ac.in">shirish@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16322/16322.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Venu Vinod</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462621 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:avv@nitw.ac.in">avv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Sarath Babu </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462610 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sarat@nitw.ac.in">sarat@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. Anand Kishore</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462623 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16325/16325.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Srinath Suranani</h6>

                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462624 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:srinath@nitw.ac.in">srinath@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vidyasagar Shilapuram</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462634 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vidyasagars@nitw.ac.in">vidyasagars@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16429/16429.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S. Murali Mohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:murali@nitw.ac.in">murali@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16422/16422.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Uday Bhaskar Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462629 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:udaybhaskar@nitw.ac.in">udaybhaskar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyan_K_2023-10-9-17-23-49.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raghu Raja Pandiyan K</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462603 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raghuraj@nitw.ac.in">raghuraj@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. S. Rajmohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462631 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rajmohan@nitw.ac.in">rajmohan@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16930/16930.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Manohar Kakunuri</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462627 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:manohar@nitw.ac.in">manohar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17069/17069.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Ramya </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462620 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramyaaraga@nitw.ac.in">ramyaaraga@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17012/17012.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Sampath Kumar </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462622 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pskr@nitw.ac.in">pskr@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>









                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Process Systems and Control Engineering</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16422/16422.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Uday Bhaskar Babu</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462629 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:udaybhaskar@nitw.ac.in">udaybhaskar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Shirish H Sonawane</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462626 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:shirish@nitw.ac.in">shirish@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. Anand Kishore</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462623 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16460/16460.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Seshagiri Rao (on-lien)</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462633 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:seshagiri@nitw.ac.in">seshagiri@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. V. Suresh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462628 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsuresh@nitw.ac.in">pvsuresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vidyasagar Shilapuram</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462634 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vidyasagars@nitw.ac.in">vidyasagars@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16429/16429.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S. Murali Mohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:murali@nitw.ac.in">murali@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16465/16465.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V. Ramsagar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462632 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramsagar@nitw.ac.in">ramsagar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyan_K_2023-10-9-17-23-49.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raghu Raja Pandiyan K</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462603 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raghuraj@nitw.ac.in">raghuraj@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. S. Rajmohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462631 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rajmohan@nitw.ac.in">rajmohan@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17065/17065.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Praveen Bommineni</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462649 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:praveen@nitw.ac.in">praveen@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17015/17015.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Naresh Thota</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462647 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:naresh@nitw.ac.in">naresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17012/17012.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Sampath Kumar </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462622 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pskr@nitw.ac.in">pskr@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Advanced Materials</h4>
                                            </header>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16930/16930.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Manohar Kakunuri</h6><span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462627 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:manohar@nitw.ac.in">manohar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Shirish H Sonawane</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462626 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:shirish@nitw.ac.in">shirish@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. Anand Kishore</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462623 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16325/16325.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Srinath Suranani</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462624 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:srinath@nitw.ac.in">srinath@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. V. Suresh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462628 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsuresh@nitw.ac.in">pvsuresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vidyasagar Shilapuram</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462634 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vidyasagars@nitw.ac.in">vidyasagars@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16429/16429.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S. Murali Mohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:murali@nitw.ac.in">murali@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16465/16465.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V. Ramsagar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462632 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramsagar@nitw.ac.in">ramsagar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyan_K_2023-10-9-17-23-49.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raghu Raja Pandiyan K</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462603 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raghuraj@nitw.ac.in">raghuraj@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. S. Rajmohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462631 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rajmohan@nitw.ac.in">rajmohan@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17065/17065.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Praveen Bommineni</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462649 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:praveen@nitw.ac.in">praveen@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17015/17015.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Naresh Thota</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462647 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:naresh@nitw.ac.in">naresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17069/17069.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Ramya </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462620 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramyaaraga@nitw.ac.in">ramyaaraga@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17012/17012.jpg" alt=""
                                                        className="img-fluid" width="150" height="180" /> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Sampath Kumar </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462622 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pskr@nitw.ac.in">pskr@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>



                                <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                    aria-labelledby="nav-AdjunctFaculty-tab">

                                    <div className="col-md-12 profile-info-individual">
                                        <div className="row">

                                            <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex">
                                                        <div className=""> <img src="https://nitw.ac.in/api/static/files/Prof._Tata_Narasinga_Rao_2023-10-20-18-32-20.png" alt=""
                                                            className="" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Tata Narasinga Rao </h6>

                                                            <span>Director, International Advanced Research Centre for Powder Metallurgy & New Materials (ARCI), Hyderabad</span>
                                                            <div className=" mt-2">
                                                                <p className="contact-info">  <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:tata@arci.res.in">director@arci.res.in, tata@arci.res.in</a> </p>
                                                                <p className="justified-text">Nanotechnology, Materials science, Energy materials, Li ion Batteries</p>

                                                                <p className="justified-text"><b> Website: </b> <a href="https://www.arci.res.in/people-pages/tn-rao-cv.pdf">https://www.arci.res.in/people-pages/tn-rao-cv.pdf</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex">
                                                        <div className=""> <img src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sharma_2023-10-20-18-32-39.png" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Pisupati V Sharma </h6>

                                                            <span>Professor of Energy and Mineral Engineering, and Chemical Engineering, Director, Center for Critical Minerals,
                                                                The Pennsylvania State University, USA</span>
                                                            <div className=" mt-2">
                                                                <p className="contact-info">  <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sxp17@psu.edu">sxp17@psu.edu</a> </p>
                                                                <p className="justified-text">Chemical kinetics, mass, and heat transfer in combustion processes; CFD modeling of combustion and air pollution systems; oxy-fuel combustion; coal processing and mineral matter transformations in gasification; coal, biomass, and petroleum coke gasification and recovery of rare earth and critical elements from secondary sources and industrial wastes</p>

                                                                <p className="justified-text"><b>Website: </b><a href="https://www.eme.psu.edu/directory/sarma-v-pisupati">https://www.eme.psu.edu/directory/sarma-v-pisupati</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>






                                            <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex">
                                                        <div className=""> <img src="https://nitw.ac.in/api/static/files/Prof._Ajay_K_Dalai_2023-10-20-18-32-55.png" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Ajay K Dalai </h6>

                                                            <span>Distinguished Professor and Canada Research Chair in Bioenergy & Environmentally Friendly Chemical Processing, Department of Chemical and Biological Engineering
                                                                College of Engineering, University of Saskatchewan, SK Canada</span>
                                                            <div className=" mt-2">
                                                                <p className="contact-info">  <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ajay.dalai@usask.ca">ajay.dalai@usask.ca</a> </p>
                                                                <p className="justified-text">Nanocatalysis, chemical reaction engineering, bio and petro-fuels, renewable energy, hydroprocessing, gas-to-liquid and environmental technologies</p>

                                                                <p className="justified-text"><b>Website:</b><a href="https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php">https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php</a></p>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>








                                        </div>
                                    </div>


                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                        aria-labelledby="v-pills-NotableAlumni-tab">


                                        <div className="row notable-alumni">
                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._K._Krishnaiah_2023-10-31-10-45-54.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.iittp.ac.in/dr-k-krishnaiah"
                                                            target="_blank">
                                                            <h6>Prof. K. Krishnaiah</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1973)<br />
                                                            <b>Present Position :</b> Retd. Professor@ IIT Madras; Professor, IIT Tirupati
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._P._S._V._Nataraj_2023-10-31-10-48-44.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.sc.iitb.ac.in/~nataraj/"
                                                            target="_blank">

                                                            <h6>Prof. P. S. V. Nataraj</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1979)<br />
                                                            <b>Present Position :</b> Professor, IIT Bombay
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Dr._P._G._Rao_2023-10-31-10-53-30.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="-"
                                                            target="_blank">

                                                            <h6>Dr. P. G. Rao</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1974)<br />
                                                            <b>Present Position :</b> Former President, IIChE, Independent Director, Bitchem Asphalt Technologies Limited, Guwahati, Former Director RRL Jorhat, Vice-Chancellor, University of  Science & Technology, Meghalaya
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>





                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._Kaushal_Rege_2023-10-31-10-56-1.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://search.asu.edu/profile/980478"
                                                            target="_blank">

                                                            <h6>Prof. Kaushal Rege</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1998)<br />
                                                            <b>Present Position :</b> Professor, Arizona State University, USA
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._Harish_Vashisht_2023-10-31-10-59-27.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="-"
                                                            target="_blank">

                                                            <h6>Prof. Harish Vashisht</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (2005)<br />
                                                            <b>Present Position :</b> Professor, University of New Hampshire, USA
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._Suresh_Santanam_2023-10-31-11-1-33.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://iac.syr.edu/html/about_us.html"
                                                            target="_blank">

                                                            <h6>Prof. Suresh Santanam</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1981)<br />
                                                            <b>Present Position :</b> Director, Industrial Assessment Centre, Syracuse University, USA
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._Prem_Bikkina_2023-10-31-11-4-13.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="-"
                                                            target="_blank">

                                                            <h6>Prof. Prem Bikkina</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1997)<br />
                                                            <b>Present Position :</b> Associate Professor, Harold Courson Chair in Petroleum Engg., Petroleum Program Coordinator, Oklahoma State University, USA
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._Vikas_Mahto_2023-10-31-12-9-23.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://petroleum.iitism.ac.in/pe_faculty_details"
                                                            target="_blank">

                                                            <h6>Prof. Vikas Mahto</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> M.Tech (ChE) (1999)<br />
                                                            <b>Present Position :</b> Professor, IIT (ISM) Dhanbad
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._Z_V_P_Murthy_2023-10-31-12-11-21.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.svnit.ac.in/web/department/chemical/faculty.php"
                                                            target="_blank">

                                                            <h6>Prof. Z V P Murthy</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1986)<br />
                                                            <b>Present Position :</b>  Professor, SVNIT Surat
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Prof._Prateek_K_Jha_2023-10-31-12-12-56.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://ch.iitr.ac.in/~CH/Prateek_Jha"
                                                            target="_blank">

                                                            <h6>Prof. Prateek K Jha</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (2004)<br />
                                                            <b>Present Position :</b>  Associate Professor, IIT Roorkee
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Mr._Venkat_Changavalli_2023-10-31-12-37-2.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.linkedin.com/in/venkatchangavalli/?originalSubdomain=in"
                                                            target="_blank">

                                                            <h6>Mr. Venkat Changavalli</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1975)<br />
                                                            <b>Present Position :</b>  Former Advisor to Govt. of UP, Former CEO, EMRI(‘108’)
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Mr._C.G_._Sethuram_2023-10-31-12-41-4.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.linkedin.com/in/sethuram-cg-06219ab2/?originalSubdomain=in"
                                                            target="_blank">

                                                            <h6>Mr. C. G . Sethuram (Gold Medalist)</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1976)<br />
                                                            <b>Present Position :</b>  Group CEO of Thirumalai Chemicals Ltd., Chennai
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>





                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Mr._K._Krishna_Mohan_Raju_2023-10-31-12-53-20.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="-"
                                                            target="_blank">

                                                            <h6>Mr. K. Krishna Mohan Raju</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1990)<br />
                                                            <b>Present Position :</b>  Former CEO, Sneha Diagnostics
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>





                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Mr._K._V_Siva_Rao_2023-10-31-12-54-56.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.linkedin.com/in/k-v-siva-rao-84bb9436/?originalSubdomain=in"
                                                            target="_blank">

                                                            <h6>Mr. K. V Siva Rao</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> M.Tech (ChE) (1997)<br />
                                                            <b>Present Position :</b>  Vice-President, Energy Solutions International (Emerson Company), Hyderabad
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>





                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Mr._N._Sridhar_Rao_2023-10-31-12-57-25.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="-"
                                                            target="_blank">

                                                            <h6>Mr. N. Sridhar Rao</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> -<br />
                                                            <b>Present Position :</b> Addl. DGP, Andhra Pradesh
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Ms._Sarala_Menon_2023-10-31-12-59-25.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="-"
                                                            target="_blank">

                                                            <h6>Ms. Sarala Menon (Bhaskaran)</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1983)<br />
                                                            <b>Present Position :</b> Executive Vice President, Colgate Palmolive, Mumbai
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Mr._Gaurav_Sharma_2023-10-31-13-0-46.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.linkedin.com/in/gaufire/"
                                                            target="_blank">

                                                            <h6>Mr. Gaurav Sharma</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (2010)<br />
                                                            <b>Present Position :</b> CEO, SaaS Labs, USA
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Mr._Madhav_Malladi_2023-10-31-13-2-5.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="https://www.linkedin.com/in/drmatmalladi/"
                                                            target="_blank">

                                                            <h6>Mr. Madhav Malladi</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1973)<br />
                                                            <b>Present Position :</b> President Global Business Development, Reliance Industries
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3 col-6">
                                                <div className="card "> <img src="https://nitw.ac.in/api/static/files/Dr._R._L._Umashankar_2023-10-31-13-4-20.jpg" alt="" className="img-fluid" width="150" height="180" />
                                                    <div className="data">

                                                        <a
                                                            href="-"
                                                            target="_blank">

                                                            <h6>Dr. R. L. Umashankar</h6></a>

                                                        <p className="justified-text"><b>Batch:</b> B.Tech (ChE) (1969)<br />
                                                            <b>Present Position :</b> Former MD, Lakshman Chemicals Davangere Pvt. Ltd.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>




                                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">
                                </div>

                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        {/* <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span> */}
                                    </div>


                                    {/* <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 

                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}





                                </div>






                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default ChemicalDeptPage;
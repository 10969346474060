import React from 'react';
import PageHeader from '../components/PageHeader';
// import './deansw.css'; // Update the CSS file name

const Senate = () => {
  return (

    <div className="container">

    <div className="row pt-3 ">
       
          <h5><b style={{color:"maroon"}}>Details of the Chairman and other members of Senate of National Institute of Technology, Warangal</b></h5>
      <div className="col-md-12 pt-4">
        <h4 className="htitle">CHAIRMAN</h4>
      </div>
      <div className="col-md-4 senate-main">
        <div className="senate-card">
        <a href="https://www.nitw.ac.in/page/?url=/Director/DirectorNITW/" target="_blank">
      <b>Prof. Bidyadhar Subudhi</b><br />
      <small>Director and Chairman BoG, NIT Warangal</small>
    </a>
        </div>
      </div>
      <div className="col-md-12 pb-2">
        <h4 className="htitle">SECRETARY</h4>
      </div>
      <div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://nitw.ac.in/page/?url=/NITWRegistrar/list" target="_blank">
      <b>Prof. N. V. Umamahesh</b><br />
      <small>Registrar, NIT Warangal</small>
    </a>
  </div>
</div>











      <div className="col-md-12 pb-2">
        <h4 className="htitle">EXTERNAL SENATORS</h4>
      </div>
      <div className="col-md-4 senate-main">
        <div className="senate-card">
          <b> Prof. Ashok Kumar Pradhan</b><br />
          <small>Dept. of Electrical Engineering,<br></br>
IIT Kharagpur</small>
        </div>
      </div>


      <div className="col-md-4 senate-main">
        <div className="senate-card">
          <b>Prof. Amit Prashant</b><br />
          <small>Dept. of Civil Engineering,<br></br>
          IIT Gandhinagar</small>
        </div>
      </div>


      <div className="col-md-4 senate-main">
        <div className="senate-card">
          <b>Prof. Sri Padmavati</b><br />
          <small>Dept. of Mathematics,<br></br>
          University of Hyderabad</small>
        </div>
      </div>



      <div className="col-md-12 pb-2">
        <h4 className="htitle">INTERNAL SENATORS </h4>
      </div>
      <div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/bt-simha_bt" target="_blank">
      <b>Prof. K. Narasimhulu </b><br />
      <small>Professor, Department of Biotechnology</small>
    </a>
  </div>
</div>
<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/bt-parcha" target="_blank">
      <b>Prof. P. Sreenivasa Rao </b><br />
      <small>Professor, Department of Biotechnology</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu" target="_blank">
      <b>Prof. R. Satish Babu </b><br />
      <small>Professor, Department of Biotechnology</small>
    </a>
  </div>
</div>
      

      
<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-baig" target="_blank">
      <b>Prof. Arif Ali Baig Moghal</b><br />
      <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>
      
      
<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-csrk" target="_blank">
    <b> Prof. C. S. R. K. Prasad </b><br />
          <small>Professor (HAG), Department of Civil Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-drseshu" target="_blank">
    <b> Prof. D. Ramaseshu  </b><br />
          <small>Professor (HAG), Department of Civil Engineering</small>
    </a>
  </div>
</div>





<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-kvreddy" target="_blank">
    <b> Prof. Venkata Reddy Keesara  </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-mhl" target="_blank">
    <b> Prof. M. Heera Lal  </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16195" target="_blank">
    <b> Prof. N. V. Umamahesh  </b><br />
          <small>Professor (HAG), Department of Civil Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-hari" target="_blank">
    <b> Prof. P Venkateswara Rao </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-phari" target="_blank">
    <b> Prof. P. Hari Krishna P </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-rateeshp" target="_blank">
    <b> Prof. P. Rathish Kumar </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-svrao" target="_blank">
    <b> Prof. S Venkateswara Rao </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-tdg" target="_blank">
    <b> Prof. T. D. Gunneswara Rao </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-vc" target="_blank">
    <b> Prof. Venkaiah Chowdary </b><br />
          <small>Professor, Department of Civil Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ch-sarat" target="_blank">
    <b> Prof. A. Sarath Babu </b><br />
          <small>Professor, Department of Chemical Engineering</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ch-avv" target="_blank">
    <b> Prof. A. Venu Vinod </b><br />
          <small>Professor, Department of Chemical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ch-kola" target="_blank">
    <b> Prof. K. Anand Kishore </b><br />
          <small>Professor, Department of Chemical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ch-pvsuresh" target="_blank">
    <b> Prof. P. V. Suresh </b><br />
          <small>Professor, Department of Chemical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ch-srinath" target="_blank">
    <b> Prof. S. Srinath </b><br />
          <small>Professor, Department of Chemical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ch-shirish" target="_blank">
    <b> Prof. Sonawane Shirish Hari </b><br />
          <small>Professor, Department of Chemical Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cs-chapram" target="_blank">
    <b> Prof. Ch. Sudhakar </b><br />
          <small>Professor, Department of Computer Science And Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cs-kramesh" target="_blank">
    <b> Prof. K. Ramesh </b><br />
          <small>Professor, Department of Computer Science And Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cs-prkrishna" target="_blank">
    <b> Prof. Pisipati Radha Krishna </b><br />
          <small>Professor, Department of Computer Science And Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cs-rpadma" target="_blank">
    <b> Prof. R. Padmavathy </b><br />
          <small>Professor, Department of Computer Science And Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cs-rbvs66" target="_blank">
    <b> Prof. R.B.V. Subrahmanyam </b><br />
          <small>Professor, Department of Computer Science And Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cs-rashrr" target="_blank">
    <b> Prof. Rashmi Ranjan Rout </b><br />
          <small>Professor, Department of Computer Science And Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cy-kashinath" target="_blank">
    <b> Prof. D. Kasinath </b><br />
          <small>Professor, Department of Chemistry</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cy-kvgobichem" target="_blank">
    <b> Prof. K. V. Gobi </b><br />
          <small>Professor, Department of Chemistry</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cy-patrisrilakshmi" target="_blank">
    <b> Prof. P. Venkata Sri Laxmi </b><br />
          <small>Professor, Department of Chemistry</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cy-venkatathrin" target="_blank">
    <b> Prof. Venkatathri Narayanan </b><br />
          <small>Professor, Department of Chemistry</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cy-vishnu" target="_blank">
    <b> Prof. Vishnu Shanker </b><br />
          <small>Professor, Department of Chemistry</small>
    </a>
  </div>
</div>





<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-lakshmi" target="_blank">
    <b> Prof. B. Lakshmi </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-vakula" target="_blank">
    <b> Prof. D. Vakula </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-anjan" target="_blank">
    <b> Prof. L. Anjaneyulu </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-nbr" target="_blank">
    <b> Prof. N. Bheema Rao </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-patri" target="_blank">
    <b> Prof. P. Sreehari Rao </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-ravikumar" target="_blank">
    <b> Prof. Ravi Kumar Jatoth </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16306" target="_blank">
    <b> Prof. S. Anuradha </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-kishoret" target="_blank">
    <b> Prof. T. Kishore Kumar </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>


{/* <div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-tvkhrao75" target="_blank">
    <b> Prof. T. V. K. Hanumanth Rao </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div> */}


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ec-vvmani" target="_blank">
    <b> Prof. V. Venkata Mani </b><br />
          <small>Professor, Department of Electronics & communication Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ee-blnraju" target="_blank">
    <b> Prof. B. L. Narasimha Raju</b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href=" https://erp.nitw.ac.in/ext/profile/ee-bkmurthy" target="_blank">
    <b> Prof. Bhagwan Krishna Murthy</b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ee-ch.venkaiah" target="_blank">
    <b> Prof. Ch. Venkaiah</b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ee-dvss" target="_blank">
    <b> Prof. D. V. S. S. Siva Sarma</b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ee-sailaja" target="_blank">
    <b> Prof. M. Sailaja Kumari</b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ee-nvn" target="_blank">
    <b> Prof. N. Viswanathan</b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ee-nvs" target="_blank">
    <b> Prof. N. V. Srikanth</b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16232" target="_blank">
    <b>Prof. S. Srinivasa Rao </b><br />
          <small>Professor, Department of Electrical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ee-sekhar" target="_blank">
    <b>Prof. V. T. Somasekhar </b><br />
          <small>Professor (HAG), Department of Electrical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/hs-madhavik24" target="_blank">
    <b>Prof. K. Madhavi </b><br />
          <small>Professor, Department of Humanities & Social Science</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-dsc" target="_blank">
    <b>Prof. D. Srinivasa Charya </b><br />
          <small>Professor, Department of Mathematics</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-ddutta" target="_blank">
    <b>Prof. Debashis Dutta</b><br />
          <small>Professor, Department of Mathematics</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-hprani" target="_blank">
    <b>Prof. Hari Ponnamma Rani</b><br />
          <small>Professor, Department of Mathematics</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-jvr" target="_blank">
    <b>Prof. J. V. Ramana Murthy</b><br />
          <small>Professor, Department of Mathematics</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-kasi" target="_blank">
    <b>Prof. K. N. S. Kasiviswanadham</b><br />
          <small>Professor, Department of Mathematics</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-avbabu" target="_blank">
    <b>Prof. A. Veeresh Babu</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-venu" target="_blank">
    <b>Prof. A. Venu Gopal</b><br />
          <small>Professor (HAG), Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-adepu_kumar7" target="_blank">
    <b>Prof. Adepu Kumar</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-satishben" target="_blank">
    <b>Prof. B. Satish Ben</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-cspr" target="_blank">
    <b>Prof. C. S. P. Rao</b><br />
          <small>Professor (HAG), Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-djayakrishna" target="_blank">
    <b>Prof. D. Jayakrishna</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-gap" target="_blank">
    <b>Prof. G. Amba Prasada Rao</b><br />
          <small>Professor (HAG), Department of Mechanical Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-gns" target="_blank">
    <b>Prof. G. Nagasrinivasulu</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-kiran" target="_blank">
    <b>Prof. K. Kiran Kumar</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-madhu" target="_blank">
    <b>Prof. K. Madhu Murthy</b><br />
          <small>Professor (HAG), Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-kvsaisrinath" target="_blank">
    <b>Prof. K. V. Sai Srinadh</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-lanka" target="_blank">
    <b>Prof. L. Krishnanand</b><br />
          <small>Professor (HAG), Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-jd" target="_blank">
    <b>Prof. M. Joseph Davidson</b><br />
          <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-selva" target="_blank">
    <b>Prof. N. Selvaraj</b><br />
    <small>Professor (HAG), Department of Mechanical Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-bangaru" target="_blank">
    <b>Prof. P. Bangaru Babu</b><br />
    <small>Professor (HAG), Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-subhash" target="_blank">
    <b>Prof. P. Subhash Chandra Bose</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-vamsikrishna" target="_blank">
    <b>Prof. P. Vamsi Krishna</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-pravi" target="_blank">
    <b>Prof. Puli Ravi Kumar</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-rnrao" target="_blank">
    <b>Prof. R. Narasimha Rao</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-sneni" target="_blank">
    <b>Prof. S. Srinivasa Rao</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-korla" target="_blank">
    <b>Prof. Srikanth Korla</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-harikumar" target="_blank">
    <b>Prof. V. Hari Kumar</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-vrkraju" target="_blank">
    <b>Prof. V. Rajesh Khana Raju</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-vsbabu" target="_blank">
    <b>Prof. V. Suresh Babu</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-vpcm80" target="_blank">
    <b>Prof. V. P. Chandramohan</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-vasu" target="_blank">
    <b>Prof. V. Vasu</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/me-yrk" target="_blank">
    <b>Prof. Y. Ravi Kumar</b><br />
    <small>Professor, Department of Mechanical Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/mm-asit" target="_blank">
    <b>Prof. Asit Kumar Khanra </b><br />
    <small>Professor, Department of Metallurgical & Material Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/mm-vanitha" target="_blank">
    <b>Prof. C. Vanitha </b><br />
    <small>Professor, Department of Metallurgical & Material Engineering</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/mm-gvsnr" target="_blank">
    <b>Prof. G. V. S. Nageswara Rao </b><br />
    <small>Professor, Department of Metallurgical & Material Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/mm-talari" target="_blank">
    <b>Prof. Mahesh Kumar Talari </b><br />
    <small>Professor, Department of Metallurgical & Material Engineering</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/mm-kishorebabu" target="_blank">
    <b>Prof. N. Kishore Babu </b><br />
    <small>Professor, Department of Metallurgical & Material Engineering</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/mm-nn" target="_blank">
    <b>Prof. N. Narasaiah </b><br />
    <small>Professor, Department of Metallurgical & Material Engineering</small>
    </a>
  </div>
</div>



{/* <div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16405" target="_blank">
    <b>Prof. P. Madhusudhana Reddy</b><br />
    <small>Professor, Department of Physical Education</small>
    </a>
  </div>
</div> */}


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404" target="_blank">
    <b>Prof. P. Ravi Kumar</b><br />
    <small>Professor, Department of Physical Education</small>
    </a>
  </div>
</div>


{/* <div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16810" target="_blank">
    <b>Prof. R. Dayanidhi</b><br />
    <small>Professor, Department of Physical Education</small>
    </a>
  </div>
</div> */}



{/* <div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ph-dinakar" target="_blank">
    <b>Prof. D. Dinakar</b><br />
    <small>Professor, Department of Physics</small>
    </a>
  </div>
</div> */}



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ph-haranath" target="_blank">
    <b>Prof. D. Haranath</b><br />
    <small>Professor, Department of Physics</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ph-ktr" target="_blank">
    <b>Prof. K. Thangaraju</b><br />
    <small>Professor, Department of Physics</small>
    </a>
  </div>
</div>





<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ph-drazeem2002" target="_blank">
    <b>Prof. P. Abdul Azeem</b><br />
    <small>Professor, Department of Physics</small>
    </a>
  </div>
</div>




<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ph-syamprasad" target="_blank">
    <b>Prof. P. Syam Prasad</b><br />
    <small>Professor, Department of Physics</small>
    </a>
  </div>
</div>


<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ph-sroy" target="_blank">
    <b>Prof. Sourabh Roy</b><br />
    <small>Professor, Department of Physics</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ph-tvraokmm" target="_blank">
    <b>Prof. T. Venkatappa Rao</b><br />
    <small>Professor, Department of Physics</small>
    </a>
  </div>
</div>



<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/sm-ramlal" target="_blank">
    <b>Prof. P. Ramlal</b><br />
    <small>Professor, Department of School of Management</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/sm-vrdevi" target="_blank">
    <b>Prof. V. Rama Devi</b><br />
    <small>Professor, Department of School of Management</small>
    </a>
  </div>
</div>

<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/cs-ravic" target="_blank">
    <b>Prof. S. Ravi Chandra</b><br />
    <small>Professor, Department of Computer Science and Engineering</small>
    </a>
  </div>
</div>
<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ce-hari" target="_blank">
    <b>Prof. P Hari Prasada Reddy </b><br />
    <small>Professor, Department of  Civil Engineering</small>
    </a>
  </div>
</div>
<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-abenerji" target="_blank">
    <b>Prof. A Benerji Babu</b><br />
    <small>Professor, Department of Mathematics </small>
    </a>
  </div>
</div>
<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-pm" target="_blank">
    <b>Prof. P. Muthu </b><br />
    <small>Professor, Department of Mathematics</small>
    </a>
  </div>
</div>
<div className="col-md-4 senate-main">
  <div className="senate-card">
    <a href="https://erp.nitw.ac.in/ext/profile/ma-rsselva" target="_blank">
    <b>Prof. R S Selvaraj</b><br />
    <small>Professor, Department of Mathematics</small>
    </a>
  </div>
</div>




    </div>
    </div>
  );
};

export default Senate;
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Slideshow from "../components/SlideShow";
import BreakingNews from "../components/BreakingNews";
import Announcements from "../components/Announcements";
import SecondaryNavbar from "../components/SecondaryNavbar";
import PlacementCard from "../components/PlacementCard";
import PlacementLogs from "../pages/PlacementLogs";
import { getCategoryWiseNotifications } from "../utils/getDeptData";
import CampusLife from "../components/campusLife";
import ProgressBar from "../components/progressBar";
import Research from "../components/Research";
import AboutNitw from "../components/AboutNitw";
import SocialFeed from "../components/SocialsFeed";
import { MainServerUrl } from "../BasePath";
import Newsletter from "../components/Newsletter";
import NewsScroll from "../components/NewsScroll";
import NewsEvents from "../constants/NewsEventsScrollData"
import PublicationsData from "../constants/PublicationsData"
import PatentsAndProjects from "../constants/PatentsAndProjects"
import PhdSeminars from "../components/PhdSeminars";
import NewScroll3 from "../components/NewScroll3";
import NewScroll4 from "../components/NewScroll4";
import NewScroll2 from "../components/NewScroll2";
import '../css/NewsScroll.css';
import PlacementCard2 from "../components/PlacementCard2";
import DirectorsTab from "../components/DirectorsTab";
import Patents from "../components/Patents";
import CampusActivity from "../components/CampusActivity";

function compareEvents(a, b) {
  if (a.event_date > b.event_date) {
    return -1;
  }
  if (a.event_date < b.event_date) {
    return 1;
  }
  return 0;
}

function compareImages(a, b) {
  if (a.created_date > b.created_date) {
    return -1;
  }
  if (a.created_date < b.created_date) {
    return 1;
  }
  return 0;
}

const HomePage = () => {
  // console.log(categoryWiseNotifications.news);
  const [data, setData] = useState({});
  const [news, setNews] = useState([]);
  const [tenders, setTenders] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [breakingNews, setBreakingNews] = useState([
    {
      title: "Loading",
      url: "#",
    },
  ]);

  useEffect(() => {
    if (data) {
      setNews(data.news);
      setTenders(data.tenders);
      setWorkshops(data.workshops);
      setBreakingNews(data.breakingNews);
    }
  }, [data]);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    // const controller = new AbortController();
    // const id = setTimeout(() => controller.abort(), 5000);

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // signal: controller.signal,
    };
    setNews([]);
    setTenders([]);
    setWorkshops([]);
    setBreakingNews([]);
    fetch(`${MainServerUrl}/api/notification/getall`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("response is not ok");
        }
        if (response.status !== 200) {
          throw new Error("Status code is not 200");
        }
        return response.json();
      })
      .then((data) => setData(getCategoryWiseNotifications(data)))
      .catch((err) => {
        console.log("falling to alternate server");
        fetch(
          `https://insti-web-backend.herokuapp.com/api/notification/getall`,
          requestOptions
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("response is not ok");
            }
            if (response.status !== 200) {
              throw new Error("Status code is not 200");
            }
            return response.json();
          })
          .then((data) => setData(getCategoryWiseNotifications(data)))
          .catch((err) => {
            console.log(err);
          });
      });
    // .finally(() => clearTimeout(id));

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const [newsEvent, setNewsEvent] = useState([])
  const [AwardsandRecongnition, setAwardsandRecongnition] = useState([])
  const [RecentPublication, setRecentPublication] = useState([])
  const [GalleryData, setGalleryData] = useState([])

  useEffect(() => {
    fetch("https://nitw.ac.in/api/nerp/getAll")
      .then(function (response) { return response.json() })
      .then(jsondata => {
        let News = jsondata.filter(obj => obj.type == "News and Events")
        News = News.sort(compareEvents);
        // console.log("NEWS", News)
        setNewsEvent(News);
        const Awards = jsondata.filter(obj => obj.type == "Awards and Recognitions")
        setAwardsandRecongnition(Awards)
        const Publication = jsondata.filter(obj => obj.type == "Recent Publications")
        setRecentPublication(Publication)
      })

  }, [])

  useEffect(() => {
    fetch("https://nitw.ac.in/api/gallery/getAll")
      .then(function (response) { return response.json() })
      .then(jsondata => {
        jsondata = jsondata.sort(compareImages)
        console.log("Gallery", jsondata)
        setGalleryData(jsondata)
        setImagesLoaded(true)
      })

  }, [])

  // console.log(newsEvent)
  // console.log(AwardsandRecongnition)
  // console.log(RecentPublication)

  return (
    <React.Fragment>
      <Helmet></Helmet>
      <Slideshow />


      <div >
      <div style={{ backgroundColor: "#F5F7FA", paddingLeft: "1.3vw", paddingRight: "1.3vw", borderBottom: "3px solid #ecaa32" }}>
        <BreakingNews breakingNews={breakingNews}></BreakingNews>
      </div>
      <DirectorsTab />

      <div className="font-change-applicable" style={{ backgroundColor: "#F5F7FA" }}>
        <div className="row EventsPublication">
          <div className="col-md-4 col-sm-12 font-change-applicable" style={{ marginLeft: "0px" }}><NewsScroll cardItems={newsEvent} autoPlay={true} carouselTitle={"Upcoming Events"} /></div>
          <div className="col-md-3 col-sm-12 font-change-applicable" style={{ margin: "0 0px" }}><NewScroll2 cardItems={AwardsandRecongnition} autoPlay={true} carouselTitle={"Awards and Recognition"} /></div>
          {/* <div className="col-md-4 col-sm-12 " style={{ margin: "0 0px" }}> <NewScroll2 cardItems={RecentPublication} autoPlay={false} carouselTitle={"Recent Publications"} /></div> */}
          <div className="col-md-4 col-sm-12 font-change-applicable" style={{ margin: "0 0px" }}>
          <PhdSeminars autoPlay={true} carouselTitle={"Seminars"} />
             {/* <NewScroll3 autoPlay={true} carouselTitle={"Seminars "} /> */}
             </div>
        </div>
      </div>

      {/* <SecondaryNavbar></SecondaryNavbar> */}

      <div className="page" style={{ backgroundColor: "#F5F7FA" }}>
        <div
          style={{
            width: "80vw",
            margin: "auto",
          }}
        >
          <div className="row">
            <Announcements
              data={news || []}
              title="Notices"
              start={0}
            ></Announcements>
            <Announcements
              data={workshops || []}
              title="Workshops & Conferences"
              start={1}
            ></Announcements>
            <Announcements
              data={tenders || []}
              title="Tenders"
              start={2}
            ></Announcements>
          </div>
        </div>

        <div>
          <PlacementCard2></PlacementCard2>
        </div>
        <div>
          <PlacementLogs></PlacementLogs>
        </div>

        {/* <div>
          <AboutNitw />
        </div> */}



        {/* <div>
          <Research></Research>
        </div> */}

        <div style={{
          backgroundColor: "rgb(245, 247, 250)",
          backgroundImage: "url('https://nitw.ac.in/api/static/files/ribbon_light_strip_2023-6-15-11-2-51.jpg')",
          backgroundSize: "cover",
          borderTop: "7px solid #db5903",
          borderBottom: "7px solid #db5903"
        }}>
          <h2 style={{
            fontFamily: 'Merriweather',
            color: '#B80924',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: "10px",
            background: "rgba(255, 255, 255, 0.8)"
          }}>
            Research Highlights
          </h2>


          <div className="row EventsPublication" style={{ textAlign: 'center' }}>
            <div className="col-md-4 col-sm-12" style={{ margin: "0 0px" }}>
              {/* <PhdSeminars autoPlay={true} carouselTitle={"Ph. D seminars"} /> */}
              <Patents/>
            </div>
            <div className="col-md-4 col-sm-12" style={{ margin: "0 0px" }}>
              <NewScroll2 cardItems={RecentPublication} autoPlay={false} carouselTitle={"Recent Publications"} />
            </div>

            <div className="col-md-4 col-sm-12 " style={{ margin: "0 0px" }}>
              <NewScroll4 cardItems={PatentsAndProjects} autoPlay={true} carouselTitle={"Sponsored Projects"} />
            </div>
          </div>
        </div>


        <ProgressBar />

        <div>

          {/* <CampusLife></CampusLife> */}
        </div>

        <div style={{ backgroundColor: "#FFF5EE" }} >

        </div>
        <div style={{ backgroundColor: "#fbfbfb", borderTop: "3px solid #f16127" }}>
          <SocialFeed />
          {/* <center>
          <div className="text-center">
            <h4 className="mt-2 mb-2 notice-board">
              Opportunities (Careers / Jobs)
            </h4>
          </div>
          </center> */}

          <div>
            {<CampusLife items={GalleryData} />}
          </div>
          {/* <div>
            {<CampusActivity />}
          </div> */}
          {/* <div className="row" style={{ margin: "0px 7vw", padding: "40px 0",overflow: "hidden" }}> */}
          {/* <div className="col-md-6 col-sm-12" style={{ margin: "0 30px" }}></div>
          <div className="col-md-4 col-sm-12" style={{ margin: "0 30px" }}><Newsletter /></div> */}

          {/* </div> */}
        </div>
        {/* <div>
          <Newsletter></Newsletter>
        </div> */}
      </div>
      </div>
    </React.Fragment>
  );
};

export default HomePage;

// const cardItems = [
//     // { 'title': "Dr. Kusum Kumari, Department of Physics is  selected for the INSA-Visiting Scientist Programme for the year 2023-24.", 'Content': '', 'url': 'https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16597' },
//     // { 'title': "CoCoLe 2023 has received financial assistance from SERB, DST, Govt. of India.", 'Content': '', 'url': 'https://ic-cocole.in/' },
//     // { 'title': "Director Prof. Bidyadhar Subudhi is nominated as Panel Member of ARMREB of DRDO", 'Content': '', 'url': '#' },
//     // { 'title': "Professor Venkatathri Narayanan of our Chemistry Department is elected as Fellow Member of the Royal Society of Chemistry, London", 'Content': '', 'url': '#' },
//     // { 'title': "Best Paper Award for PhD Student Nitish Gupta", 'Content': 'Nitish Gupta and D. Bhargavi, "An Analytical Study of the Impact of an Inclined Magnetic Field on Couette Flow with Jeffrey Fluid under Local Thermal Non-Equilibrium (LTNE) and Viscous Dissipation", paper presented at the "International Conference on Advancements in Materials, Manufacturing, and Automation (AMMA-2023)", held during April 7-8, 2023, organized by Amrita Vishwa Vidyapeetham, Tamil Nadu, India. ', 'url': '#' },
//     // { 'title': "Title 1", 'Content': 'Content 1', 'url': 'https://www.nitw.ac.in/' },
//     // { 'title': "Title 1", 'Content': 'Content 1', 'url': 'https://www.nitw.ac.in/' },
//     // { 'title': "Title 1", 'Content': 'Content 1', 'url': 'https://www.nitw.ac.in/' },

// ]

// export default cardItems;

const cardItems = [
  {
    title: 'Prof. Rakesh Kumar and  Prof. K. Uday Kumar have received a Project on  Development of high-performance triboelectric nanogenerator based on 2D MoS2@Metal organic framework Hybrid films for self-powered IoT systems',
    Content: '',
      url: 'https://www.nitw.ac.in/'

  },
  
  {
    title: 'Congratulations to Prof. Rathish Kumar Pancharathi , Dept. of Civil Engineering has been selected as the Senior Member, INAE. Prof Rathish Kumar Pancharathi has been also successful in securing two R&D project grants: (i) SPARC,  UKIERI, MoE project on Inspiring the researchers of tomorrow in sustainable concrete construction (ii) Project  on “Development of Geopolymer Aggregates from Ash(Dry and Pond) for applications in Bio-Bricks, Low grade Pavements and Precast Elements” Damodar Valley Corporation, GoI, Ministry of Power',
    Content: '',
      url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Congratulations to Prof Soumya Lipsa Rath (BT) for International project from National Institutes of Health (NIH), USA for simulation work',
    Content: '',
      url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Congratulations to Prof. Raghu Chitta and Prof. PV Srilakshmi, Prof. Gopi  Ram, Prof. Manjaiah M and Prof. Kishore Babu, Prof. Surajbhan  Sevda, Prof. M. Jerold, Prof. A. Kirubakaran and Prof. V. T. Somasekhar, Prof. Rathish for SPARC Project Grants.',
    Content: '',
      url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. Palash Mishra has received a Project on Surface Charge Mitigation in Nano-Micro Hybrid Silicone Rubber Composites for HVDC Insulation in Harsh Environmental Conditions',
    Content: '',
      url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Dr.V.Rama Devi, School of Management  has received a Project on"Glass Ceiling for Women in Corporate Sector- Antecedents, Outcomes & Policy Measures" 2023 sanctioned by National Commission for Women, New Delhi.',
    Content: '',
      url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'An Indian patent has granted to P. Abdul Azeem, P Srinath, K. Venugopal Reddy,  S. Rajkumar,  M Bamanandam, M Krishnam Raju, Sushil Patel "CALCIUM SILICATE BASED NANO CRYSTALLINE CERAMICS: A COST-EFFECTIVE METHOD FOR THE PREPARATION USING NATURAL RESOURCES" Grant No. 376586   Application No. 2020201941036738',
      Content: '',
      url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Thangaraju, Paul Joseph and V. Jayalakshmi, Department of Physics, have received a SPARC project on Hybrid Tetrapod Nanomaterials Based Sustainable Optoelectronic Technologies.',
    Content: '',
    url: 'https://www.nitw.ac.in/'
  },
    {
      title: 'Prof. Adepu Kumar, Department of Mechanical Engineering is granted with a patent titled System and Method for reinforcing powder particles using Friction Stir Processing along with his doctoral student Shri Naresh P.',
      Content: '',
      url: 'https://www.nitw.ac.in/'
    },
    {
      title: 'A.Suchith Reddy and Prof P.Rathish Kumar, Indian Patent Filed on A method and system for assessing sustainability of civil infrastructure, Application No. 202141054686, 26th November 2021.',
      Content: '',
      url: 'https://www.nitw.ac.in/'
    },
    {
      title: 'Mukul Pradhan, S. Rudra, R. Chakraborty File No. 202141054738 Synthesis of Graphitized Carbon from Biomass using Focused Sunlight in a Newly Designed Reaction Setup for Energy Storage Application, 2021.',
      Content: '',
      url: 'https://www.nitw.ac.in/'
    },
    {
      title: 'E. Suresh Babu An Efficient Spectral Cluster Routing Method to Enhance the Network Performance in Wireless Sensor Networks Publication The Patent Office Journal No. 12/2021 Dated 19/03/2021.',
      Content: ' ',
      url: 'https://www.nitw.ac.in/'
    },
    {
      title: 'Dr. V. Rama and Prof. C. B. Rama Rao have been granted a patent on A Tele -Health Care System and Method for Early Disease Detection bearing patent no. 2021105599, date: 03/11/2021.',
      Content: '',
      url: 'https://www.nitw.ac.in/'
    },
    {
      title: 'Dr. Suresh Babu Perli received Innovation patent from Australia for the title A Web-Enabled Smart Home Gateway for Automatic Device and Network Configuration and Automatic System Updates Via IoT, Date of Grant: 15 Dec 2021.',
      Content: '',
      url: 'https://www.nitw.ac.in/'
    }
  
    // {
    //   title: 'Jitesh Pani, Devkaran Maru, Priyanka Chaudhary, Jitendra Gangwar, Kanapuram Uday Kumar, Bal Chandra Yadav, and Hitesh Borkar, "Improved Supercapacitor Performance with Enhanced Interlayer Spacing of Nanoflower MoS2 in Long Discharge Time in LED-Glowing Application", 2023.',
    //   Content: '',
    //   url: 'https://doi.org/10.1002/ente.202300193'
    // }
  ];
  
  export default cardItems;
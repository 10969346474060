import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import { convocation2024 } from '../constants/ConvocationData';
import '../css/convocation2023.css'; // Import your custom CSS for styling
import { red } from '@mui/material/colors';

const Convocation2024Page = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleMouseOver = (index) => {
    setSelectedItem(index);
  };

  const handleMouseOut = () => {
    setSelectedItem(null);
  };

  return (
    <div className="page text-center font-change-applicable">
      {PageHeader && <PageHeader dept="/convocation2024" />}
      <div className="p-3 container" style={{ width: '100%', marginTop: '20px' }}>
        <div className="row justify-content-center">
          {convocation2024.map((item, index) => (
            <div
              className={`col-md-6 col-lg-4 my-3 member ${
                index === 0 ? 'first-tab' : 'other-tab'
              } ${item.name.toLowerCase()}-tab`}
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <a href={item.link} target="_blank" rel="noreferrer">
                <div
                  className={`card custom-profile-card ${
                    selectedItem === index ? 'hovered' : ''
                  } ${item.isCircle ? 'circle-tab' : ''}`}
                >
                  <div className="circle-tab-content">
                    <img
                      src={item.image}
                      alt={`Item ${index + 1}`}
                      className={`circle-tab-image ${
                        selectedItem === index ? 'hovered' : ''
                      }`}
                    />
                   <p className={`circle-tab-label`}>
                      {item.name}
                      {index === 0 && <br /> /* Add line break only for Chief Guest */}
                      {index === 0 && 'Chief Guest' /* Display Chief Guest for the first tab */}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}
          
          {/* <h4 style={{color:"red"}}>Contact us: 8332969675</h4> */}
        </div>
      
    
    {/* New section for YouTube live streaming link */}


{/* YouTube Live Streaming Section */}
{/* <div className="youtube-section" style={{ marginTop: '40px', padding: '20px', background: '#f0f0f0' }}>
  <div className="youtube-content" style={{ display: 'flex', alignItems: 'center' }}>
   
    <div className="left-column" style={{ flex: 1 }}>
      <h3 style={{ textAlign: 'left' }}>Live Streaming</h3>
      <p style={{ textAlign: 'left', marginBottom: '10px' }}>
        
        live streaming starts at 1:30 PM, 16<sup>th</sup> September, 2023. 
       
        <br />
        <a
          href="https://www.youtube.com/embed/RqwefYaVgsI?si=boNGlQvvnA-h06nk"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#007BFF', textDecoration: 'underline' }}
        >
          Watch Live
        </a>
        
      </p>

      <img src="https://nitw.ac.in/api/static/files/scanner_NITW_21st_Convocation_2023_2023-8-12-18-33-48.jpeg" alt="NITW 21st Convocation" width="180" height="180"></img>
    </div>
    

    <div className="right-column" style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
      <div className="youtube-embed">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/RqwefYaVgsI?si=boNGlQvvnA-h06nk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  </div>
</div> */}

</div>
</div>
  );
};

export default Convocation2024Page;
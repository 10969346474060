import React, { useState } from 'react';
import '../css/MilestonesTimeline.css';
import { colors } from '@mui/material';



const Natgfoe2024 = () => {


    const styles = {
        global: {
            fontFamily: "Signika !important",
            color: "#000 !important"
        },
        title: {
            color: '#2037ff',
        },
        paragraph: {
            fontSize: '16px',
        },

        ulBulletes: {
            color: "#000"
        }
    };




    return (
        <>
            <section><img className='img-fluid' alt='' src='https://nitw.ac.in/api/static/files/natgfoe2024-banner_2024-11-7-18-15-58.png' /></section>
            <ul className="nav nav-tabs justify-content-center aboutus-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link  about-tab active" id="tab-0" data-bs-toggle="tab" href="#tabpanel-0" role="tab" aria-controls="tabpanel-0" aria-selected="true">Home</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-6" data-bs-toggle="tab" href="#tabpanel-6" role="tab" aria-controls="tabpanel-6" aria-selected="false">About ANRF</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-4" data-bs-toggle="tab" href="#tabpanel-4" role="tab" aria-controls="tabpanel-4" aria-selected="false">About INAE</a>
                </li>

                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-5" data-bs-toggle="tab" href="#tabpanel-5" role="tab" aria-controls="tabpanel-5" aria-selected="false">About NITW</a>
                </li>

                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-2" data-bs-toggle="tab" href="#tabpanel-2" role="tab" aria-controls="tabpanel-2" aria-selected="false">Speakers</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-3" data-bs-toggle="tab" href="#tabpanel-3" role="tab" aria-controls="tabpanel-3" aria-selected="false">Schedule</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-1" data-bs-toggle="tab" href="#tabpanel-1" role="tab" aria-controls="tabpanel-1" aria-selected="false">Organizers</a>
                </li>



            </ul>

            <div className="container">
                <div className="tab-content pt-5 pb-5" id="tab-content">
                    <div className="tab-pane active" id="tabpanel-0" role="tabpanel" aria-labelledby="tab-0">

                        <div className='row'>
                            <div className='col-md-12'>

                                <h3 style={styles.title}>National Frontiers of Engineering</h3>
                                <p style={styles.paragraph}>The Symposium on National Frontiers of Engineering (NatFoE) is a flagship event of the Indian National Academy of Engineering (INAE) since 2006. It brings together emerging engineering leaders from academia, industry, and R&D labs. NatFoE serves as an enabling platform for sharing cutting-edge research, technical innovations, and emerging trends across a wide spectrum of engineering fields. </p>

                                {/* <p style={styles.paragraph}>NatFoE provides a dynamic platform for early-career professionals to present and discuss pioneering research, innovative technologies, and cutting-edge developments across various engineering disciplines. Attendees, both speakers and participants, represent leading academic institutions, research labs, and industries, making this symposium a unique opportunity to network, exchange ideas, and contribute to the future of engineering.</p> */}
                                <p style={styles.paragraph}>The 18th National Frontiers of Engineering (NatFoE) Symposium is scheduled to effected during 16-17, November 2024, jointly organized by INAE and NIT Warangal. This prestigious two-day event will  provide ample opportunity to participate with known for their exceptional research and innovation. Alongside plenory and delegates sessions, the symposium will feature poster presentations, offering an additional platform for sharing innovative ideas and fostering in-depth discussions on the cutting-edge engineering topics.  </p>
                                <h4 style={styles.title}>Themes</h4>
                                <p style={styles.paragraph}>This year's symposium will focus on key themes addressing critical advancements and challenges in engineering. These topics will foster interdisciplinary dialogue and collaboration, bringing together thought leaders from academia, industry, and research to explore the future of engineering and technology.</p>
                                <ul class="visbullets" style={styles.ulBulletes}>

                                    <li>Additive Manufacturing and Automation</li>
                                    <li>Smart Grid: Power Electronic Converters, control and Protection</li>
                                    <li>Green Hydrogen and Storage Technologies</li>
                                    <li>Quantum Computing, Artificial Intelligence and Machine Learning</li>

                                </ul>
                                <h4 style={styles.title}>IMP</h4>
                                NatFoE will be co-located with the Innovation in Manufacturing Processes (IMP), a national project competition open to engineering students and practitioners. The competition welcomes participation from undergraduates, postgraduates, PhD. students in engineering & technology and incubators, providing a platform to showcase innovative solutions in manufacturing processes. IMP aims to foster creativity and technical excellence, offering participants an opportunity to present their projects to a distinguished audience of industry experts, researchers, and academics
                                <h4 style={styles.title}>Important dates</h4>
                                <p style={styles.paragraph}>Last date to submit Posters :9 November 2024</p>
                                <p style={styles.paragraph}>Last date to Submit abstract for IMP: 9 November 2024</p>
                                <p style={styles.paragraph}>Symposium on 16-17 November 2024</p>


                            </div>
                        </div>
                        {/* ABOUT US END*/}

                    </div>
                    {/* ORGANIZERS START */}
                    <div className="tab-pane" id="tabpanel-1" role="tabpanel" aria-labelledby="tab-1">

                        <div className='row'>


                            <h3 style={styles.title} className='mt-3'>Patron</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-title" style={{ paddingLeft: "0px" }}>Prof. Indranil Manna</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>FNAE, President, INAE</p>
                                </div>
                            </div>
                            <h3 style={styles.title} className='mt-3'>Coordinators</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Bidyadhar Subudhi</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>FNAE, Director, NIT Warangal</p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sivaji Chakravorti</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>FNAE, Vice President INAE</p>
                                </div>
                            </div>
                            <h3 style={styles.title} className='mt-3'>Core Team</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Shirish H Sonawane</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>Dean (Research & Development) - Convenor </p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Ratish Kumar</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Kartik Balasubramanian</h5>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof.S Shankar</h5>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Bharat Bandi</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Ram Krishan</h5>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. V Sreedevi</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sreehari Rao Patri</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Shri Gajanan Devkate</h5>
                                </div>
                            </div>

                            <h3 style={styles.title} className='mt-3'>Theme Conveners</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Additive Manufacturing & Automation</h5>
                                    <p className="card-text">Prof. Adepu Kumar <br />Prof .Y Ravi Kumar <br />Prof. Shivraman</p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Smart Grid: Power Electronic Converters, control and Protection </h5>
                                    <p className="card-text">Prof. V T Somasekhar <br />Prof. Ram krishan</p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Green Hydrogen and Storage Technologies </h5>
                                    <p className="card-text">Prof. P V Suresh  <br />Prof. K Manohar</p>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Quantum Computing , Artificial Intelligence and Machine Learning </h5>
                                    <p className="card-text">Prof. P Radhakrishna  <br />Prof. U Venkanna  <br /> Prof. Manish K Bajpai</p>
                                </div>
                            </div>


                            <h3 style={styles.title} className='mt-3'>IMP Start-ups /Exhibits / Posters </h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Prof. Y Ravi Kumar</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Ravi Kumar J</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. L Anjaneyulu</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sreehari Rao Patri</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Subhas Chandra Bose</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Vamsi Krishna</h5>

                                </div>
                            </div>


                            <h3 style={styles.title} className='mt-3'>Organising Team</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Rashmi Ranjan Rout</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. T Phanirama Tezeswi</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. G. Uday Bhaskar</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. K S Raj Mohan</h5>

                                </div>
                            </div>



                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Sampath Kumar</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sanjit Biswas</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sanjaya K Panda</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Prof. Jew Das</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Shri Adesh Srivastava</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Prof. T Rahul </h5>

                                </div>
                            </div>


                        </div>


                    </div>
                    {/* ORGANIZERS START */}

                    {/* SPEAKERS START */}
                    <div className="tab-pane" id="tabpanel-2" role="tabpanel" aria-labelledby="tab-2">
                        <div className='row'>

                            <h3 style={styles.title}>Theme 1: Additive Manufacturing and Automation</h3>

                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/1_2024-10-30-10-35-28.png" className="img-fluid " alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Surjya Kanta Pal</h5>
                                    <p className="card-text-ord">Former Lord Kumar Bhattacharyya Chair Professor, IIT Kharagpur</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/2_2024-10-30-10-36-9.png" className="img-fluid " alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Poonam Sundriyal</h5>
                                    <p className="card-text">INAE Yong associate <br /> Assistant Professor, IIT KGP</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/3_2024-10-30-10-37-42.png" className="img-fluid " alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Mr. Ankith Sahu</h5>
                                    <p className="card-text">CEO and Founder, Objectify Technologies</p>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/4_2024-10-30-10-37-58.png" className="img-fluid " alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Gururaj Telasang</h5>
                                    <p className="card-text">Scientist, ARCI, Hyderabad</p>
                                </div>
                            </div>

                            <h3 style={styles.title} className='mt-3'>Theme 2: Smart Grid : Power Electronics Converters, Control and Protection </h3>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/5_2024-10-30-10-38-18.png" className="img-fluid " alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. S C Srivastava</h5>
                                    <p className="card-text">Professor, Department of Electrical Engineering, IIT Kanpur</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/6_2024-10-30-10-38-32.png" className="img-fluid " alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Yaswaswi Bansal</h5>
                                    <p className="card-text">Assistant Professor, Department of Electrical Engineering, IIT Delhi</p>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/7_2024-10-30-10-38-48.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. P. Deepak Reddy</h5>
                                    <p className="card-text">Assistant Professor, Department of Electrical Engineering, IIT Kharagpur</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/8_2024-10-30-10-39-2.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. P. Chandrasekhar </h5>
                                    <p className="card-text">Assistant Professor, Department of Electrical Engineering, IIT Bhubaneshwar</p>
                                </div>
                            </div>


                            <h3 style={styles.title} className='mt-3'>Theme 3: Green hydrogen and Storage Technologies</h3>

                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/9_2024-10-30-10-39-16.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Suddhasatwa Basu </h5>
                                    <p className="card-text">Professor, Department of Chemical Engineering, IIT Delhi</p>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/Prof._V_Sreedevi_2024-4-25-10-49-22.jpg" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. V. Sreedevi</h5>
                                    <p className="card-text">Assistant Professor, National Institute of Technology Warangal</p>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/10_2024-10-30-10-39-33.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Vijay Radhakrishnan</h5>
                                    <p className="card-text">General Manager Battery  Technology
                                        Reliance New Energy, Reliance Industries Limited
                                        Mumbai</p>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/11_2024-10-30-10-39-52.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr.-Ing. V.V.S.S. Srikanth</h5>
                                    <p className="card-text">Associate Professor,
                                        School of Engineering Sciences and Technology,
                                        University of Hyderabad</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/12_2024-10-30-10-40-7.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Sujit Pillai</h5>
                                    <p className="card-text">Scientist-F
                                        Ministry of New and Renewable Energy (MNRE), New Delhi</p>
                                </div>
                            </div>



                            <h3 style={styles.title} className='mt-3'>Theme 4: Theme IV Quantum Computing, AI and ML</h3>

                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/13_2024-10-30-10-40-24.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Anil Prabhakar</h5>
                                    <p className="card-text">Professor
                                        Dept. of Electrical Engineering, IIT Madras</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/14_2024-10-30-10-40-39.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Sharad Sinha</h5>
                                    <p className="card-text">Associate Professor, Computer Science and Engineering
                                        Dean (Faculty Affairs), IIT Goa</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/15_2024-10-30-10-40-54.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Mr. Amit Saxena</h5>
                                    <p className="card-text">Scientist E, CDAC</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/16_2024-10-30-10-41-12.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Puneet Gupta</h5>
                                    <p className="card-text">Associate Professor,
                                        Dept. of Computer Science and Engineering, IIT Indore</p>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <img src="https://nitw.ac.in/api/static/files/17_2024-10-30-10-41-28.png" className="img-fluid" alt="..." />
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Dr. Devashree Tripathi</h5>
                                    <p className="card-text">Assistant Professor
                                        IIT Bhubaneshwar</p>
                                </div>
                            </div>





                        </div>





                    </div>
                    {/* SPEAKERS END */}
                    <div className="tab-pane" id="tabpanel-3" role="tabpanel" aria-labelledby="tab-3">



                        <div className='row'>
                            <h3 style={styles.title}>Program Schedule</h3>
                            <h4><a href='https://nitw.ac.in/api/static/files/natfoe_2024-11-16-11-41-29.pdf' target='_blank' style={{ color: "red" }}>Click here to download the program schedule.</a></h4>
                        </div>
                        <div className='row'>
                            <h3 style={styles.title}>Day 1: 16<sup>th</sup> November 2024 (Saturday)</h3>
                            <table className=" pl-1 custom-table table-bordered">
                                <tbody>
                                    <tr>
                                        <td><b>09.00 - 09.30</b> </td>
                                        <td><b>Registration (Venue: Ambedkar Learning Centre)</b></td>
                                    </tr>
                                    <tr>
                                        <td> </td>
                                        <td><b>Inaugural Session (Venue: Ambedkar Learning Centre)</b></td>
                                    </tr>
                                    <tr>
                                        <td>09.30 - 09.35 </td>
                                        <td>Welcome Remarks: <br/> Prof. Bidyadhar Subudhi<br />Coordinator NatFoE & Director, NIT Warangal</td>
                                    </tr>
                                    <tr>
                                        <td> 09.35 - 09:40</td>
                                        <td>About NatFoE Symposium:<br /> Prof. Sivaji Chakravorti<br />Vice President, INAE</td>
                                    </tr>
                                    <tr>
                                        <td>09:40 - 09:50 </td>
                                        <td>Presidential Remarks: <br />Prof. Indranil Manna<br />
                                            President, INAE</td>
                                    </tr>
                                    <tr>
                                        <td>09:50 - 09:52 </td>
                                        <td>Releasing Symposium Abstract Booklet</td>
                                    </tr>
                                    <tr>
                                        <td>09:52 - 09:55 </td>
                                        <td>Vote of Thanks Prof. Shirish H Sonawane,<br />
                                            Convener, NatFoE 2024</td>
                                    </tr>
                                    <tr>
                                        <td><b> 09:55 to 10:15</b></td>
                                        <td><b> High Tea</b></td>
                                    </tr>

                                </tbody>
                            </table>
                            <h5 style={{ color: "#c13c37" }}>Session-1 (Venue: Bose Hall, Seminar Hall Complex)<br />
                                Theme: Additive manufacturing and Automation
                            </h5>
                            <p>Theme Conveners: Prof. Adepu Kumar, Prof. Y. Ravi Kumar, Prof. Shivraman</p>
                            <table className="table-bordered">
                                <tbody>
                                    <tr>
                                        <td>10:15 – 11:00 </td>
                                        <td><b><u>Plenary Lecture:</u></b> Human-Centric Manufacturing: Exploring the Next Frontier with Industry 5.0<br/>
                                            Speaker: Prof. Surjya Kanta Pal, IIT Kharagpur
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> </td>
                                        <td><b><u>Invited Lectures:</u></b></td>
                                    </tr>
                                    <tr>
                                        <td>11:00 – 11:30 </td>
                                        <td>1. 3D Printing of Supercapacitors for Self-Powered Wearable Electronics<br/>
                                            Speaker: Prof. Poonam Sundriyal, IIT Kharagpur
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11:30 – 12:00 </td>
                                        <td>2. Approach to critical engineering for the Indian aerospace industry through the Metal L-PBF process: <br/>integrating design modification, material qualification, post-processing and certification<br/>
                                            Speaker: Mr. Ankith Sahu Objectify Technologies Pvt Ltd., Greater Noida
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12:00 – 12:30 </td>
                                        <td>3. Additive Manufacturing by Powder Bed Fusion by Laser Beam Melting (PBF-L/M): Materials and Case Studies<br/>
                                            Speaker: Dr. Gururaj T, Scientist, ARCI, Hyderabad
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                            <h5 style={{ color: "#c13c37" }}>Session-2 (Venue: Bose Hall, Seminar Hall Complex)<br/>
                                Theme: Smart Grid: Power Electronic Converters, Control and Protection

                            </h5>
                            <p>Theme Conveners: Prof. V. T. Somasekhar, Prof. Ram Krishan</p>
                            <table className=" pl-1 custom-table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>12:30 – 13:00</td>
                                        <td><b><u>Plenary Lecture:</u></b> Smart Electric Grids:  Power Management, Control and Protection Challenges<br/>
                                            Speaker: Prof. Suresh Chandra Srivastava, IIT Kanpur
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>13:00 – 14:00</b></td>
                                        <td><b>Lunch Break</b></td>
                                    </tr>
                                    <tr>
                                        <td>    </td>
                                        <td> <b><u>Invited Lectures:</u></b>  </td>
                                    </tr>
                                    <tr>
                                        <td> 14:00 – 14:45   </td>
                                        <td>   1. Transformative Role of PMUs for Monitoring and Protection in Smart Grid<br/>
                                            Speaker: Prof. Yashasvi Bansal, IIT Delhi
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> 14.45 – 15:15   </td>
                                        <td>  2. Operational and Control of Converter Dominant Microgrid systems<br/>
                                            Speaker: Prof. Deepak Reddy, IIT Kharagpur
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>  15:15 – 15:45  </td>
                                        <td>  3. Wireless Power Transfer & Its Challenges<br/>
                                            Speaker: Prof. Chandrashekhar Perumalla, IIT Bhubaneswar
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>  15.45 – 16:00  </td>
                                        <td>   Tea Break </td>
                                    </tr>
                                    <tr>
                                        <td>  16:00 – 16:15  </td>
                                        <td> Discussion   </td>
                                    </tr>
                                    <tr>

                                        <td colSpan={2}> Poster Session Combining all the themes:    </td>
                                    </tr>
                                    <tr>
                                        <td> <b>16:00 – 17:30  </b> </td>
                                        <td> <b> Poster Session<br/>
                                            Venue: Seminar Hall Complex</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                        <div className='row'>
                            <h3 style={styles.title}>Day 2: 17<sup>th</sup> November 2024 (Sunday)</h3>
                            <h5 style={{ color: "#c13c37" }}>Session-3 (Venue: Bose Hall, Seminar Hall Complex)<br/>
                                Theme: Green Hydrogen and Storage Technologies



                            </h5>
                            <p>Theme Conveners: Prof. P.V. Suresh, Prof. Manohar Kakunuri</p>
                            <table className=" pl-1 custom-table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>  09:30 – 10:15  </td>
                                        <td>  <b><u>Plenary Lecture:</u></b>Electrochemical Engines for Energy Storage and Conversion to Achieve Net Zero Carbon Emission<br/>
                                            Speaker: Prof. Suddhasatwa Basu, IIT Delhi
                                        </td>
                                    </tr><tr>
                                        <td>    </td>
                                        <td> <b><u>Invited Lectures:</u></b>  </td>
                                    </tr><tr>
                                        <td> 10:15 – 10:45   </td>
                                        <td> 1. Engineering aspects towards commercialization of Sodium Ion Batteries in   India<br/>
                                            Speaker: Dr. Vijay Radhakrishnan, RIL, Mumbai
                                        </td>
                                    </tr><tr>
                                        <td> <b> 10:45 – 11:15 </b> </td>
                                        <td> <b> Tea Break </b>  </td>
                                    </tr><tr>
                                        <td> 11:15 – 11:45   </td>
                                        <td>  2. Overview of Electrolyser Technology for Green Hydrogen Production<br/>
                                            Speaker: Dr. Sujit Pillai, MNRE, New Delhi
                                        </td>
                                    </tr><tr>
                                        <td>   11:45 – 12:10 </td>
                                        <td>  3. Advances in Solid State Hydrogen Storage Materials<br/>
                                            Speaker: Dr.-Ing. V.V.S.S. Srikanth UoH, Hyderabad
                                        </td>
                                    </tr><tr>
                                        <td>   12:15 – 12:45 </td>
                                        <td> On-demand Hydrogen Generation by the Hydrolysis of Novel Aluminum-based Composites<br/>
                                            Speaker: Prof. V. Sreedevi, NIT Warangal
                                        </td>
                                    </tr><tr>
                                        <td> <b>12:45 – 14:00  </b> </td>
                                        <td> <b> Lunch Break</b>  </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h5 style={{ color: "#c13c37" }}>Session-4 (Venue: Bose Hall, Seminar Hall Complex)<br/>
                                Theme: Quantum Computing, AI and ML
                            </h5>
                            <p>Theme Conveners: Prof. P. Radhakrishna, Prof. U. Venkanna, Prof. Manish K Bajpai</p>
                            <table className=" pl-1 custom-table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>  14:00 – 14:45  </td>
                                        <td> <b><u>Plenary Lecture:</u></b> Quantum Technologies in the National Quantum Mission<br/>
                                            Speaker: Prof. Anil Prabhakar, IIT Madras
                                        </td>
                                    </tr><tr>
                                        <td>    </td>
                                        <td> <b><u>Invited Lectures:</u></b>  </td>
                                    </tr><tr>
                                        <td>  14:45 – 15:15  </td>
                                        <td>  1. Importance of Remote Photoplethysmography in the realm of AI<br/>
                                            Speaker: Dr. Puneet Gupta, IIT Indore
                                        </td>
                                    </tr><tr>
                                        <td>  15:15 – 15:45  </td>
                                        <td> 2. Quantum Simulators and Accelerators: Harnessing PARAM for Quantum Computing Acceleration<br/>
                                            Speaker: Mr. Amit Saxena, CDAC, Pune
                                        </td>
                                    </tr><tr>
                                        <td>  <b>15:45 – 16:00 </b> </td>
                                        <td>  <b>Tea Break </b> </td>
                                    </tr><tr>
                                        <td> 16:00 – 16:30   </td>
                                        <td>  3. Symbiotic Relationship Between Artificial Intelligence and Computing Systems Design  </td>
                                    </tr><tr>
                                        <td> 16:30 – 17:00   </td>
                                        <td> 4. AI Infrastructure: Optimizing LLM Inference for Efficiency and Scalability<br/>
                                            Speaker: Prof. Devashree Tripathy, IIT Bhubaneshwar
                                        </td>
                                    </tr><tr>
                                        <td><b> 17:00 – 17:30  </b> </td>
                                        <td>  <b>Valedictory Session (Venue: Bose Hall, Seminar Hall Complex) </b>  </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className='row'>
                            <h3 style={styles.title}>Day 2: 17<sup>th</sup> November 2024 (Sunday)</h3>
                            <h5 style={{ color: "#c13c37" }}>National Level Project Competition: Innovation in Manufacturing Practices (IMP 2024)</h5>
                            <p>Prof. J. Ravi Kumar, Prof. Y. Ravi Kumar, Prof. T. Rahul, Prof. P. Subhash Chandra Bose,
                                Prof. P. Srihari Rao, Prof. L. Anjaneyulu, Prof. Sanjaya Kumar Panda </p>
                            <h5 style={{ color: "#c13c37" }}> Venue: Homi Bhabha Hall, Seminar Hall Complex</h5>
                            <table className=" pl-1 custom-table table-bordered">
                                <tbody>
                                    <tr><td>10:00 – 12:00</td><td>Pitching Session1: Under-Graduate Scholars </td></tr>
                                    <tr><td>12:00 – 13:30</td><td>Pitching Session2: Post-Graduate Scholars </td></tr>
                                    <tr><td><b>13:30 – 14:30</b></td><td><b>Lunch Break</b></td></tr>
                                    <tr><td>14:30 – 16:30</td><td>Pitching Session3: Young Entrepreneurs/Startups/PhD Scholars </td></tr>
                                    <tr><td>16:30 – 17:00</td><td>Valedictory Session for both NaTFoE and IMP 2024 </td></tr>

                                </tbody>
                            </table>
                        </div>





                    </div>
                    {/* ABOUT INAE START */}
                    <div className="tab-pane" id="tabpanel-6" role="tabpanel" aria-labelledby="tab-6">
                        <div className='row'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h3 style={styles.title}>About ANRF</h3>
                                    <p>The Anusandhan National Research Foundation (ANRF) has been established with Anusandhan National Research Foundation (ANRF) 2023 Act. The ANRF aims to seed, grow and promote research and development (R&D) and foster a culture of research and innovation throughout India’s universities, colleges, research institutions, and R&D laboratories. ANRF will act as an apex body to provide high-level strategic direction of scientific research in the country as per recommendations of the National Education Policy (NEP). With the establishment of ANRF, the Science and Engineering Research Board (SERB) established by an act of Parliament in 2008 has been subsumed into ANRF. ANRF will forge collaborations among the industry, academia, and government departments and research institutions, and create an interface mechanism for participation and contribution of industries and State governments in addition to the scientific and line ministries.</p>
                                    <p>For more details, please visit <a href='https://dst.gov.in/anusandhan-national-research-foundation-anrf' target='_blank'>https://dst.gov.in/anusandhan-national-research-foundation-anrf</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ABOUT INAE END */}


                    {/* ABOUT INAE START */}
                    <div className="tab-pane" id="tabpanel-4" role="tabpanel" aria-labelledby="tab-4">
                        <div className='row'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h3 style={styles.title}>About INAE</h3>
                                    <p>The Indian National Academy of Engineering (INAE), founded in 1987 comprises India’s most distinguished engineers, scientists and technologists covering the entire spectrum of engineering disciplines. INAE functions as an apex body and promotes the practice of engineering and technology and the related sciences for their application to solving problems of national importance. The Academy also provides a forum for futuristic planning for the country’s development requiring engineering and technological inputs. </p>
                                    <p>For more details, please visit <a href='https://www.inae.in/' target='_blank'>https://www.inae.in/</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ABOUT INAE END */}


                    {/* ABOUT NITW START */}
                    <div className="tab-pane" id="tabpanel-5" role="tabpanel" aria-labelledby="tab-5">
                        <div className='row'>
                            <div className='col-md-12'>
                                <h3 style={styles.title}>About NIT Warangal</h3>
                                <p>National Institute of Technology Warangal is an institute of national importance which was established in 1959. This is the first NIT of the country whose foundation stone was laid by the Pandit Jawaharlal Nehru.
                                    It is a fully residential lush green campus spread across 250 acres.
                                    The Institute has received a significant recognition from different stake holders for its excellence in teaching and research. It has been facilitated with competent faculty staff and excellent infrastructure.</p>
                                <p>The institute offers 11 BTech, 26 MTech, 5 MSc, MBA, MCA, Integrated MSc, BSc, B Ed, and PhD in engineering, sciences, humanities and management. The Institute currently has thirteen academic departments and advanced research centers in various disciplines of engineering, sciences and management, with nearly 100 laboratories for teaching and R&D. The Institute is constantly striving to achieve higher levels of technical excellence.</p>
                                <p>For more details, please visit <a href='https://www.nitw.ac.in/' target='_blank'>https://www.nitw.ac.in/</a></p>
                            </div>
                        </div>
                    </div>
                    {/* ABOUT NITW END */}


                </div>



            </div>

        </>
    );



}

export default Natgfoe2024

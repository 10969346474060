
import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { IMG_URL } from "../constants/PlacementLogsData";

// const bgurl =
// `${MainServerUrl}/static/files/NITW_2021-10-13-22-53-31.jpg`;
let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);
const PlacementLogs = () => {
  return (
    <React.Fragment>
      <Grid
        style={{
          backgroundColor: "#ecf1f7",
         
          // backgroundImage: `url("${bgurl}")`,
          // backgroundSize: "cover",
        }}
      >
        <Grid height="2vh" ></Grid>
        <div style={{ width: "90vw", margin: "auto" }}>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              fontWeight="bold"
              margin="auto"
              textAlign="center"
              color="#b80924"
              paddingTop="0.5vh"
            >
              Our Recruiters
            </Typography>
            <hr className="divider bg-madison"  />
          </ThemeProvider>
        </div>
        <br /><br />
        <section className="section">
          <div style={{ marginBottom: "60px" }} className="owl-carousel flickr owl-carousel-fullheight" data-items={6} data-sm-items={6} data-autoplay="true" data-md-items={8} data-xxl-items={10} data-nav="false" data-dots="false" data-mouse-drag="true" data-lightgallery="group" data-stage-padding={0} data-xl-stage-padding={0}>
            {IMG_URL.map((img) => {
              return (
                <a style={{
                  margin: "5px 10px",
                  padding: "10px",

                }} className="recruiter-logo thumbnail-default" data-lightgallery="item" href={img.url}><img width={340} height={340} data-title="alt" src={img.url} alt="" /><p className="icon text-center" style={{ fontSize: '20px', borderRadius: "15px" }} fontFamily="Merriweather">{img.text}</p></a>
              )
            })}
          </div>
        </section>

      </Grid>

    </React.Fragment>
  );
};

export default PlacementLogs;
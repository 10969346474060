import { useState } from "react";
const ECEDeptPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            <img src="ECEDeptAssets/images/NIT-ECE-bnner.jpg" className="img-fluid" />
          </header>
          <div className="row">
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>About</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programmes</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                  <span>People </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected="false">
                  <span>Notable Alumni </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected="false">
                  <span>Students Society </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research Group</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected="false"> <span>Awards and Honours</span> </a>
                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected="false">
                  <span>Laboratory & Facilities</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected="false"> <span>Photo Gallery</span> </a>
              </div>



              {/*Head of the Dept Section Start*/}
              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ec-vakula"
                target="_blank"> <img src="ECEDeptAssets/images/f1.jpg" alt="" className="img-fluid" />
                <h6>Prof. D. Vakula</h6>
              </a>
                <p> Head of the Department <br /> <a href="mailto:ece_hod@nitw.ac.in"
                  style={{ color: "#808080" }}>ece_hod@nitw.ac.in</a> <br /> 08702462400, 9490165347 </p>
              </div>
              {/*Head of the Dept Section End*/}



            </div>
            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p>National Institute of Technology Warangal was established in 1959, and the Department of Electronics and Communication Engineering has been one of the key departments of the institute since its inception in the year 1971. The department has expanded steadily over the last few decades and is now recognized as one of India's leading academic and research centers. The department has strong liaisons with academics and industry across the globe. The department also provides support to R&D organizations. The faculty is actively engaged in providing solutions to industry problems as consultants. The department's academic programs are rated among the top in the country. The graduating students are well-placed in leading academic institutes, research organizations and industries.</p>
                    <p>The Department of Electronics and Communication Engineering is one of the country’s largest ECE departments among all NITs in India and one of the largest National Institute of Technology, Warangal (NITW) departments. The ECE Department at NITW has an international reputation for excellence in teaching, research and service. With excellent laboratory facilities and dedicated faculty, the department of ECE offers a broad range of programs that include undergraduate (B.Tech) and postgraduate (M.Tech) in Electronics Instrumentation, VLSI System Design, Communication Systems and research (PhD) programs. Some of the recent sponsored project undertaken by the department includes Radar Emitter Identification using Neural Networks sponsored by DLRL, Hyderabad and Special Manpower Development in VLSI sponsored by MIT-Govt. of India.</p>

                    <h3>Academic Programs</h3>
                    <p>The department offers a comprehensive Bachelor in Technology program in Electronics and Communication Engineering and Electronics and Communication Engineering (VLSI Design and Technology) from A.Y 2023-24. An intensive Master in Technology program in three specializations- Electronic Instrumentation, VLSI and Advanced Communication.</p>

                    <img src="ECEDeptAssets/images/timeline-ece.jpg" alt="" className="img-fluid"></img>

                  </div>
                                    {/* NUMBERING START*/}
                                    <div class="row nirf-ranking-counter text-center">
                         
                         
                        
                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>38</span></h2>
                                 
                                 <h6 class="mt-0"><b>Faculty</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>13</span></h2>
                                 <h6 class="mt-0"><b>Staff</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>101</span></h2>
                                 <h6 class="mt-0"><b>PhD Research Scholars</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>689</span></h2>
                                 <h6 class="mt-0"><b>Undergraduate Students</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>188</span></h2>
                                 <h6 class="mt-0"><b>Postgraduate Students</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>444</span></h2>
                                 <h6 class="mt-0"><b>Publications</b></h6>
                             </div>
                         </div>

                         
                       
                     </div>

                  <div className="row vision-mission-section">


                    <div className="col-md-6 vision-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                        alt="" /> Vision</h4>
                      <p className="mb-2">Create an educational environment to mould the students to meet the challenges of modern Electronics & Communication industry through state of the art technical knowledge and innovative experimental approaches.</p>

                      <img src="ECEDeptAssets/images/vision-img.jpg" className="img-fluid mr-3"
                        alt="" />


                      {/* <ul>
                                                            <li>Upto date Technical Knowledge</li>
                                                            <li>Analytical and Practical skills</li>
                                                            <li>A creative and innovative approach to work</li>
                                            </ul> */}


                      {/* <h4 className="mt-4 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>

                                            <p className="mb-2">Create Research, Development and Testing environment to meet ever changing and ever demanding needs of the Electronic Industry in particular, along with IT & other inter disciplinary fields in general.</p> */}

                    </div>


                    <div className="col-md-6 mission-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                        alt="" /> Mission</h4>
                      <p className="  mb-2">To create learning, development and testing environment to meet ever challenging needs of the electronic industry</p>
                      <p className="  mb-2">To create entrepreneurial environment and industry interaction for mutual benefit</p>
                      <p className="  mb-2">To become a global partner in training human resources in the fields of chip design, instrumentation and networking</p>
                      <p className="  mb-2">To associate with internationally reputed Institutions for academic excellence and collaborative research</p>

                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className="mt-2 mb-2 notice-board1">
                      <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardECE/ElectronicsandCommunicationEngineering" > Notices/Announcements</a>
                    </h4>
                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                  {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                  <div className="row">


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div >
                            <h3>B.Tech</h3>
                            {/* <p>Scheme and Syllabi</p> */}
                            {/*<img src="CSEDeptAssets/images/btech.jpg" alt="" className="img-fluid"/>*/}
                          </div>

                          <p className="more mt-2"> <a className="more-info-link"
                            href="ECEDeptAssets/docs/b-tech-ece-final-07062022.pdf" target="_blank"> B.Tech Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                          <p className="more"> <a className="more-info-link"
                            href="ECEDeptAssets/docs/BTech_ECE_VLSI_-_Curriculum_2023-5-22-17-45-46.pdf" target="_blank"> B.Tech (VLSI Design and Technology) Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>




                          {/* <p className="more mt-2"> <a className="more-info-link"
                                                        href="ECEDeptAssets/docs/btech_ece_syllabus_2017.pdf" target="_blank"> Electronics and Communication Engineering (Effective from 2017 - 18) <i className="fa fa-angle-right"></i> </a> </p> */}



                        </div>
                      </div>
                    </div>


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div>
                            <h3>M.Tech</h3>
                            {/* <p>Scheme and Syllabi <br/>
                                                        Electronics & Communication Engineering (Effective from 2021-22)
                                                        </p> */}
                            {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                          </div>

                          <p className="more">
                            <a className=" more-info-link"
                              href="https://nitw.ac.in/api/static/files/Embedded_and_Machine_Learning_Systems_2024-6-5-13-27-48.pdf"
                              target="_blank">M.Tech (Embedded and Machine Learning Systems) Scheme and Syllabus <i
                                className="fa fa-angle-right"></i> </a>
                          </p>

                          <p className="more mt-2">
                            <a className=" more-info-link"
                              href="ECEDeptAssets/docs/2_vlsi-system-design.pdf"
                              target="_blank">M.Tech (VLSI System Design)  Scheme and Syllabus <i className="fa fa-angle-right"></i> </a>
                          </p>

                          <p className="more mt-2">
                            <a className=" more-info-link"
                              href="ECEDeptAssets/docs/m-tech-acs-syllabus-2019-2020.pdf"
                              target="_blank">M.Tech (Advanced Communication Systems) Scheme and Syllabus <i className="fa fa-angle-right"></i>
                            </a>
                          </p>


                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div>
                            {/* <h3>Electronics & Communication Engineering  </h3>
                                                        <p>(Effective from 2019-20)</p> */}
                            {/*<img src="CSEDeptAssets/images/mtech2.jpg" alt="" className="img-fluid"/>*/}
                          </div>


                          {/* <p className="more"> <a className="more-info-link"
                                                        href="ECEDeptAssets/docs/mteceiembedded-systems_new_syll_senate-1.pdf"
                                                        target="_blank"> Electronic Instrumentation & Embedded Systems <i className="fa fa-angle-right"></i> </a> </p>


                                                        <p className="more mt-2"> <a className="more-info-link"
                                                        href="ECEDeptAssets/docs/syllabus_mtech_vlsi_updated-1.pdf"
                                                        target="_blank"> VLSI System Design <i className="fa fa-angle-right"></i> </a> 
                                                        </p>

                                                        <p className="more mt-2"> <a className="more-info-link"
                                                        href="ECEDeptAssets/docs/m-tech-acs-syllabus-2019-2020-1.pdf"
                                                        target="_blank"> Advanced Communication Systems <i className="fa fa-angle-right"></i> </a> 
                                                        </p> */}



                        </div>
                      </div>
                    </div>




                    {/*<div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>MCA</h3>
                                                        <p>The Master of Computer Applications (MCA) program was started in 1986
                                                            with an intake of 30 and increased to 46 from 2008. Currently the
                                                            strength is 58.</p>
                                                        <img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>
                                                    </div>
                                                    <p className="more"> <a className="more-info-link" href="CSEDeptAssets/docs/cse-mca-tt.pdf"
                                                        target="_blank"> MCA Even Semester Timetable <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>*/}



                    {/*<div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D.</h3>
                                                        <img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>
                                                    </div>
                                                    <p className="more"> <a className="buttton more-info-link"
                                                        href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                        target="_blank"> Rules and Regulations of Ph.D. Program <i
                                                            className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>*/}




                  </div>

                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div className="row laboratory-facilities">
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Electronic Instrumentation Lab</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className="card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            {/* <h4 className="card-title  mb-2">Hardware</h4> */}
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">Transducer and Signal Cond. Lab</li>
                              {/* <p className="list-para pb-0 mb-3">Number of Systems: 40</p> */}
                              <li className="text-black">PC Based Instrumentation Lab</li>
                              <li className="text-black">Electronic Instrumentation Lab</li>
                              {/* <p className="list-para pb-0 mb-3">Number of Systems: 5</p> */}
                            </ul>
                            {/* <h4 className="card-title  mb-2"> Software</h4>
                                                        <p>Dual boot with Windows 7 and Ubuntu 14.04, NetBeans 7, MSDN, Dev C++
                                                        </p> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <img src="ECEDeptAssets/images/ele_ins_lab.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Communication Labs</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">

                            {/* <h4 className="card-title  mb-2">Hardware</h4> */}
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">Communication System Lab</li>
                              <li className="text-black">Microwave & Optical Communication Lab</li>
                              <li className="text-black">Vector Network Analyzer, Spectrum Analyzer, MIC kit</li>
                              <li className="text-black">Microwave Test benches and MIC Design Software</li>
                              {/* <p className="list-para pb-0 mb-3">Number of Systems: 50</p> */}
                            </ul>
                            {/* <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Cognos BIClementine Version 11.0, Oracle 11g (Unlimited user
                                                            Licenses), MSDN Academic Alliance Software, Informatica (25 user
                                                            licenses )</p> */}
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="ECEDeptAssets/images/com_sys_lab.jpg"
                          alt="" className="img-fluid" /> </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Analog & Digital Circuit Labs</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className=" card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            {/* <h4 className="card-title  mb-2">Hardware</h4> */}
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">Electronic Devices & Circuits Lab</li>
                              <li className="text-black">Integrated Circuits Lab</li>
                              <li className="text-black">Microprocessor & Microcontroller lab</li>
                              <li className="text-black">Micro processing and Interfacing lab with 8086 &8085</li>
                              <li className="text-black">Microcontroller 8051 &32 bit channel Logic Analyzer</li>
                              {/* <p className="list-para pb-0 mb-3">Number of Systems: 45</p> */}
                            </ul>
                            {/* <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Dual boot with Windows XP and Ubuntu 12.0, NetBeans 7, Oracle Client
                                                            10</p> */}
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="ECEDeptAssets/images/integrated_ckt_lab.jpg"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">VLSI Design Labs</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className=" card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            {/* <h4 className="card-title  mb-2">Hardware</h4> */}
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                              {/* <p className="list-para pb-0 mb-3">Number of Systems: 50</p> */}
                              <li className="text-black">Electronic Computer Design & Automation Lab</li>
                              <li className="text-black">Analog IC Design lab</li>
                              <li className="text-black">PDA Lab</li>
                              <li className="text-black">ALTERA FOUNDATION Series</li>
                              <li className="text-black">Synopsis Leonardo Synthesizer</li>
                              <li className="text-black">SPICE Variants</li>
                              <li className="text-black">CADENCE & MENTOR graphics mixed signal kits Synopsis & magma Digital Kits</li>
                              <li className="text-black">XILINX FPGAs &CPLDs-DSP</li>
                              {/* <p className="list-para pb-0 mb-3">Number of Systems: 40</p> */}
                            </ul>
                            {/* <h4 className="card-title  mb-2">Software</h4>
                                                        <p>Rational Testing Suite, MSDN, NetBeans 7, Microsoft Assembler, Dev
                                                            C++ </p> */}
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="ECEDeptAssets/images/analog_ic_design_lab.jpg" alt="Photo of sunset"
                          className="img-fluid" /> </div>
                      </div>
                    </div>

                    {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Cloud Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">i7 processor, 16GB RAM</li>

                                                            <li className="text-black">Windows OS, Ubuntu OS</li>

                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/cloud-lab.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div> */}

                    {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Wireless and Security Laboratory</h3>
                                            </header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card item-card card-block">
                                                        <h4 className="card-title  mb-2">Hardware</h4>
                                                        <ul className="ps-0 list-unstyled">
                                                            <li className="text-black">HP Proliant 8300 Micro Tower systemsi5
                                                                @3.40GHz, RAM 2GB, 500GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 30</p>
                                                            <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 10</p>
                                                            <li className="text-black">HCL Dual core2Intel® Core™ 2 Duo CPU
                                                                E4500@2.20 GHz, 1GB RAM, 160GB HDD</li>
                                                            <p className="list-para pb-0 mb-3">Number of Systems: 4</p>
                                                        </ul>
                                                        <h4 className="card-title  mb-2">Software</h4>
                                                        <p>ONE Simulator, NS3, Rational Rose Tools, MSDN, Dev C++</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"> <img src="CSEDeptAssets/images/wireless.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div>
                                        </div> */}

                    {/* <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            <header>
                                                <h3 className="">Servers</h3>
                                            </header>
                                            <div className="row">
                                          
                                                <div className="col-md-12"> <img src="CSEDeptAssets/images/server.jpg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div>
                                            </div> <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Windows File Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                                                            Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Oracle Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL160 Gen8 Intel®
                                                            Xeon® CPU E5 -2630 @2.30GHz with 16GB RAM, 2*1TB </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">McAFEE Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HCL Intel® Xeon® CPU 3.40GHz
                                                            with 4GB RAM, 3*120GB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Proxy Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>Hp Compaq Elite 8300 SFF (i5
                                                            -3470) CPU @3.40GHz, RAM 2GB, HDD 500GB </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">COGNOS Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360 Intel® Xeon®
                                                            CPU 3.20GHz, 3GB RAM, 160GB HDD </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Rational Rose Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>IBM e-server Intel® Xeon™ CPU
                                                            2.40 GHz, 1GB RAM, 40GB HDD </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Linux Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                                                            Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD) </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">High Performance Computing Server (8
                                                            node Cluster)</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL380P Gen82 x
                                                            Intel® xeon® CPU E5-2640 (2.5 GHz / 6-core/15MB / 95w) Processor64
                                                            GB DDR RAM, HP SA 410i RAID controller with 1 GB FBWCHP SN1000E 16GB
                                                            Dual Port, FC HBA/1200WIntel® xeon® CPU E5-2640 (2.5 GHz /
                                                            6-core/15MB / 95w) ProcessorIntel 7500 chip set with node controller
                                                        </p>
                                                    </div>
                                                    <div className="details-card">
                                                        <h4 className="card-title  mb-2">Big Data Server</h4>
                                                        <p className="text-black"> <b>Hardware: </b>HP Proliant DL980 Gen78 x Intel®
                                                            xeon® CPU E7-2830 (2.13 GHz / 8-core/24MB / 105w) ProcessorIntel
                                                            7500 chip set with node controller, 512 GB DDR RAMHP SA 410i RAID
                                                            controller with 1 GB FBWC6x600GB 10K HDD, DVD + RW 12 Nos. of PCI
                                                            slots4x dual port Giga Bit Ethernet NIC and 2x 10GBPS Ethernet
                                                            CardHP SN1000E 16GB Dual Port, FC HBA/1200WRack Mountable Hot
                                                            pluggable Redundant Power Supplies and Redundant fans/ Server
                                                            Manageability SoftwareOS: CentOS 6.5 Server with Hadoop2.2 </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}




                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <p>List of program specific laboratories and computing facility within department.</p>
                      <div className="row">
                        <div className="col-md-12">


                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th>S.NO </th>
                                <th>Name of the Laboratory</th>
                                <th>Room No</th>
                                <th>Area (Sq. m)</th>
                              </tr>
                            </thead>

                            <tbody>

                              <tr>
                                <td>1</td>
                                <td>ECE Stores</td>
                                <td>ECE B1</td>
                                <td>55.11</td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>Basic Electronics Lab </td>
                                <td>ECE B2 </td>
                                <td>100.95 </td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>Analog IC Design Lab </td>
                                <td>ECE 104 </td>
                                <td>77.22 </td>
                              </tr>

                              <tr>
                                <td>4</td>
                                <td>Mixed Signal Design Lab </td>
                                <td>ECE 105 </td>
                                <td>132.3</td>
                              </tr>


                              <tr>
                                <td>5</td>
                                <td>Telecom Software/Networks Lab</td>
                                <td>ECE 108  </td>
                                <td>103.95 </td>
                              </tr>


                              <tr>
                                <td>6</td>
                                <td>Tranducers and Signal Conditioning Lab </td>
                                <td>ECE 116  </td>
                                <td>112.95  </td>
                              </tr>


                              <tr>
                                <td>7</td>
                                <td>Digital Signal Processing Lab</td>
                                <td>ECE 208</td>
                                <td>73.8 </td>
                              </tr>


                              <tr>
                                <td>8</td>
                                <td>Elelctronic Instrumentation Lab</td>
                                <td>ECE 209 </td>
                                <td>65.25</td>
                              </tr>

                              <tr>
                                <td>9</td>
                                <td>PC Based Instrumentation Lab</td>
                                <td>ECE 210 </td>
                                <td>65.25</td>
                              </tr>


                              <tr>
                                <td>10</td>
                                <td>Wireless Communication Networks Lab</td>
                                <td>ECE 220</td>
                                <td>66.06 </td>
                              </tr>


                              <tr>
                                <td>11</td>
                                <td>Communication Systems Lab</td>
                                <td>ECE 221</td>
                                <td>74.66  </td>
                              </tr>

                              <tr>
                                <td>12</td>
                                <td>Microwave and Optical Communication Lab</td>
                                <td>ECE 222</td>
                                <td>74.66  </td>
                              </tr>

                              <tr>
                                <td>13</td>
                                <td>Integrated Circuits Lab </td>
                                <td>ECE 303 </td>
                                <td>128.7  </td>
                              </tr>

                              <tr>
                                <td>14</td>
                                <td>Electronic Design Automation / Microprocessors and Microconrollers Lab</td>
                                <td>ECE 313 </td>
                                <td>161.1</td>
                              </tr>


                              <tr>
                                <td>15</td>
                                <td>Electronic Circuits Lab </td>
                                <td>ECE 314  </td>
                                <td>121.5</td>
                              </tr>




                            </tbody>
                          </table>

                        </div>

                      </div>
                    </div>




                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>

                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                      id="nav-AdjunctFaculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor</a>



                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                      Staff</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                      data-toggle="tab" role="tab"
                      aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                  </div>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">
                      {/*<h4 className=" mb-4">Faculty</h4>*/}
                      {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                      {/* <section id="profile-info">


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-amarjitk"
                              target="_blank"> <img src="ECEDeptAssets/images/f35.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-amarjitk"
                                    target="_blank">Prof. Amarjit Kumar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 7906021481 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:amarjitk@nitw.ac.in">amarjitk@nitw.ac.in</a>
                                  </p>
                                  <p>THz devices and components, MIMO antenna, RF components for 5G and beyond, Reconfigurable Multiband and Multifunctional Radio-Frequency Integrated Circuits, Active and Passive RF/Microwave/Millimetre waves Circuits, RF Transmitter and Receiver front-ends, RFICs, MICs, RF Wireless Sensors, Microwave Antennas Development</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16297"
                              target="_blank"> <img src="ECEDeptAssets/images/f4.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16297"
                                    target="_blank">Prof. Anjaneyulu L</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969355, 9490378430 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:anjan@nitw.ac.in">anjan@nitw.ac.in </a>
                                  </p>
                                  <p>Microwave Engg, Radar Engg, Antennas & Propagation, Computer Networks, ANNs, Under water communication, ECM/ECCM/EW Techniques</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16928"
                              target="_blank"> <img src="ECEDeptAssets/images/f25.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16928"
                                    target="_blank">Prof. Arun Kumar Gande</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462429 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:g.arun@nitw.ac.in">g.arun@nitw.ac.in</a>
                                  </p>
                                  <p>RF and Microwave Engineering, Radar Engineering, RFIC Design, time modulated RF circuits, Microwave and Millimeter wave passive and active circuits (Balanced mixers, Low noise amplifiers, Switches- SPST, SPDT and SPNT, Power dividers, Filters etc) ; Communication and Radar front end design, Metamaterials, Substrate Integrated Waveguides circuits and antennas, and Reconfigurable filters and antennas. ;If you are interested in pursuing PhD in the above mentioned topics at NIT Warangal, send me an e-mail. Interviews for our PhD research programs are held twice a year. ; Interested B. Tech students for Summer Internships may send their CV to my e-mail during the months of February-March.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-atul"
                              target="_blank"> <img src="ECEDeptAssets/images/f26.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-atul"
                                    target="_blank">Prof. Atul Kumar Nishad</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8106500502 <br />  <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:atul@nitw.ac.in">atul@nitw.ac.in</a>
                                  
                                  </p>
                                  <p>Nanoscale Interconnects and Devices, Low Power VLSI Circuit Design, Cryogenic Electronics, Memristor based memory and Logic Design, Spintronics, First-Principle Study, Low-dimensional (1D/2D) materials</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16917"
                              target="_blank"> <img src="ECEDeptAssets/images/f30.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16917"
                                    target="_blank">Prof. B K N Srinivasarao</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9800296596 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:srinu.bkn@nitw.ac.in">srinu.bkn@nitw.ac.in </a>
                                  </p>
                                  <p>VLSI architectures for image and video processing, Embedded systems design, IOT, System-on-Chip design,Network-on-Chip.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-chvramarao"
                              target="_blank"> <img src="ECEDeptAssets/images/f20.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-chvramarao"
                                    target="_blank">Prof. Ch. V. Rama Rao</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9402187886 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chvramarao@nitw.ac.in">chvramarao@nitw.ac.in</a>
                                  </p>
                                  <p>Speech Processing, Signal Processing, Image Processing, Machine Learning Algorithms for Signal Processing, Signal Processing Issues in Advanced Communication Systems, Pattern Recognition</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17059"
                              target="_blank"> <img src="ECEDeptAssets/images/f37.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17059"
                                    target="_blank">Prof. Chayan Bhar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 7908826629 <br /><i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:cbhar@nitw.ac.in">cbhar@nitw.ac.in </a>
                                  </p>
                                  <p>AI for networks; Reinforcement Learning based Network Design; Network Design and Analysis; Optical networks; access networks; metro networks; service migration; resource allocation; optical communication; network design analysis and performance evaluation; wireless-optical integrated networks; wireless networks; drone-based networks.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-ektagoel"
                              target="_blank"> <img src="ECEDeptAssets/images/f36.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-ektagoel"
                                    target="_blank">Prof. Ekta Goel</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8052696820 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ektagoel@nitw.ac.in">ektagoel@nitw.ac.in</a>
                                  </p>
                                  <p>Semiconductor device modeling and simulations, Nanoscale Devices, Advanced MOS Devices, Solar Cells.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16890"
                              target="_blank"> <img src="ECEDeptAssets/images/f29.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16890"
                                    target="_blank">Prof. Gopi Ram</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9679983382 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gopi.ram@nitw.ac.in">gopi.ram@nitw.ac.in</a>
                                  </p>
                                  <p>Antenna Design; Antenna and Antenna Arrays Design; Analysis and Synthesis of Radiation pattern; RF and Microwave; Time-Modulated Antenna Array Structures; Design of antennas for Wireless Power Transfer; Beamforming in Antenna Arrays; DOA Estimation and Beamforming; Frequency Diverse Array Antenna; Retrodirective Antennas; MIMO Antennas; 5G/6G Antennas; Wideband Antennas; Metamaterial Antenna; Evolutionary Optimization Techniques; Soft Computing; Advanced Optimization /Soft Computing Techniques</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16293"
                              target="_blank"> <img src="ECEDeptAssets/images/f13.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16293"
                                    target="_blank">Prof. Hanumanth Rao T V K</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9494280298 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:tvkhrao75@nitw.ac.in">tvkhrao75@nitw.ac.in </a>
                                  </p>
                                  <p>Bio Medical Signal Processing; VLSI</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16295"
                              target="_blank"> <img src="ECEDeptAssets/images/f9.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16295"
                                    target="_blank">Prof. Hari Krishna Prasad P</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702430012, 8019434706 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:phkp@nitw.ac.in">phkp@nitw.ac.in  </a>
                                  </p>
                                  <p>Wireless communications</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16891"
                              target="_blank"> <img src="ECEDeptAssets/images/f28.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16891"
                                    target="_blank">Prof. Himansu Shekhar Pradhan</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9437903184 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:hsp@nitw.ac.in">hsp@nitw.ac.in</a>
                                  </p>
                                  <p>Fiber Optic Sensors; Optical Signal Processing; Nonlinear Fiber Optics; Optical Communication; Structural Health Monitoring using Fiber Optic Sensors; Free Space Optical Communication; Channel modelling in FSO system; Biomedical Sensors; SPR Based Biomedical Sensors; Next generation communication networks.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sai"
                              target="_blank"> <img src="ECEDeptAssets/images/f8.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sai"
                                    target="_blank">Prof. K L V Sai Prakash Sakuru</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9246551868 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sai@nitw.ac.in">sai@nitw.ac.in </a>
                                  </p>
                                  <p>Internet Of Things (IoT) & Cyber Physical Systems, Machine Learning Aand Smart Systems Design; Switching And Multiplexing; LTE 4G & 5G RAN; Ad-hoc and sensor networks; Green communications; Web technologies</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16910"
                              target="_blank"> <img src="ECEDeptAssets/images/f24.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16910"
                                    target="_blank">Prof. Kalpana Naidu</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9393941144 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kalpana@nitw.ac.in">kalpana@nitw.ac.in</a>
                                  </p>
                                  <p>6G wireless communication; Heterogeneous communication; Cognitive radio; Sensor Networks; Radio resource management; signal processing ; Machine Learning; UAV (Unmanned Aerial Vehicles )s ; NOMA ( Non-Orthogonal Multiple Access ) in 5G networks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-lakshmi"
                              target="_blank"> <img src="ECEDeptAssets/images/f11.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-lakshmi"
                                    target="_blank">Prof. Lakshmi B</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9493436845 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:lakshmi@nitw.ac.in">lakshmi@nitw.ac.in</a>
                                  </p>
                                  <p>Internet of Things, Machine Learning, VLSI Architectures, Digital System Design, Embedded System Design, Digital IC Design, FPGA Design, Low power VLSI design, ASIC Design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16887"
                              target="_blank"> <img src="ECEDeptAssets/images/f32.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16887"
                                    target="_blank">Prof. Maheshwaram Satish</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462423 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:satishm@nitw.ac.in">satishm@nitw.ac.in </a>
                                  </p>
                                  <p>Novel devices design and analysis using calibrated TCAD simulation setup; Compact modeling of novel devices aiming at faster circuit design and analysis; Characterization and fabrication of MOS devices/circuits; Design of analog and digital circuits for low power application in logic or sensory circuits; Design of embedded/IoT applications or systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16880"
                              target="_blank"> <img src="ECEDeptAssets/images/f31.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16880"
                                    target="_blank">Prof. Mohammad Farukh Hashmi</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9666740604 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:mdfarukh@nitw.ac.in">mdfarukh@nitw.ac.in </a>
                                  </p>
                                  <p>Digital Image Processing, Computer Vision, Machine Vision, Video Processing/Technology, Video Codec Design, Embedded Systems, Internet of Things(IOT), Digital Signal Processing(DSP), Machine/Deep Learning, Deep Learning for Health Care and Computer Vision, Digital Design, Embedded Image Processing, Biomedical Image Processing, SAR Imaging, Real Time Operating Systems, Image/Video Compression, Health Care Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-pmurali"
                              target="_blank"> <img src="ECEDeptAssets/images/f16.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-pmurali"
                                    target="_blank">Prof. Muralidhar Pullakandam</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 918702462442 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pmurali@nitw.ac.in">pmurali@nitw.ac.in</a>
                                     </p>
                                  <p>Embedded Systems Design ;Digital System Design, FPGA based designs ;VLSI Architectures; Video & Image Processing Algorithms and Architectures, ML and DL Accelerator design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-nbr"
                              target="_blank"> <img src="ECEDeptAssets/images/f5.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-nbr"
                                    target="_blank">Prof. N. Bheema Rao</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9492909194, 8702462432  <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:nbr@nitw.ac.in">nbr@nitw.ac.in</a>
                                  </p>
                                  <p>Design and Modelling of On-Chip Inductors for RF Applications, Device Modelling and Interconnect Modelling</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sarma"
                              target="_blank"> <img src="ECEDeptAssets/images/f2.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sarma"
                                    target="_blank">Prof. Narasimha Sarma N V S</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702462412 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sarma@nitw.ac.in">sarma@nitw.ac.in</a>
                                  </p>
                                  <p>Numerical Electromagnetics, Antennas, ANNs and Wireless Sensor Networks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-aprao"
                              target="_blank"> <img src="ECEDeptAssets/images/f15.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-aprao"
                                    target="_blank">Prof. Prakasa Rao A</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969358 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:aprao@nitw.ac.in">aprao@nitw.ac.in</a>
                                  </p>
                                  <p>Smart Antenna Systems, Optimization Techniques, EDC and Signals & Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-kprakash"
                              target="_blank"> <img src="ECEDeptAssets/images/f33.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-kprakash"
                                    target="_blank">Prof. Prakash Kodali</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8074035659 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kprakash@nitw.ac.in">kprakash@nitw.ac.in</a>
                                  </p>
                                  <p>Printed Flexible Electronics, Embedded Systems, VLSI - Nano Electronics, Signal Conditioning Circuits & Systems, and Biomedical Electronics.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16298"
                              target="_blank"> <img src="ECEDeptAssets/images/f21.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16298"
                                    target="_blank">Prof. Prithvi Pothupogu</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9849317318, 8332969360 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:prithvi@nitw.ac.in">prithvi@nitw.ac.in</a>
                                  </p>
                                  <p>Digital System Designs, Speech Processing, Embedded Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-raghu"
                              target="_blank"> <img src="ECEDeptAssets/images/f6.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-raghu"
                                    target="_blank">Prof. Raghunadh M V</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 98949799747 <br />  <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:raghu@nitw.ac.in">raghu@nitw.ac.in</a>
                                  </p>
                                  <p>Communication Systems, Image Processing, Pattern Recognition</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-kishore"
                              target="_blank"> <img src="ECEDeptAssets/images/f7.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-kishore"
                                    target="_blank">Prof. Ravi Kishore Kodali</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 8985230362 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:kishore@nitw.ac.in">kishore@nitw.ac.in</a>
                                  </p>
                                  <p>Internet of Things Smart cities Computer Networks and Security Wireless Communications Wireless Sensor Networks, Digital System Design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-ravikumar"
                              target="_blank"> <img src="ECEDeptAssets/images/f18.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-ravikumar"
                                    target="_blank">Prof. Ravi Kumar Jatoth</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702462444 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ravikumar@nitw.ac.in">ravikumar@nitw.ac.in</a>
                                  </p>
                                  <p>Signal Processing Algorithms, Machine Learning AlgorithmsNature Inspired Algorithms .Process Control Design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16306"
                              target="_blank"> <img src="ECEDeptAssets/images/f19.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16306"
                                    target="_blank">Prof. S. Anuradha</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9490334276, 8332969364 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:anuradha@nitw.ac.in">anuradha@nitw.ac.in</a>
                                  </p>
                                  <p>Wireless Communications, Coding Techniques, Fading Channels, Cognitive Radios, Signal Processing for Communications, Antennas for Cognitive Radios</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sarangam_7"
                              target="_blank"> <img src="ECEDeptAssets/images/f23.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sarangam_7"
                                    target="_blank">Prof. Sarangam K</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702462445 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sarangam_7@nitw.ac.in">sarangam_7@nitw.ac.in</a>
                                  </p>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-pawarshuvam"
                              target="_blank"> <img src="ECEDeptAssets/images/f38.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-pawarshuvam"
                                    target="_blank">Prof. Shuvam Pawar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 94576 42108 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pawarshuvam@nitw.ac.in">pawarshuvam@nitw.ac.in</a>
                                  </p>
                                  <p>SAW & BAW resonators, Sensors and Actuators, Thin Films Technology and Semiconductor Devices.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-patri"
                              target="_blank"> <img src="ECEDeptAssets/images/f14.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-patri"
                                    target="_blank">Prof. Sreehari Rao Patri</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969357 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:patri@nitw.ac.in">patri@nitw.ac.in</a>
                                  </p>
                                  <p>Analog/Mixed Signal IC Design, Power Management IC Design, RF IC Design, On-chip Smart Sensors, Communication Systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sridhar67nitw"
                              target="_blank"> <img src="ECEDeptAssets/images/f12.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sridhar67nitw"
                                    target="_blank">Prof. Sridhar K V</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9849548428, 8332969356 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sridhar@nitw.ac.in">sridhar@nitw.ac.in </a>
                                  </p>
                                  <p>Bio-medical signal / Image processingAdvanced Signal Processing, Signal processing</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-kishoret"
                              target="_blank"> <img src="ECEDeptAssets/images/f3.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-kishoret"
                                    target="_blank">Prof. T. Kishore Kumar</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969353, 9440973318 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kishoret@nitw.ac.in">kishoret@nitw.ac.in</a>
                                    </p>
                                  <p>Artificial Intelligence and Machine Learning for Speech, Image and Medical Applications; AI in healthcare; Adaptive Signal Processing ; Advanced Digital System Design; Internet of Things, Real Time Embedded System</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-agni"
                              target="_blank"> <img src="ECEDeptAssets/images/f22.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-agni"
                                    target="_blank">Prof. V. Rama</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440762744 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vr.nitw@gmail.com">vr.nitw@gmail.com</a>
                                  </p>
                                  <p>Bio-Medical Signal Processing; Artificial Neural Networks; Analog Electronics , Digital Logic Design ; IC applications</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16923"
                              target="_blank"> <img src="ECEDeptAssets/images/f27.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16923"
                                    target="_blank">Prof. Vadthiya Narendar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9795235922 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:narendarv@nitw.ac.in">narendarv@nitw.ac.in</a>
                                  </p>
                                  <p>Beyond CMOS, Nanoscale device design, Modeling and simulation of semiconductor devices, 2D material based devices, Neuromorphic Computing, VLSI Circuits & Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16303"
                              target="_blank"> <img src="ECEDeptAssets/images/f1.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16303"
                                    target="_blank">Prof. Vakula D</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969362, 9490378554 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vakula@nitw.ac.in">vakula@nitw.ac.in</a> </p>
                                  <p>Antenna Arrays, Multi-function Antennas, Ultra Wide Band Antennas, Fault Diagnosis of Antenna Arrays, Artificial Neural Networks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17061"
                              target="_blank"> <img src="ECEDeptAssets/images/f34.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17061"
                                    target="_blank">Prof. Vasundhara</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9778162166 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vasundhara@nitw.ac.in">vasundhara@nitw.ac.in</a>
                                  </p>
                                  <p>Adaptive Signal Processing; System Identification and Parameter Estimation; Feedback Cancellation in Hearing aids; Distributed Systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-vvmani"
                              target="_blank"> <img src="ECEDeptAssets/images/f17.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-vvmani"
                                    target="_blank">Prof. Venkata Mani V</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9705024365 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vvmani@nitw.ac.in">vvmani@nitw.ac.in </a>
                                  </p>
                                  <p>Signal Processing for wireless communication, MIMO, OFDM, Millimeter Wave, and Green Communication, Visible Light Communication,5G new waveform design, Software-defined radio and Testbed for 5G</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </section> */}
                      <section id="profile-info">


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-amarjitk"
                              target="_blank"> <img src="ECEDeptAssets/images/f35.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-amarjitk"
                                    target="_blank">Prof. Amarjit Kumar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 7906021481 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:amarjitk@nitw.ac.in">amarjitk@nitw.ac.in</a>
                                  </p>
                                  <p>THz devices and components, MIMO antenna, RF components for 5G and beyond, Reconfigurable Multiband and Multifunctional Radio-Frequency Integrated Circuits, Active and Passive RF/Microwave/Millimetre waves Circuits, RF Transmitter and Receiver front-ends, RFICs, MICs, RF Wireless Sensors, Microwave Antennas Development</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-anjan"
                              target="_blank"> <img src="ECEDeptAssets/images/f4.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-anjan"
                                    target="_blank">Prof. Anjaneyulu L</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969355, 9490378430 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:anjan@nitw.ac.in">anjan@nitw.ac.in </a>
                                  </p>
                                  <p>Microwave Engg, Radar Engg, Antennas & Propagation, Computer Networks, ANNs, Under water communication, ECM/ECCM/EW Techniques</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-g.arun"
                              target="_blank"> <img src="ECEDeptAssets/images/f25.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-g.arun"
                                    target="_blank">Prof. Arun Kumar Gande</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462429 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:g.arun@nitw.ac.in">g.arun@nitw.ac.in</a>
                                  </p>
                                  <p>RF and Microwave Engineering, Radar Engineering, RFIC Design, time modulated RF circuits, Microwave and Millimeter wave passive and active circuits (Balanced mixers, Low noise amplifiers, Switches- SPST, SPDT and SPNT, Power dividers, Filters etc) ; Communication and Radar front end design, Metamaterials, Substrate Integrated Waveguides circuits and antennas, and Reconfigurable filters and antennas. ;If you are interested in pursuing PhD in the above mentioned topics at NIT Warangal, send me an e-mail. Interviews for our PhD research programs are held twice a year. ; Interested B. Tech students for Summer Internships may send their CV to my e-mail during the months of February-March.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-atul"
                              target="_blank"> <img src="ECEDeptAssets/images/f26.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-atul"
                                    target="_blank">Prof. Atul Kumar Nishad</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8106500502 <br />  <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:atul@nitw.ac.in">atul@nitw.ac.in</a>
                                    {/* , <a
                                                        href="mailto:atulnishad@gmail.com">atulnishad@gmail.com</a> */}
                                  </p>
                                  <p>Nanoscale Interconnects and Devices, Low Power VLSI Circuit Design, Cryogenic Electronics, Memristor based memory and Logic Design, Spintronics, First-Principle Study, Low-dimensional (1D/2D) materials</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-srinu.bkn"
                              target="_blank"> <img src="ECEDeptAssets/images/f30.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-srinu.bkn"
                                    target="_blank">Prof. B K N Srinivasarao</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9800296596 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:srinu.bkn@nitw.ac.in">srinu.bkn@nitw.ac.in </a>
                                  </p>
                                  <p>VLSI architectures for image and video processing, Embedded systems design, IOT, System-on-Chip design,Network-on-Chip.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-chvramarao"
                              target="_blank"> <img src="ECEDeptAssets/images/f20.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-chvramarao"
                                    target="_blank">Prof. Ch. V. Rama Rao</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9402187886 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chvramarao@nitw.ac.in">chvramarao@nitw.ac.in</a>
                                  </p>
                                  <p>Speech Processing, Signal Processing, Image Processing, Machine Learning Algorithms for Signal Processing, Signal Processing Issues in Advanced Communication Systems, Pattern Recognition</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-cbhar"
                              target="_blank"> <img src="ECEDeptAssets/images/f37.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-cbhar"
                                    target="_blank">Prof. Chayan Bhar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 7908826629 <br /><i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:cbhar@nitw.ac.in">cbhar@nitw.ac.in </a>
                                  </p>
                                  <p>AI for networks; Reinforcement Learning based Network Design; Network Design and Analysis; Optical networks; access networks; metro networks; service migration; resource allocation; optical communication; network design analysis and performance evaluation; wireless-optical integrated networks; wireless networks; drone-based networks.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-ektagoel"
                              target="_blank"> <img src="ECEDeptAssets/images/f36.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-ektagoel"
                                    target="_blank">Prof. Ekta Goel</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8052696820 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ektagoel@nitw.ac.in">ektagoel@nitw.ac.in</a>
                                  </p>
                                  <p>Semiconductor device modeling and simulations, Nanoscale Devices, Advanced MOS Devices, Solar Cells.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-gopi.ram"
                              target="_blank"> <img src="ECEDeptAssets/images/f29.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-gopi.ram"
                                    target="_blank">Prof. Gopi Ram</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9679983382 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gopi.ram@nitw.ac.in">gopi.ram@nitw.ac.in</a>
                                  </p>
                                  <p>Antenna Design; Antenna and Antenna Arrays Design; Analysis and Synthesis of Radiation pattern; RF and Microwave; Time-Modulated Antenna Array Structures; Design of antennas for Wireless Power Transfer; Beamforming in Antenna Arrays; DOA Estimation and Beamforming; Frequency Diverse Array Antenna; Retrodirective Antennas; MIMO Antennas; 5G/6G Antennas; Wideband Antennas; Metamaterial Antenna; Evolutionary Optimization Techniques; Soft Computing; Advanced Optimization /Soft Computing Techniques</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-tvkhrao75"
                              target="_blank"> <img src="ECEDeptAssets/images/f13.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-tvkhrao75"
                                    target="_blank">Prof. Hanumanth Rao T V K</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9494280298 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:tvkhrao75@nitw.ac.in">tvkhrao75@nitw.ac.in </a>
                                  </p>
                                  <p>Bio Medical Signal Processing; VLSI</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-phkp"
                              target="_blank"> <img src="ECEDeptAssets/images/f9.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-phkp"
                                    target="_blank">Prof. Hari Krishna Prasad P</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702430012, 8019434706 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:phkp@nitw.ac.in">phkp@nitw.ac.in  </a>
                                  </p>
                                  <p>Wireless communications</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-hsp"
                              target="_blank"> <img src="ECEDeptAssets/images/f28.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-hsp"
                                    target="_blank">Prof. Himansu Shekhar Pradhan</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9437903184 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:hsp@nitw.ac.in">hsp@nitw.ac.in</a>
                                  </p>
                                  <p>Fiber Optic Sensors; Optical Signal Processing; Nonlinear Fiber Optics; Optical Communication; Structural Health Monitoring using Fiber Optic Sensors; Free Space Optical Communication; Channel modelling in FSO system; Biomedical Sensors; SPR Based Biomedical Sensors; Next generation communication networks.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sai"
                              target="_blank"> <img src="ECEDeptAssets/images/f8.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sai"
                                    target="_blank">Prof. K L V Sai Prakash Sakuru</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9246551868 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sai@nitw.ac.in">sai@nitw.ac.in </a>
                                  </p>
                                  <p>Internet Of Things (IoT) & Cyber Physical Systems, Machine Learning Aand Smart Systems Design; Switching And Multiplexing; LTE 4G & 5G RAN; Ad-hoc and sensor networks; Green communications; Web technologies</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-kalpana"
                              target="_blank"> <img src="ECEDeptAssets/images/f24.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-kalpana"
                                    target="_blank">Prof. Kalpana Naidu</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9393941144 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kalpana@nitw.ac.in">kalpana@nitw.ac.in</a>
                                  </p>
                                  <p>6G wireless communication; Heterogeneous communication; Cognitive radio; Sensor Networks; Radio resource management; signal processing ; Machine Learning; UAV (Unmanned Aerial Vehicles )s ; NOMA ( Non-Orthogonal Multiple Access ) in 5G networks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-lakshmi"
                              target="_blank"> <img src="ECEDeptAssets/images/f11.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-lakshmi"
                                    target="_blank">Prof. Lakshmi B</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9493436845 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:lakshmi@nitw.ac.in">lakshmi@nitw.ac.in</a>
                                  </p>
                                  <p>Internet of Things, Machine Learning, VLSI Architectures, Digital System Design, Embedded System Design, Digital IC Design, FPGA Design, Low power VLSI design, ASIC Design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-satishm"
                              target="_blank"> <img src="ECEDeptAssets/images/f32.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-satishm"
                                    target="_blank">Prof. Maheshwaram Satish</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462423 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:satishm@nitw.ac.in">satishm@nitw.ac.in </a>
                                  </p>
                                  <p>Novel devices design and analysis using calibrated TCAD simulation setup; Compact modeling of novel devices aiming at faster circuit design and analysis; Characterization and fabrication of MOS devices/circuits; Design of analog and digital circuits for low power application in logic or sensory circuits; Design of embedded/IoT applications or systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-mdfarukh"
                              target="_blank"> <img src="ECEDeptAssets/images/f31.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-mdfarukh"
                                    target="_blank">Prof. Mohammad Farukh Hashmi</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9666740604 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:mdfarukh@nitw.ac.in">mdfarukh@nitw.ac.in </a>
                                  </p>
                                  <p>Digital Image Processing, Computer Vision, Machine Vision, Video Processing/Technology, Video Codec Design, Embedded Systems, Internet of Things(IOT), Digital Signal Processing(DSP), Machine/Deep Learning, Deep Learning for Health Care and Computer Vision, Digital Design, Embedded Image Processing, Biomedical Image Processing, SAR Imaging, Real Time Operating Systems, Image/Video Compression, Health Care Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-pmurali"
                              target="_blank"> <img src="ECEDeptAssets/images/f16.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-pmurali"
                                    target="_blank">Prof. Muralidhar Pullakandam</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 918702462442 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pmurali@nitw.ac.in">pmurali@nitw.ac.in</a>
                                    {/* , <a
                                                        href="mailto:pmurali_nitw@yahoo.co.in">pmurali_nitw@yahoo.co.in</a> */}
                                  </p>
                                  <p>Embedded Systems Design ;Digital System Design, FPGA based designs ;VLSI Architectures; Video & Image Processing Algorithms and Architectures, ML and DL Accelerator design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-nbr"
                              target="_blank"> <img src="ECEDeptAssets/images/f5.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-nbr"
                                    target="_blank">Prof. N. Bheema Rao</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9492909194, 8702462432  <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:nbr@nitw.ac.in">nbr@nitw.ac.in</a>
                                  </p>
                                  <p>Design and Modelling of On-Chip Inductors for RF Applications, Device Modelling and Interconnect Modelling</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sarma"
                              target="_blank"> <img src="ECEDeptAssets/images/f2.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sarma"
                                    target="_blank">Prof. Narasimha Sarma N V S</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702462412 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sarma@nitw.ac.in">sarma@nitw.ac.in</a>
                                  </p>
                                  <p>Numerical Electromagnetics, Antennas, ANNs and Wireless Sensor Networks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-aprao"
                              target="_blank"> <img src="ECEDeptAssets/images/f15.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-aprao"
                                    target="_blank">Prof. Prakasa Rao A</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969358 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:aprao@nitw.ac.in">aprao@nitw.ac.in</a>
                                  </p>
                                  <p>Smart Antenna Systems, Optimization Techniques, EDC and Signals & Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-kprakash"
                              target="_blank"> <img src="ECEDeptAssets/images/f33.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-kprakash"
                                    target="_blank">Prof. Prakash Kodali</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8074035659 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kprakash@nitw.ac.in">kprakash@nitw.ac.in</a>
                                  </p>
                                  <p>Printed Flexible Electronics, Embedded Systems, VLSI - Nano Electronics, Signal Conditioning Circuits & Systems, and Biomedical Electronics.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-prithvi"
                              target="_blank"> <img src="ECEDeptAssets/images/f21.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-prithvi"
                                    target="_blank">Prof. Prithvi Pothupogu</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9849317318, 8332969360 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:prithvi@nitw.ac.in">prithvi@nitw.ac.in</a>
                                  </p>
                                  <p>Digital System Designs, Speech Processing, Embedded Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-raghu"
                              target="_blank"> <img src="ECEDeptAssets/images/f6.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-raghu"
                                    target="_blank">Prof. Raghunadh M V</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 98949799747 <br />  <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:raghu@nitw.ac.in">raghu@nitw.ac.in</a>
                                  </p>
                                  <p>Communication Systems, Image Processing, Pattern Recognition</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-kishore"
                              target="_blank"> <img src="ECEDeptAssets/images/f7.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-kishore"
                                    target="_blank">Prof. Ravi Kishore Kodali</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 8985230362 <br />
                                    <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto:kishore@nitw.ac.in">kishore@nitw.ac.in</a>
                                  </p>
                                  <p>Internet of Things Smart cities Computer Networks and Security Wireless Communications Wireless Sensor Networks, Digital System Design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-ravikumar"
                              target="_blank"> <img src="ECEDeptAssets/images/f18.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-ravikumar"
                                    target="_blank">Prof. Ravi Kumar Jatoth</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702462444 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ravikumar@nitw.ac.in">ravikumar@nitw.ac.in</a>
                                  </p>
                                  <p>Signal Processing Algorithms, Machine Learning AlgorithmsNature Inspired Algorithms .Process Control Design</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-anuradha"
                              target="_blank"> <img src="ECEDeptAssets/images/f19.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-anuradha"
                                    target="_blank">Prof. S. Anuradha</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9490334276, 8332969364 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:anuradha@nitw.ac.in">anuradha@nitw.ac.in</a>
                                  </p>
                                  <p>Wireless Communications, Coding Techniques, Fading Channels, Cognitive Radios, Signal Processing for Communications, Antennas for Cognitive Radios</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sarangam_7"
                              target="_blank"> <img src="ECEDeptAssets/images/f23.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sarangam_7"
                                    target="_blank">Prof. Sarangam K</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8702462445 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sarangam_7@nitw.ac.in">sarangam_7@nitw.ac.in</a>
                                  </p>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-pawarshuvam"
                              target="_blank"> <img src="ECEDeptAssets/images/f38.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-pawarshuvam"
                                    target="_blank">Prof. Shuvam Pawar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 94576 42108 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pawarshuvam@nitw.ac.in">pawarshuvam@nitw.ac.in</a>
                                  </p>
                                  <p>SAW & BAW resonators, Sensors and Actuators, Thin Films Technology and Semiconductor Devices.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a href="https://erp.nitw.ac.in/ext/profile/ec-ssolanki" target="_blank"> <img src="ECEDeptAssets/images/f39.jpeg" alt="" className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-ssolanki" target="_blank">Prof. Sourabh Solanki</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9826825567 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ssolanki@nitw.ac.in">ssolanki@nitw.ac.in</a>
                                  </p>
                                  <p>Non-Terrestrial Networks, THz Communication, Spectrum Sharing Networks, Cooperative Communication, Physical Layer Security.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-patri"
                              target="_blank"> <img src="ECEDeptAssets/images/f14.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-patri"
                                    target="_blank">Prof. Sreehari Rao Patri</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969357 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:patri@nitw.ac.in">patri@nitw.ac.in</a>
                                  </p>
                                  <p>Analog/Mixed Signal IC Design, Power Management IC Design, RF IC Design, On-chip Smart Sensors, Communication Systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-sridhar67nitw"
                              target="_blank"> <img src="ECEDeptAssets/images/f12.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-sridhar67nitw"
                                    target="_blank">Prof. Sridhar K V</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9849548428, 8332969356 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sridhar@nitw.ac.in">sridhar@nitw.ac.in </a>
                                  </p>
                                  <p>Bio-medical signal / Image processingAdvanced Signal Processing, Signal processing</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-kishoret"
                              target="_blank"> <img src="ECEDeptAssets/images/f3.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-kishoret"
                                    target="_blank">Prof. T. Kishore Kumar</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969353, 9440973318 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kishoret@nitw.ac.in">kishoret@nitw.ac.in</a>
                                    {/* , <a
                                                        href="mailto:,kishorefr@gmail.com">kishorefr@gmail.com</a> */}
                                  </p>
                                  <p>Artificial Intelligence and Machine Learning for Speech, Image and Medical Applications; AI in healthcare; Adaptive Signal Processing ; Advanced Digital System Design; Internet of Things, Real Time Embedded System</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-agni"
                              target="_blank"> <img src="ECEDeptAssets/images/f22.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-agni"
                                    target="_blank">Prof. V. Rama</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440762744 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vr.nitw@gmail.com">vr.nitw@gmail.com</a>
                                  </p>
                                  <p>Bio-Medical Signal Processing; Artificial Neural Networks; Analog Electronics , Digital Logic Design ; IC applications</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-narendarv"
                              target="_blank"> <img src="ECEDeptAssets/images/f27.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-narendarv"
                                    target="_blank">Prof. Vadthiya Narendar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9795235922 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:narendarv@nitw.ac.in">narendarv@nitw.ac.in</a>
                                  </p>
                                  <p>Beyond CMOS, Nanoscale device design, Modeling and simulation of semiconductor devices, 2D material based devices, Neuromorphic Computing, VLSI Circuits & Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-vakula"
                              target="_blank"> <img src="ECEDeptAssets/images/f1.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-vakula"
                                    target="_blank">Prof. Vakula D</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969362, 9490378554 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vakula@nitw.ac.in">vakula@nitw.ac.in</a> </p>
                                  <p>Antenna Arrays, Multi-function Antennas, Ultra Wide Band Antennas, Fault Diagnosis of Antenna Arrays, Artificial Neural Networks</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-vasundhara"
                              target="_blank"> <img src="ECEDeptAssets/images/f34.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-vasundhara"
                                    target="_blank">Prof. Vasundhara</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9778162166 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vasundhara@nitw.ac.in">vasundhara@nitw.ac.in</a>
                                  </p>
                                  <p>Adaptive Signal Processing; System Identification and Parameter Estimation; Feedback Cancellation in Hearing aids; Distributed Systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ec-vvmani"
                              target="_blank"> <img src="ECEDeptAssets/images/f17.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ec-vvmani"
                                    target="_blank">Prof. Venkata Mani V</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"><i className="fa fa-phone"
                                    aria-hidden="true"></i> 9705024365 <br />   <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vvmani@nitw.ac.in">vvmani@nitw.ac.in </a>
                                  </p>
                                  <p>Signal Processing for wireless communication, MIMO, OFDM, Millimeter Wave, and Green Communication, Visible Light Communication,5G new waveform design, Software-defined radio and Testbed for 5G</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </section>
                    </div>




                    <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                      aria-labelledby="nav-AdjunctFaculty-tab">

                      <div className="col-md-12 profile-info-individual">
                        <div className="row">

                          <div className="col-md-9">
                            <div className="card">
                              <div className="d-flex align-items-center">
                                <div className="image"> <img src="ECEDeptAssets/images/bhvs.jpg" alt=""
                                  className="img-fluid" /> </div>
                                <div className="ml-3 w-100">
                                  <h6 className="mb-0 mt-0"> Dr. BHVS Narayana Murthy</h6>
                                  <div className=" mt-2">
                                    <p className="contact-info"><i className="fa fa-phone" aria-hidden="true"></i> +91 9440712549<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bhvsnm@gmail.com">bhvsnm@gmail.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>










                        </div>
                      </div>
                    </div>










                    <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                      aria-labelledby="nav-NonTeachingStaff-tab">
                      <div className="row academic_group">


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st1.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Pandilla Shiva Kumar</h6> <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9059838843 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pandillas@nitw.ac.in">pandillas@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st2.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Manne Prahaseeth</h6> <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8639316201 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:mannep@nitw.ac.in">mannep@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st3.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Banoth Devender</h6>
                                <span>Technician SG-II</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969377 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:banothd@nitw.ac.in">banothd@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st4.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Gumma Rama Kotaiah</h6>
                                <span>Senior Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7989324263 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gummar@nitw.ac.in">gummar@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st5.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Chinta Yagna Mallesh</h6> <span>Senior Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9618760709 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chintay@nitw.ac.in">chintay@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st6.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Chelpuri Raju</h6> <span>Senior Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9959878052 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chelpurir@nitw.ac.in">chelpurir@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st7.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. A. Ramesh</h6> <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9705992331 </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st8.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Sindam Koushik</h6>
                                <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8801257364 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sindamk@nitw.ac.in">sindamk@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st9.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Gumasi Naresh</h6> <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8897457436<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gumasin@nitw.ac.in">gumasin@nitw.ac.in</a>


                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st10.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Ms. B. Srujana Yadav</h6> <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7386331003 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bavus@nitw.ac.in">bavus@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="ECEDeptAssets/images/st11.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Ginkala Bala Subbarayudu</h6> <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9989461166 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ginkalab@nitw.ac.in">ginkalab@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </div>

                    </div>
                    <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">
                      {/*<h4 className="">Ph.D. Awardee</h4>
                    <p>
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






                      <div className="row phd-std-row">



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img ">

                              <img src="ECEDeptAssets/images/phd1.jpg" className="img-fluid image" alt="" />

                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b></b>
                  </p>
                  
                </div>
              </div> */}


                            </div>
                            <h6 className="std-title">Maryala Praveen</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>719072</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Atul Kumar Nishad</b> </span>
                            <hr />

                            <p className="contact-info">  8143696431 <br />

                              <span><a href="mailto:praveenm@student.nitw.ac.in"> praveenm@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd2.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Computational Neuroscience</b>
                  </p>
                
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Vaddi Venkata
                              Narayana
                            </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720058</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Prakash
                              Kodali
                            </b> </span>
                            <hr />

                            <p className="contact-info">  9908689295 <br />

                              <span><a href="mailto:vv720058@student.nitw.ac.in"> vv720058@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>








                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd3.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Cryptography</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Krishna Sundeep Basavaraju</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721065</b> </span><br></br>
                            <span>Supervisor: <b>Prof. T. Kishore Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  8886770533 <br />

                              <span><a href="mailto:bs721065@student.nitw.ac.in"> bs721065@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd4.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Social Network Analysis</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Chilaka Jayaram</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER04</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Patri Sreehari Rao</b> </span>
                            <hr />

                            <p className="contact-info">  8985017101 <br />

                              <span><a href="mailto:cj21ecrer04@student.nitw.ac.in"> cj21ecrer04@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd5.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Distributed Algorithms</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Neetu Narayan rao Chikyal</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720055</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Vasundhara & Dr.Chayan Bhar</b> </span>
                            <hr />

                            <p className="contact-info">  8184923187 <br />

                              <span><a href="mailto:cn720055@student.nitw.ac.in"> cn720055@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd6.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Inaganti Naurin Bahadur </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECFJ107</b> </span><br></br>
                            <span>Supervisor: <b>Prof. B. Lakshmi</b> </span>
                            <hr />

                            <p className="contact-info">  9390959100 <br />

                              <span><a href="mailto:in712107@student.nitw.ac.in"> in712107@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd7.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Computer Vision and Image
    Processing</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Priyadarsini Samal</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER10</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Mohammad Farukh Hashmi</b> </span>
                            <hr />

                            <p className="contact-info">  8851617028 <br />

                              <span><a href="mailto:ps21ecrer10@student.nitw.ac.in"> ps21ecrer10@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd8.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Sunitha Mandava</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECPJ091</b> </span><br></br>
                            <span>Supervisor: <b>Dr Gopi Ram</b> </span>
                            <hr />

                            <p className="contact-info">  9573297310 <br />

                              <span><a href="mailto:sm712091@student.nitw.ac.in"> sm712091@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img"> */}
                        {/* <img src="ECEDeptAssets/images/phd9.jpg" className="img-fluid image" alt=""/> */}
                        {/* <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" /> */}
                        {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Software Defined Networks </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                        {/* </div>
                            <h6 className="std-title">Lavudya Srinivas</h6>
                            <span>Self finance</span><br></br>
                            <span>Roll No: <b>21ECRES04</b> </span><br></br>
                            <span>Supervisor: <b>Dr. G. Arun Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  9963990548 <br />

                              <span><a href="mailto:ls21ecres04@student.nitw.ac.in"> ls21ecres04@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd10.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Mobile computing and security </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Boddu Vijayasree </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR1R04</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Prakash Kodali</b> </span>
                            <hr />

                            <p className="contact-info">  8179487004 <br />

                              <span><a href="mailto:bv22ecr1r04@student.nitw.ac.in"> bv22ecr1r04@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd11.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Privacy Preserving Data Publishing </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Pattimi Hari </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>701939</b> </span><br></br>
                            <span>Supervisor: <b>Dr.B.K.N. Srinivasa Rao</b> </span>
                            <hr />

                            <p className="contact-info">  7801077228 <br />

                              <span><a href="mailto:harinitw@student.nitw.ac.in"> harinitw@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd12.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Maddi Fathima </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R11</b> </span><br></br>
                            <span>Supervisor: <b>Dr. G. Arun Kumar &
                              Prof.Patri Sreehari rao
                            </b> </span>
                            <hr />

                            <p className="contact-info">  9908367273, 8185832749 <br />

                              <span><a href="mailto:mf22ecr2r11@student.nitw.ac.in"> mf22ecr2r11@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd13.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Service Oriented Architecture </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Jali Nandini  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>701937</b> </span><br></br>
                            <span>Supervisor: <b>Dr. P. Muralidhar &
                              Prof. Patri Sreehari Rao
                            </b> </span>
                            <hr />

                            <p className="contact-info">  9494804726 <br />

                              <span><a href="mailto:jnandini@student.nitw.ac.in"> jnandini@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd14.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Cryptography  </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Renikunta Mallaiah  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>719069</b> </span><br></br>
                            <span>Supervisor: <b>Prof. V V Mani</b> </span>
                            <hr />

                            <p className="contact-info">  8501856443 <br />

                              <span><a href="mailto:mallaiah719069@student.nitw.ac.in"> mallaiah719069@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd15.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data Analytics  </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Gandhi B</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R02</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Chayan Bhar</b> </span>
                            <hr />

                            <p className="contact-info">  7702139514 <br />

                              <span><a href="mailto:gb22ecr2r02@student.nitw.ac.in"> gb22ecr2r02@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd16.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b></b>
                  </p>
              
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Sunitha Bhukya</h6>
                            <span>Full-Time (SF) Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR1S02</b> </span><br></br>
                            <span>Supervisor: <b>Prof. N. Bheema Rao</b> </span>
                            <hr />

                            <p className="contact-info">  8897759529 <br />

                              <span><a href="mailto:bs22ecr1s02@student.nitw.ac.in"> bs22ecr1s02@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd17.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b></b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Aruna V </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721070</b> </span><br></br>
                            <span>Supervisor: <b>Prof L Anjaneyulu</b> </span>
                            <hr />

                            <p className="contact-info">  9701797479 <br />

                              <span><a href="mailto:av721070@student.nitw.ac.in"> av721070@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd18.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Graph Algorithms   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Y.Rama muni Reddy</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER09</b> </span><br></br>
                            <span>Supervisor: <b>Dr. P. Muralidhar</b> </span>
                            <hr />

                            <p className="contact-info">  9849440181 <br />

                              <span><a href="mailto:yr21ecrer09@student.nitw.ac.in"> yr21ecrer09@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title">Ch.Manasa</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R01</b> </span><br></br>
                            <span>Supervisor: <b>Prof. D. Vakula</b> </span>
                            <hr />

                            <p className="contact-info">  6309251764 <br />

                              <span><a href="mailto:cm22ecr2r01@student.nitw.ac.in"> cm22ecr2r01@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd20.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Data Mining and Machine Learning  </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Sresta Valasa</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R09</b> </span><br></br>
                            <span>Supervisor: <b>Dr. V. Narendar</b> </span>
                            <hr />

                            <p className="contact-info">  9398320650 <br />

                              <span><a href="mailto:vs22ecr2r09@student.nitw.ac.in"> vs22ecr2r09@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd21.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Application Security in Computer Networks  </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Kotha Venkata Ramakrishna</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR1R08</b> </span><br></br>
                            <span>Supervisor: <b>Dr. V. Narendar</b> </span>
                            <hr />

                            <p className="contact-info">  8179483218 <br />

                              <span><a href="mailto:kv22ecr1r08@student.nitw.ac.in"> kv22ecr1r08@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd22.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Software Engineering and Machine Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Podeti Raveendra</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>719068</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Patri Sreehari Rao & Dr P. Muralidhar</b> </span>
                            <hr />

                            <p className="contact-info">  9396640764 <br />

                              <span><a href="mailto:raveendra466@student.nitw.ac.in"> raveendra466@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd23.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Bioinformatics, Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Vanga Mahesh</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER03</b> </span><br></br>
                            <span>Supervisor: <b>Dr. B.K.N Srinivasarao & Dr. E Suresh Babu</b> </span>
                            <hr />

                            <p className="contact-info">  9542801122 <br />

                              <span><a href="mailto:vm21ecrer03@student.nitw.ac.in"> vm21ecrer03@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                             
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title">Abhay Narasimha K S</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720051</b> </span><br></br>
                            <span>Supervisor: <b>Prof. V Venkata Mani</b> </span>
                            <hr />

                            <p className="contact-info">  9900206089 <br />

                              <span><a href="mailto:ak720051@student.nitw.ac.in"> ak720051@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                            
                            </div>
                            <h6 className="std-title">Chakala Sandhya</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR1R12</b> </span><br></br>
                            <span>Supervisor: <b>Prof. V Venkata Mani</b> </span>
                            <hr />

                            <p className="contact-info">  9390011416 <br />

                              <span><a href="mailto:cs22ecr1r12@student.nitw.ac.in"> cs22ecr1r12@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd26.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Location Based Services for Network Applications   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Kota Nageswara Rao</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721068</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Kalpana Naidu</b> </span>
                            <hr />

                            <p className="contact-info">  9951967479 <br />

                              <span><a href="mailto:kn721068@student.nitw.ac.in">kn721068@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd27.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Security & Privacy   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Chilupuri Anusha</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721059</b> </span><br></br>
                            <span>Supervisor: <b>Prof. S.Anuradha</b> </span>
                            <hr />

                            <p className="contact-info">  9177442314 <br />

                              <span><a href="mailto:ca721059@student.nitw.ac.in"> ca721059@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd28.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Data Mining   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Ravi Sankar Puppala</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>719067</b> </span><br></br>
                            <span>Supervisor: <b>Prof.Prakash Kodali</b> </span>
                            <hr />

                            <p className="contact-info">  9640586834 <br />

                              <span><a href="mailto:ravi482@student.nitw.ac.in"> ravi482@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title">Adi Surendra Mohanraju M</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER08</b> </span><br></br>
                            <span>Supervisor: <b>Prof. L.Anjaneyulu</b> </span>
                            <hr />

                            <p className="contact-info">  9032825483 <br />

                              <span><a href="mailto:as21ecrer08@student.nitw.ac.in"> as21ecrer08@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd30.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Biometrics   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Santosh Kumar padhi</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>701940</b> </span><br></br>
                            <span>Supervisor: <b>Dr.V.Narendar</b> </span>
                            <hr />

                            <p className="contact-info">  7978275226 <br />

                              <span><a href="mailto:santhosh_nitw@student.nitw.ac.in"> santhosh_nitw@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd31.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Information Security   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Gundala Jhansi Rani</h6>
                            <span>Full-Time (Self-Finance) Research Scholar </span><br></br>
                            <span>Roll No: <b>719072</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Mohammad Farukh Hashmi</b> </span>
                            <hr />

                            <p className="contact-info">  9963092636 <br />

                              <span><a href="mailto:gj22ecr1s01@student.nitw.ac.in"> gj22ecr1s01@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd32.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Image Processing   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Sudharani Chidurala</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR1R07</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Amara Prakasa Rao</b> </span>
                            <hr />

                            <p className="contact-info">  9908246470 <br />

                              <span><a href="mailto:sc22ecr1r07@student.nitw.ac.in"> sc22ecr1r07@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        {/* 
                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title">Shilpa Narlagiri</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECREP09</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Amara Prakasa Rao</b> </span>
                            <hr />

                            <p className="contact-info">  7893903292 <br />

                              <span><a href="mailto:sn21ecrep09@student.nitw.ac.in"> sn21ecrep09@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd34.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Data Mining   </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Bochu Lakshakoti</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2E08</b> </span><br></br>
                            <span>Supervisor: <b>Prof.Prakash Kodali</b> </span>
                            <hr />

                            <p className="contact-info">  8499030475 <br />

                              <span><a href="mailto:bl22ecr2r08@student.nitw.ac.in"> bl22ecr2r08@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd35.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Privacy Preserving of Data in Cloud Computing    </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Rudravaram Srikanth</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>719072</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Maheshwaram Satish</b> </span>
                            <hr />

                            <p className="contact-info">  9618052919 <br />

                              <span><a href="mailto:rs712094@student.nitw.ac.in"> rs712094@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd36.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Bioinformatics</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Lakshmi Sowjanya Koneru</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1P03</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Prakash Kodali</b> </span>
                            <hr />

                            <p className="contact-info">  8885516969 <br />

                              <span><a href="mailto:ls23ecr1p03@student.nitw.ac.in"> ls23ecr1p03@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd37.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Bandi Narasimha Rao</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER15</b> </span><br></br>
                            <span>Supervisor: <b>Prof. S. Anuradha</b> </span>
                            <hr />

                            <p className="contact-info">  7285929264 <br />

                              <span><a href="mailto:bn21ecrer15@student.nitw.ac.in"> bn21ecrer15@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd38.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Data Mining with Big Data</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Banoth Yakub</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER13</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Amarjit Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  9640653210 <br />

                              <span><a href="mailto:by21ecrer13@student.nitw.ac.in"> by21ecrer13@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd39.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine learning, Data science, big data, spiking neural network </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Pudari Chiranjeevi</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECREP07</b> </span><br></br>
                            <span>Supervisor: <b>Dr. N. Bheemarao</b> </span>
                            <hr />

                            <p className="contact-info">  9908796040 <br />

                              <span><a href="mailto:pc21ecrep07@student.nitw.ac.in"> pc21ecrep07@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd40.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Image Processing</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Shwetha H M</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720056</b> </span><br></br>
                            <span>Supervisor: <b>Prof. S. Anuradha</b> </span>
                            <hr />

                            <p className="contact-info">  9449678620 <br />

                              <span><a href="mailto:sh720056@student.nitw.ac.in"> sh720056@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd41.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Ayesha Tabassum</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R07</b> </span><br></br>
                            <span>Supervisor: <b>Prof. L.Aanjaneyulu</b> </span>
                            <hr />

                            <p className="contact-info">  8096161522 <br />

                              <span><a href="mailto:at22ecr2r07@student.nitw.ac.in"> at22ecr2r07@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd42.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Internet of Things and Block chain</b>
                  </p>

                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Elagandula Aparna</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721058</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Gopi Ram</b> </span>
                            <hr />

                            <p className="contact-info">  9581672672 <br />

                              <span><a href="mailto:ea721058@student.nitw.ac.in"> ea721058@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd43.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Medical imaging</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Praveen Kumar Mudidhe</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720054</b> </span><br></br>
                            <span>Supervisor: <b>Prof. N. Bheema Rao</b> </span>
                            <hr />

                            <p className="contact-info">  9030102906 <br />

                              <span><a href="mailto:pk720054@student.nitw.ac.in"> pk720054@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd44.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Biometrics</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">K Murali Chandra Babu</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721056</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Ekta Goel</b> </span>
                            <hr />

                            <p className="contact-info">  9553045566 <br />

                              <span><a href="mailto:praveenm@student.nitw.ac.in"> mk721056@studnet.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd45.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Computer Vision and Machine Learning </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Thatikonda Ragini</h6>
                            <span>Full-Time (Self-Finance)  Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECSJ105</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Prakash
                              Kodali
                            </b> </span>
                            <hr />

                            <p className="contact-info">  9133039995 <br />

                              <span><a href="mailto:tr712105@student.nitw.ac.in"> tr712105@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title">Snehal Tonpe </h6>
                            <span>External Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721067</b> </span><br></br>
                            <span>Supervisor: <b>Dr Chayan Bhar</b> </span>
                            <hr />

                            <p className="contact-info">  8378056987 <br />

                              <span><a href="mailto:sv721067@student.nitw.ac.in"> sv721067@student.nitw.ac.in </a> </span></p>
                          </div>
                        </div> */}


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                             
                            </div>
                            <h6 className="std-title">Rajkumar Maharaju</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>712110</b> </span><br></br>
                            <span>Supervisor: <b>Dr. V Rama</b> </span>
                            <hr />

                            <p className="contact-info">  9676740151 <br />

                              <span><a href="mailto:rm712110@student.nitw.ac.in"> rm712110@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd48.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Data Mining,Big Data Analytics </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Manchala Naveen</h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER12</b> </span><br></br>
                            <span>Supervisor: <b>Prof. N. Bheema Rao</b> </span>
                            <hr />

                            <p className="contact-info">  9603264699 <br />

                              <span><a href="mailto:mn21ecrer12@student.nitw.ac.in"> mn21ecrer12@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd49.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Image Processing</b>
                  </p>
 
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Kadiyala Ragodaya Deepthi</h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720059</b> </span><br></br>
                            <span>Supervisor: <b>Prof. L. Anjaneyulu,
                              Dr. Chayan Bhar
                            </b> </span>
                            <hr />

                            <p className="contact-info">  9493550926 <br />

                              <span><a href="mailto:kr720059@student.nitw.ac.in"> kr720059@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd50.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>wireless networks</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> KannaRao Shatarasi </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRES02</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Gopi Ram</b> </span>
                            <hr />

                            <p className="contact-info">  9948125929 <br />

                              <span><a href="mailto:ks21ecres02@student.nitw.ac.in"> ks21ecres02@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd51.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine learning</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Padmaja.E  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR1R03</b> </span><br></br>
                            <span>Supervisor: <b>Dr.V.Rama</b> </span>
                            <hr />

                            <p className="contact-info">  9160544916<br />

                              <span><a href="mailto:pe22ecr1r03@student.nitw.ac.in"> pe22ecr1r03@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd52.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Software Testing and Program Analysis </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Ravi Kothapally </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>701934</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Satish Maheshwaram,
                              Dr. V Narendar
                            </b> </span>
                            <hr />

                            <p className="contact-info">  9493072755 <br />

                              <span><a href="mailto:ravikothapally@student.nitw.ac.in"> ravikothapally@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd53.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data, Internet of Things  </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sreevani Menda  </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECREP11</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Ekta Goel</b> </span>
                            <hr />

                            <p className="contact-info">  9652568789 <br />

                              <span><a href="mailto:sm21ecrep11@student.nitw.ac.in"> sm21ecrep11@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd54.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> C. Kaushik </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECPJ100</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Chayan Bhar,
                              Prof. L. Anjaneyulu
                            </b> </span>
                            <hr />

                            <p className="contact-info">  9550135353 <br />

                              <span><a href="mailto:kc712100@student.nitw.ac.in"> kc712100@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd55.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Bioinformatics </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Keshampeta Ashwini   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECPJ095</b> </span><br></br>
                            <span>Supervisor: <b>Prof. J Ravi Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  7702424712 <br />

                              <span><a href="mailto:ak712095@student.nitw.ac.in"> ak712095@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd56.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Soft computing </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sabitha.N  </h6>
                            <span>Self Finance</span><br></br>
                            <span>Roll No: <b>21ECSJ086</b> </span><br></br>
                            <span>Supervisor: <b>Prof V.V.Mani</b> </span>
                            <hr />

                            <p className="contact-info">  8332969436 <br />

                              <span><a href="mailto:sn712086@student.nitw.ac.in"> sn712086@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd57.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data, High Performance Computing, Machine Learning, Deep Learning  </b>
                  </p>

                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Nageswara Rao Thadikamalla  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720052</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Prakasa Rao Amara</b> </span>
                            <hr />

                            <p className="contact-info">  9494446508 <br />

                              <span><a href="mailto:nr720052@student.nitw.ac.in"> nr720052@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd58.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>IoT </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> B B Shabarinath </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>701943</b> </span><br></br>
                            <span>Supervisor: <b>Dr. P. Muralidhar</b> </span>
                            <hr />

                            <p className="contact-info">  8074657913 <br />

                              <span><a href="mailto:bbshabarinath@student.nitw.ac.in"> bbshabarinath@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title"> Doosa Pushpalatha  </h6>
                            <span>Self-Finance </span><br></br>
                            <span>Roll No: <b>21ECRES03</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Prithvi.P</b> </span>
                            <hr />

                            <p className="contact-info">  9949454626 <br />

                              <span><a href="mailto:dp21ecres03@student.nitw.ac.in"> dp21ecres03@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd60.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Data mining, Data Analytics, Machine learning, IOT </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Dharavath Parvathi  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R06</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Prithvi.P</b> </span>
                            <hr />

                            <p className="contact-info">  9014561713 <br />

                              <span><a href="mailto:dp22ecr2r06@student.nitw.ac.in"> dp22ecr2r06@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd61.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Cryptography, distributed computing, IOT </b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Rayala Ravi Kumar  </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECR1P016</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Chayan Bhar</b> </span>
                            <hr />

                            <p className="contact-info">

                              <span><a href="mailto:rr21ecr1p01@student.nitw.ac.in"> rr21ecr1p01@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd62.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Cyber Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Gopireddy Palguna Kumar Reddy  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721061</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Arun Kumar Gande</b> </span>
                            <hr />

                            <p className="contact-info">  9535847261 <br />

                              <span><a href="mailto:pg721061@student.nitw.ac.in"> pg721061@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd63.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Deepthi Konikineni  </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721071</b> </span><br></br>
                            <span>Supervisor: <b>Prof.L.Anjaneyulu, Dr.Y.Padmasai</b> </span>
                            <hr />

                            <p className="contact-info">  9849949965 <br />

                              <span><a href="mailto:dk721071@student.nitw.ac.in"> dk721071@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd64.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Soft Computing</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title">Bujugundla Ravinder sahana   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>EC22R2R03</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Himansu Shekar Pradhan</b> </span>
                            <hr />

                            <p className="contact-info">  7660948455 <br />

                              <span><a href="mailto:br22ecr2r03@student.nitw.ac.in"> br22ecr2r03@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title"> Syeda Firasath Fatima   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECFJ106</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Atul Kumar Nishad</b> </span>
                            <hr />

                            <p className="contact-info">  9515912947<br />

                              <span><a href="mailto:sf712106@student.nitw.ac.in"> sf712106@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd66.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Soft Computing</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Rajapantula Kranthi  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721066</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Vasundhara</b> </span>
                            <hr />

                            <p className="contact-info">  9676771146 <br />

                              <span><a href="mailto:rk721066@student.nitw.ac.in"> rk721066@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd67.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Vanitha Devi R   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ecrer11</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Vasundhara</b> </span>
                            <hr />

                            <p className="contact-info">

                              <span><a href="mailto:vd21ecrer11@student.nitw.ac.in"> vd21ecrer11@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd68.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Ramesh A   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>720057</b> </span><br></br>
                            <span>Supervisor: <b>Prof. D. Vakula</b> </span>
                            <hr />

                            <p className="contact-info">
                              7995921223 <br />

                              <span><a href="mailto:ar720057@student.nitw.ac.in"> ar720057@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd69.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sunkaraboina Sreenu  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>701941</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Kalpana Naidu</b> </span>
                            <hr />

                            <p className="contact-info">
                              9640648110 <br />

                              <span><a href="mailto:sreenus@student.nitw.ac.in"> sreenus@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd70.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Junuthula Ashish   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>718040</b> </span><br></br>
                            <span>Supervisor: <b>Dr. A. Prakasa rao</b> </span>
                            <hr />

                            <p className="contact-info">
                              9573777078 <br />

                              <span><a href="mailto:ajunuthula@student.nitw.ac.in"> ajunuthula@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd71.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Madduluri Suneetha   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECFJ087</b> </span><br></br>
                            <span>Supervisor: <b>Prof. T. Kishore Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  8142003997 <br />

                              <span><a href="mailto:ms712087@student.nitw.ac.in"> ms712087@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd72.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Manoj Kumar M   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R14</b> </span><br></br>
                            <span>Supervisor: <b>Prof. T.Kishore Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  9177407099 <br />

                              <span><a href="mailto:mk23ecr1r14@student.nitw.ac.in"> mk23ecr1r14@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd73.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> P.Usha Rani   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECPJ104</b> </span><br></br>
                            <span>Supervisor: <b>Dr.B K N Srinivasa Rao</b> </span>
                            <hr />

                            <p className="contact-info">  9440100150 <br />

                              <span><a href="mailto:pr712104@student.nitw.ac.in"> pr712104@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd74.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> S.Swetha   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECPJ101</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Amarjit Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  8374077544<br />

                              <span><a href="mailto:ss712101@student.nitw.ac.in"> ss712101@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd75.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Vikas Kumar Tiwari   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R02</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Sridhar K V</b> </span>
                            <hr />

                            <p className="contact-info">  8885137802 <br />

                              <span><a href="mailto:vk23ecr1r02@student.nitw.ac.in"> vk23ecr1r02@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd76.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> K R Anudeep Laxmikanth   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R11</b> </span><br></br>
                            <span>Supervisor: <b>Prof. T.Kishore Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  9959375380 <br />

                              <span><a href="mailto:kr23ecr1r11@student.nitw.ac.in"> kr23ecr1r11@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd77.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> G.Srilatha   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECPJ102</b> </span><br></br>
                            <span>Supervisor: <b>Prof. P Sree Hari rao
                              Prof. S.Anuradha
                            </b> </span>
                            <hr />

                            <p className="contact-info">  7901556182 <br />

                              <span><a href="mailto:sg712102@student.nitw.ac.in"> sg712102@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd78.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> P. Madhava Rao  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R03</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Maheshwaram Satish</b> </span>
                            <hr />

                            <p className="contact-info">  9493018432 <br />

                              <span><a href="mailto:pm23ecr1r03@student.nitw.ac.in"> pm23ecr1r03@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Bhukya_Harishma_2024-7-1-16-19-29.jpg" className="img-fluid image" alt="" />
                            </div>
                            <h6 className="std-title"> Bhukya Harishma  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R06</b> </span><br></br>
                            <span>Supervisor: <b>Prof. K L V Sai Prakash Sakuru</b> </span> <hr />
                            <p className="contact-info"> 6305073365<br />
                              <span><a href="mailto:bh23ecr1r06@student.nitw.ac.in"> bh23ecr1r06@student.nitw.ac.inn</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd79.jpg" className="img-fluid image" alt="" />
                            </div>
                            <h6 className="std-title"> Bayya Radha Kumari  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R07</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Shuvam Pawar</b> </span>
                            <hr />
                            <p className="contact-info">  9705889533 <br />
                              <span><a href="mailto:vd21ecrer11@student.nitw.ac.in"> br23ecr1r07@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd80.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Katukoori Thriveni  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R09</b> </span><br></br>
                            <span>Supervisor: <b>CH.V.Rama Rao</b> </span>
                            <hr />

                            <p className="contact-info">  9550874774 <br />

                              <span><a href="mailto:tk23ecr1r09@student.nitw.ac.in"> tk23ecr1r09@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd81.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sivashakthi Singh Bondili   </h6>
                            <span>Part -Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ecrer11</b> </span><br></br>
                            <span>Supervisor: <b>Prof. B. Lakshmi</b> </span>
                            <hr />

                            <p className="contact-info">  7032063670 <br />

                              <span><a href="mailto:sivashakthi.nitw@student.nitw.ac.in"> sivashakthi.nitw@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd82.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Bandari Raghupathi  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R10</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Mohammad Farukh Hashmi</b> </span>
                            <hr />

                            <p className="contact-info">  9110756001 <br />

                              <span><a href="mailto:br23ecr1r10@student.nitw.ac.in"> br23ecr1r10@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd83.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Laxmi Kantham Durgam   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECFJ103</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Ravi kumar Jatoth</b> </span>
                            <hr />

                            <p className="contact-info">  9502522956 <br />

                              <span><a href="mailto:ld712103@student.nitw.ac.in"> ld712103@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd84.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sri Vidhya Komarina  </h6>
                            <span>Self Finance </span><br></br>
                            <span>Roll No: <b>21ECSJ093</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Ravi kumar Jatoth</b> </span>
                            <hr />

                            <p className="contact-info">  9441107899 <br />

                              <span><a href="mailto:sk712093@student.nitw.ac.in"> sk712093@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd85.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Mood Umadevi  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R12</b> </span><br></br>
                            <span>Supervisor: <b>Prof.B.Lakshmi</b> </span>
                            <hr />

                            <p className="contact-info">  8143221126 <br />

                              <span><a href="mailto:mu23ecr1r12@student.nitw.ac.in"> mu23ecr1r12@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd86.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sanjay Madupu   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R08</b> </span><br></br>
                            <span>Supervisor: <b>Prof  Ravi kumar jatoth</b> </span>
                            <hr />

                            <p className="contact-info">  9989378210 <br />

                              <span><a href="mailto:sm23ecr1r08@student.nitw.ac.in"> sm23ecr1r08@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd87.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Neelam Mounika    </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R10</b> </span><br></br>
                            <span>Supervisor: <b>Prof. S.Anuradha</b> </span>
                            <hr />

                            <p className="contact-info">  7207540562 <br />

                              <span><a href="mailto:nm22ecr2r10@student.ac.in"> nm22ecr2r10@student.ac.in</a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd88.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Devulapalli Ranjith   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R05</b> </span><br></br>
                            <span>Supervisor: <b>Dr.K.Sarangam</b> </span>
                            <hr />

                            <p className="contact-info">  9052060906 <br />

                              <span><a href="mailto:dr23ecr1r05@student.nitw.acin"> dr23ecr1r05@student.nitw.acin</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd89.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Naresh Bopparathi   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECREP12</b> </span><br></br>
                            <span>Supervisor: <b>Dr. V Narendar</b> </span>
                            <hr />

                            <p className="contact-info">  9989733732 <br />

                              <span><a href="mailto:nb21ecrep12@student.nitw.ac.in"> nb21ecrep12@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd90.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> G Rajashekhar   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721057</b> </span><br></br>
                            <span>Supervisor: <b>Dr. P.Muralidhar</b> </span>
                            <hr />

                            <p className="contact-info">  9849871143 <br />

                              <span><a href="mailto:gr721057@student.nitw.ac.in"> gr721057@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd91.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Ambati Abhinavya   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRES01</b> </span><br></br>
                            <span>Supervisor: <b>Dr.V.Rama</b> </span>
                            <hr />

                            <p className="contact-info">  7702579385 <br />

                              <span><a href="mailto:aa21ecres01@student.nitw.ac.in"> aa21ecres01@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd92.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Kruttiventi A Manjusha  </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR1P06</b> </span><br></br>
                            <span>Supervisor: <b>Dr Amara Praksa Rao</b> </span>
                            <hr />

                            <p className="contact-info">  9030917557 <br />

                              <span><a href="mailto:ka22ecr1p06@student.nitw.ac.in"> ka22ecr1p06@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd93.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Gopathoti Kirankumar   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECREP08</b> </span><br></br>
                            <span>Supervisor: <b>Prof. T.Kishore Kumar</b> </span>
                            <hr />

                            <p className="contact-info">  6305174356<br />

                              <span><a href="mailto:gk21ecrep08@student.nitw.ac.in"> gk21ecrep08@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd94.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Kaira Akshara   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>23ECR1R01</b> </span><br></br>
                            <span>Supervisor: <b>Dr. Gopi Ram</b> </span>
                            <hr />

                            <p className="contact-info">  8184899927 <br />

                              <span><a href="mailto:Ka23ecr1r01@student.nitw.ac.in"> Ka23ecr1r01@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd95.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Mohammad Sajudunisa Begum
                            </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2R04</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Kalpana Naidu</b> </span>
                            <hr />

                            <p className="contact-info">  9392831981 <br />

                              <span><a href="mailto:ms22ecr2r04@student.nitw.ac.in"> ms22ecr2r04@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              
                              <img src="ECEDeptAssets/images/no-img.jpg" className="img-fluid image" alt="" />
                              
                            </div>
                            <h6 className="std-title"> R Pavan Kumar   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>22ECR2P04</b> </span><br></br>
                            <span>Supervisor: <b>Dr. P Murlidhar</b> </span>
                            <hr />

                            <p className="contact-info">  9490956272 <br />

                              <span><a href="mailto:rp22ecr2p04@student.nitw.ac.in"> rp22ecr2p04@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div> */}



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd97.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Terkar Anil Narsinhrao   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>717032</b> </span><br></br>
                            <span>Supervisor: <b>Dr. P Muralidhar</b> </span>
                            <hr />

                            <p className="contact-info">  9110508461 <br />

                              <span><a href="mailto:anilterkar22@gmail.com"> anilterkar22@gmail.com</a> </span></p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd98.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Swaroop Kumar Macherla  </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECRER14</b> </span><br></br>
                            <span>Supervisor: <b>Dr.Ekta Goel</b> </span>
                            <hr />

                            <p className="contact-info">  9030692731 <br />

                              <span><a href="mailto:sk21ecrer14@student.nitw.ac.in"> sk21ecrer14@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd99.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Mangamuri Ramanjaneyulu   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>721062</b> </span><br></br>
                            <span>Supervisor: <b>Prof. V V Mani</b> </span>
                            <hr />

                            <p className="contact-info">  9985378253 <br />

                              <span><a href="mailto:mr721062@student.nitw.ac.in"> mr721062@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd100.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Maloth Vijaya   </h6>
                            <span>Full-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECFJ098</b> </span><br></br>
                            <span>Supervisor: <b>Prof. Ravi Kumar Jatoth</b> </span>
                            <hr />

                            <p className="contact-info">  8309296029 <br />

                              <span><a href="mailto:mv712098@student.nitw.ac.in"> mv712098@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="ECEDeptAssets/images/phd101.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> V S N Kumar Devaraju   </h6>
                            <span>Part-Time Research Scholar </span><br></br>
                            <span>Roll No: <b>21ECREP02</b> </span><br></br>
                            <span>Supervisor: <b>Prof. B. Lakshmi</b> </span>
                            <hr />

                            <p className="contact-info">  9849953764 <br />
                              <span><a href="mailto:vs21ecrep02@student.nitw.ac.in">vs21ecrep02@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>



















































                      </div>


                      {/* 
<div className="row">
<p className="more"> <a className="more-info-link" href="ECEDeptAssets/docs/8_placement-list.pdf" target="_blank"> Placements <i className="fa fa-angle-right"></i> </a> </p>
<p className="more"> <a className="more-info-link" href="ECEDeptAssets/docs/7_students-list.pdf" target="_blank"> Student List <i className="fa fa-angle-right"></i> </a> </p>
</div> */}


                    </div>
                  </div>
                </div>
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">




                  <div className="row std-society">
                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16298"
                        target="_blank"> <img src="ECEDeptAssets/images/f21.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16298"
                          target="_blank">
                          <h6>Prof. Prithvi Pothupogu</h6> <span> Faculty Advisor </span>
                          <hr />
                        </a>
                          <p> <i className="fa fa-phone" aria-hidden="true"></i>9849317318, 8332969360 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:prithvi@nitw.ac.in"
                              style={{ color: "#808080" }}>prithvi@nitw.ac.in</a> </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://erp.nitw.ac.in/ext/profile/ec-pawarshuvam"
                        target="_blank"> <img src="ECEDeptAssets/images/f38.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://erp.nitw.ac.in/ext/profile/ec-pawarshuvam"
                          target="_blank">
                          <h6>Prof. Shuvam Pawar</h6> <span> Treasurer </span>
                          <hr />
                        </a>
                          <p> <i className="fa fa-phone" aria-hidden="true"></i> 94576 42108 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i><a
                              href="mailto:pawarshuvam@nitw.ac.in"
                              style={{ color: "#808080" }}>pawarshuvam@nitw.ac.in</a> </p>
                        </div>
                      </div>
                    </div>
                  </div>






                </div>
                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">


                  <div className="row notable-alumni">


                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="ECEDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Prof. Bhaskar Choubey</h6>

                          <p>
                            <b>Present Position :</b> Chair, Analogue Circuits and Image Sensors, Siegen University
                          </p>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="ECEDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Usha Gogineni</h6>

                          <p>
                            <b>Present Position :</b> Principal Mts, Modeling at Analog Devices Massachusetts, United States
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na3.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Venkat Pullela</h6>
                          <p><b>Present Position :</b> Distinguished Engineer / Evangelist; Broadcom, San Jose, CA</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na4.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sreenivasrao Baru</h6>
                          <p>
                            <b>Present Position :</b> Operations, Industrialization and Transformation Leader
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na5.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shri. P. Venkata Ramana</h6>
                          <p><b>Present Position :</b> Additional Director General Indian Railways Institute of Signal Engineering & Telecommunications, Hyderabad</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na6.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>G Muralidhar</h6>

                          <p><b>Present Position :</b> Chief Mentor, Akshara Livelihoods; Co-lead and Mentos, AP Community-Managed Natural Farming</p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na7.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>BHVS Nararyana Murthy</h6>
                          <p>
                            <b>Present Position :</b> Former DG of DRDO's missiles and strategic systems
                          </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na8.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Rakesh Dubbudu</h6>
                          <p>
                            <b>Present Position :</b> Founder of Factly & Dataful | Public Policy & Public Data | TEDx Speaker | Transparency Campaigner
                          </p>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na9.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>C.J. Reddy</h6>
                          <p>
                            <b>Present Position :</b> Vice President Business Development, Electromagnetics - Americas, IEEE Fellow, Fellow ACES, AMTA Ed Gillespie Fellow, Fellow IETE
                            Hampton, Virginia, United States

                          </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na10.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Mallik Tatipamula</h6>
                          <p><b>Present Position :</b> Chief Technology Officer
                            San Jose, California, United States
                          </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na11.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Prof (Dr.) SANDEEP SANCHETI</h6>
                          <p><b>Present Position :</b> Provost (Vice Chancellor) at Marwadi University I Co-Founder, Edorer.com</p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="ECEDeptAssets/images/na12.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sudhakar Rao</h6>
                          <p><b>Present Position :</b> RaoS Consultants Rancho Palos Verdes, California, United States </p>
                        </div>
                      </div>
                    </div>


                  </div>


                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                  aria-labelledby="v-pills-AcademicResearchGroup-tab">
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Instrumentation and Embedded Systems Group
                        </h4>
                      </header>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f3.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. T. Kishore Kumar</h6>
                            <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969353 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kishoret@nitw.ac.in">kishoret@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f18.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Ravi Kumar Jatoth</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8702462444 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ravikumar@nitw.ac.in">ravikumar@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f16.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Muralidhar Pullakandam</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8702462442 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:pmurali@nitw.ac.in">pmurali@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f12.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Sridhar K V</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9849548428<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sridhar@nitw.ac.in">sridhar@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f20.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Ch. V. Rama Rao</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9402187886<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:chvramarao@nitw.ac.in">chvramarao@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f21.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Prithvi Pothupogu</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969360 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:prithvi@nitw.ac.in">prithvi@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f22.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. V. Rama</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440762744 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vr.nitw@gmail.com">vr.nitw@gmail.com</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f30.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Dr. B K N Srinivasarao</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9800296596 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:srinu.bkn@nitw.ac.in">srinu.bkn@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f31.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Mohammad Farukh Hashmi</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9666740604 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:mdfarukh@nitw.ac.in">mdfarukh@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f34.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Vasundhara</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9778162166 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vasundhara@nitw.ac.in">vasundhara@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f33.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Prakash Kodali</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8074035659 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:prakashk@nitw.ac.in">prakashk@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>











                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>VLSI Group</h4>
                      </header>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f5.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. N. Bheema Rao</h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9492909194 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:nbr@nitw.ac.in">nbr@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f11.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Lakshmi B</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9493436845<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:lakshmi@nitw.ac.in">lakshmi@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    {/* <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f13.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Hanumanth Rao T V K</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9494280298 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:tvkhrao75@nitw.ac.in">tvkhrao75@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f14.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Sreehari Rao Patri</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9441342324<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:patri@nitw.ac.in">patri@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f23.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Sarangam K</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8702462445 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sarangam_7@nitw.ac.in">sarangam_7@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f26.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Atul Kumar Nishad</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8106500502 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:atul@nitw.ac.in">atul@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f27.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Vadthiya Narendar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9795235922 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:narendarv@nitw.ac.in">narendarv@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f32.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Maheshwaram Satish</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9760018986 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:satishm@nitw.ac.in">satishm@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f36.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Ekta Goel</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8052696820 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ektagoel@nitw.ac.in">ektagoel@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f38.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Shuvam Pawar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9457642108 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:pawarshuvam@nitw.ac.in">pawarshuvam@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>








                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Communication and Microwaves Group</h4>
                      </header>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f2.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Narasimha Sarma N V S</h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8702462412<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sarma@nitw.ac.in">sarma@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f4.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Anjaneyulu L</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969355 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kramesh@nitw.ac.in">anjan@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f1.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Vakula D</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969362 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vakulad@nitw.ac.in">vakulad@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>





                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f17.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Venkata Mani V</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9705024365 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vvmani@nitw.ac.in">vvmani@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f19.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. S. Anuradha</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9490334276 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:anuradha@nitw.ac.in">anuradha@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f7.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Ravi Kishore Kodali</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8985230364 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kisore@nitw.ac.in">kisore@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f6.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Raghunadh M V</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969352 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:raghu@nitw.ac.in">raghu@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f8.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. K L V Sai Prakash Sakuru</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9246551868 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sai@nitw.ac.in">sai@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f9.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Hari Krishna Prasad P</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8702430012 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:phkp@nitw.ac.in">phkp@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f15.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Prakasa Rao A</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969358 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:aprao@nitw.ac.in">aprao@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f24.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Kalpana Naidu</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9393941144 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kalpana@nitw.ac.in">kalpana@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f25.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Arun Kumar Gande</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8702462429 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:g.arun@nitw.ac.in">g.arun@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>





                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f29.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Gopi Ram</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9679983382 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:gopi.ram@nitw.ac.in">gopi.ram@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f37.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Chayan Bhar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7908826629 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:cbhar@nitw.ac.in">cbhar@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f35.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Amarjit Kumar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7906021481 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:amarjitk@nitw.ac.in">amarjitk@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="ECEDeptAssets/images/f28.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Himansu Shekhar Pradhan</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9437903184 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:hsp@nitw.ac.in">hsp@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>










                  </div>


                  {/* 
<p className="more"> <a className="more-info-link" href="ECEDeptAssets/docs/areas-of-interest.pdf" target="_blank"> Consultancy Works <i className="fa fa-angle-right"></i> </a> </p>
<p className="more"> <a className="more-info-link" href="ECEDeptAssets/docs/areas-of-interest.pdf" target="_blank"> Areas of interest <i className="fa fa-angle-right"></i> </a> </p>
<p className="more"> <a className="more-info-link" href="ECEDeptAssets/docs/12_sponcered-research-projects.pdf" target="_blank"> R&D Projects <i className="fa fa-angle-right"></i> </a> </p>
<p className="more"> <a className="more-info-link" href="ECEDeptAssets/docs/11_list-of-faculty-research-papers.pdf" target="_blank"> Research Publications <i className="fa fa-angle-right"></i> </a> </p> */}

                </div>


                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">

                  {/* <div className="details"> 
								                <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span>
								                </div> */}
                  <h4>Online Faculty Development Program on Research Trends and Applications of Nanoscale Devices and Circuits (20th July – 31st July 2024 )</h4>
                  <p><b>Organized by Department of ECE, NIT Warangal in association with E & ICT Academy, NIT Warangal Sponsored by Ministry of Electronics and Information Technology (MeitY), GoI.</b></p>
                  <p>This FDP is devoted to address the need to enhance the knowledge about the latest development pertaining to recent research trends and applications of nanoscale devices and circuits and is being conducted at National Institute of Technology, Warangal. The course will offer learning relevant to nanoscale device and circuit design and development including modelling, simulation, device fabrication and their application in real time. The topics will focus on basics, advancements and applications to benefit people from academic and research communities associated with Science and Engineering. The whole course is handled by academicians and industry experts.
                  </p>


                  <img src="https://nitw.ac.in/api/static/files/fdp_1_0_2024-8-12-19-32-58.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/fdp_1_1_2024-8-12-19-35-17.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/fdp_1_2_2024-8-12-19-36-16.jpg" alt="" className="img-fluid mb-5" />
                  <h4>Online Faculty Development Program on AI for Computer Vision, Medical Imaging and Biometric Security Applications (24th May - 31st May 2024)</h4>
                  <p><b>Organized by Electronics & ICT Academy, NIT Warangal In association with Dadi Institute of Engineering & Technology (AUTONOMOUS), Anakapalle, Visakhapatnam (Sponsored by Ministry of Electronics and Information Technology (MeitY), GOI).</b></p>
                  <p>This Faculty Development Program (FDP) on Artificial Intelligence (AI) for Computer Vision, Medical Imaging and Biometric Security Applications will help educators and researchers learn about AI basics and how it applies to Medical Imaging and Biometric technologies with multiple security Applications. Participants will explore machine learning and deep learning concepts, focusing on using AI for medical imaging which aids in diagnostics, image enhancement and surveillance systems. AI plays a pivotal role in biometric security by enabling accurate and efficient authentication methods based on facial recognition, iris recognition, fingerprint analysis, and voice recognition. Through hands-on activities and real-world examples, attendees will gain practical skills to use AI effectively with different algorithms in teaching and research. By the end of the program, participants will be ready to integrate AI tools into their work, improving their ability to teach and solve security challenges with modern technology. This will benefit participants by enhancing their expertise and teaching capabilities in these critical areas.</p>
                  <img src="https://nitw.ac.in/api/static/files/fdp2_0_2024-8-12-19-55-22.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/fdp2_1_2024-8-12-19-55-55.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/fdp2_2_2024-8-12-19-56-21.jpg" alt="" className="img-fluid mb-5" />
                  <h4>Online Faculty Development Program on Latest Trends in 5G/6G wireless communication(20th May - 24th May 2024)</h4>
                  <p><b>Organized by Department of ECE, NIT Warangal & Holy Mary Institute of Technology & Science (Autonomous) in association with CENTRE FOR TRAINING AND LEARNING, NIT Warangal (Established under the PMMMNMTT Scheme, Ministry of Education, Govt. of India)

                  </b></p>
                  <p>Representatives of industries and academia have started to look into the technological developments toward the next generation (5G & 6G), as the rollout of 4G mobile communication networks take place. As a part of that, this FDP aims at providing strong theoretical background along with practical experience in the field of 5G/6G wireless Communication. Also, in this FDP; leaders in the field describe state-of-the-art research in applying the latest methodologies in the context of tomorrow’s most important wireless applications, ranging from next-generation cellular telephony and personal communication services, to nomadic computing and wireless multimedia</p>

                  <img src="https://nitw.ac.in/api/static/files/fdp3_0_2024-8-12-19-57-11.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/fdp3_1_2024-8-12-20-1-35.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/fdp3_2_2024-8-12-19-58-54.jpg" alt="" className="img-fluid mb-5" />
                  <h4>Online Faculty Development Program on Recent Research Trends and Applications of Nanoelectronics (16th Oct - 20th Oct 2023)</h4>
                  <p><b>Organized by Department of ECE, NIT Warangal in association with Centre for Training and Learning, NIT Warangal (Established under the PMMMMNMTT Scheme, Ministry of Education, Govt. of India.</b></p>
                  <p>This FDP is devoted to address the need to enhance the knowledge about the latest development pertaining to recent research trends and applications of nanoelectronics and is being conducted at National Institute of Technology, Warangal. The course will offer learning relevant to nanoscale device and circuit design and development including modelling, simulation, device fabrication and their application in real time. The topics will focus on basics, advances and applications to benefit people from academic and research communities associated with Science and Engineering. The whole course is handled by academicians and industry experts.</p>

                  <img src="https://nitw.ac.in/api/static/files/fdp4_0_2024-8-12-19-59-33.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/fdp4_1_2024-8-12-19-59-57.jpg" alt="" className="img-fluid mb-5" />
                 

                  <img src="ECEDeptAssets/images/pg1.jpg" alt="" className="img-fluid mb-5" />
                  <img src="ECEDeptAssets/images/pg2.jpg" alt="" className="img-fluid mb-5" />
                  <img src="ECEDeptAssets/images/pg3.jpg" alt="" className="img-fluid mb-5" />
                  <img src="ECEDeptAssets/images/pg4.jpg" alt="" className="img-fluid mb-5" />
                  <img src="ECEDeptAssets/images/pg5.jpg" alt="" className="img-fluid mb-5" />
                  <img src="ECEDeptAssets/images/pg6 .jpg" alt="" className="img-fluid mb-5" />
                  <img src="ECEDeptAssets/images/pg7.jpg" alt="" className="img-fluid mb-5" />
                  <img src="ECEDeptAssets/images/pg8.jpg" alt="" className="img-fluid mb-5" />




                </div>

                {/* Awards and Honors Content */}
                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">
                </div>






              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                <p>Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default ECEDeptPage;
import { useState } from "react";
import '../css/cseDeptStyle.css';
const CSEDeptPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            <img
              src="CSEDeptAssets/images/NIT-CS-bnner.jpg" className="img-fluid" />
          </header>
          <div className="row">
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>About</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programmes</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                  <span>People </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected="false">
                  <span>Notable Alumni </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected="false">
                  <span>Students Society </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                    Group</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected="false"> <span>Awards and Honours</span> </a>
                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected="false">
                  <span>Laboratory & Facilities</span>
                </a>

                {/* <a className={`nav-link ${activeTab === 'v-pills-Events' ? 'active' : ''}`} id="v-pills-Events-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Events')}
                  role="tab" aria-controls="v-pills-Events"
                  aria-selected="false"> <span>Events</span> </a> */}

                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected="false"> <span>Photo Gallery</span> </a>



                {/* Awards and Honors tab */}



              </div>
              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/cs-rpadma"
                target="_blank"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                <h6>Prof. R. Padmavathy</h6>
              </a>
                <p> Head of the Department <br /> <a href="mailto:cse_hod[AT]nitw[DOT]ac[DOT]in"
                  style={{ color: "#808080" }}>cse_hod[AT]nitw[DOT]ac[DOT]in</a> <br /> 9490165347 </p>
              </div>
            </div>
            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p>The Department of Computer Science & Engineering was established in the year 1991.
                      The department offers high quality undergraduate, postgraduate and doctoral
                      programs. The B. Tech (Computer Science and Engineering) program was started in the
                      year 1983 with an intake of 20 students. The intake was subsequently increased to
                      120 in 2008. M. Tech (Computer Science and Engineering) program was started in 1987
                      with an intake of 18 and subsequently increased to 20 in 2008. M. Tech (Information
                      Security) was introduced in the year 2008 Under ISEAP sanctioned by Ministry of
                      Communication and Information Technology (MCIT), DOE, GOI, New Delhi with intake of
                      20. Later, it was renamed as Computer Science and Information Security. The Master
                      of Computer Applications (MCA) program was started in 1986 with an intake of 30 and
                      increased to 46 from 2008. B.Tech, M.Tech (CSE) and M.Tech (CSIS) programs were
                      accredited in 2014 by NBA as per Washington Accord.</p>
                    <p>The department has distinguished and committed faculty members with PhD from reputed
                      institutes. It has close rapport with MICROSOFT, TCS, INFOSYS, ORACLE, TRDDC-Pune,
                      SUN Microsystems, EMC2, ACCENTURE, C-DAC, MOTOROLA, HONEYWELL, NOVELL, PHILIPS,
                      SAMSUNG, and IBM-Bangalore. The department has MOUs with TCS, IBM, C-DAC and
                      INFOSYS, for training students and faculty on latest cutting edge technologies and
                      also to pursue Research and Development activities.The Department has been selected
                      as a Remote Center under Information Security Awareness Project (ISEAP) Phase II,
                      MCIT Dept of IT. Along with department of ECE, it is associated in E&ICT Academy
                      project sanctioned by Department of Electronics and Information Technology, Govt of
                      India for Rs.25 crores to train faculty from Engineering and Polytechnic Streams
                      across Telangana, Andhra Pradesh, Karnataka States and Pudicherry, Andaman & Nicobar
                      islands and Lakshadweep UTs.</p>
                  </div>
                                         {/* NUMBERING START*/}
                                         <div class="row nirf-ranking-counter text-center">
                         
                           
                          
                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>32</span></h2>
                                 
                                 <h6 class="mt-0"><b>Faculty</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>9</span></h2>
                                 <h6 class="mt-0"><b>Staff</b></h6>
                             </div>
                         </div>

                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>120</span></h2>
                                 <h6 class="mt-0"><b>PhD Research Scholars</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>749</span></h2>
                                 <h6 class="mt-0"><b>Undergraduate Students</b></h6>
                             </div>
                         </div>


                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                             <div class="">
                                 <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>298</span></h2>
                                 <h6 class="mt-0"><b>Postgraduate Students</b></h6>
                             </div>
                         </div>



                         <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                           <div class="">
                               <h2 class="mb-0"><span class="counter" style={{color:"#c20315"}}>420</span></h2>
                               <h6 class="mt-0"><b>Publications</b></h6>
                           </div>
                       </div>


                       
                     </div>
                  <div className="row vision-mission-section">
                    <div className="col-md-6 vision-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                        alt="" /> Vision</h4>
                      <p className="  mb-4">Attaining global recognition in Computer Science &
                        Engineering education, research and training to meet the growing needs of the
                        industry and society.</p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                          className="img-fluid" />
                    </div>
                    <div className="col-md-6 mission-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                        alt="" /> Mission</h4>
                      <p className="  mb-2">Imparting quality education through well-designed
                        curriculum in tune with the challenging software needs of the industry.</p>
                      <p className="  mb-2">Providing state-of-art research facilities to generate
                        knowledge and develop technologies in the thrust areas of Computer Science and
                        Engineering.</p>
                      <p className="  mb-2">Developing linkages with world class organizations to
                        strengthen industry-academia relationships for mutual benefit.</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className="mt-2 mb-2 notice-board1">
                      <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardCSE/ComputerScienceandEngineering">Notices/Announcements</a>
                    </h4>
                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                  {/*<div className="row">
                                    <div className="col-md-12">
                                    <h3 className="mb-3">PhD</h3>
                                    </div>
                                    </div>*/}
                  <div className="row">


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>B.Tech</h3>
                            <p>The B. Tech (Computer Science and Engineering) program was started in
                              the year 1983 with an intake of 20 students. The intake was
                              subsequently increased to 120 in 2008. Currently the strength is
                              177.</p>
                            {/*<img src="CSEDeptAssets/images/btech.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="CSEDeptAssets/docs/07_btech-in-cse.pdf" target="_blank"> B.Tech
                            Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech in Computer Science and Engineering </h3>
                            <p>M. Tech (Computer Science and Engineering) program was started in
                              1987 with an intake of 18 and subsequently increased to 20 in 2008.
                              Currently the strength is 30.</p>
                            {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className=" more-info-link"
                            href="CSEDeptAssets/docs/re-reivsed-m-tech-cse-syllabus-20-8-21v10.pdf"
                            target="_blank"> M.Tech CSE Scheme and Syllabus <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech in Computer Science and Information Security </h3>
                            <p>M. Tech (Information Security) was introduced in the year 2008 Under
                              ISEAP sanctioned by Ministry of Communication and Information
                              Technology (MCIT), DOE, GOI, New Delhi with intake of 20. Later, it
                              was renamed as Computer Science and Information Security and with
                              current intake 30.</p>
                            {/*<img src="CSEDeptAssets/images/mtech2.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="CSEDeptAssets/docs/re-revise-mtech-csis-syllabus-august-20-2021.pdf"
                            target="_blank">  Computer Science and
                            Information Security Scheme &amp; Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>MCA</h3>
                            <p>The Master of Computer Applications (MCA) program was started in 1986
                              with an intake of 30 and increased to 46 from 2008. Currently the
                              strength is 58.</p>
                            {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link" href="CSEDeptAssets/docs/cse-mca-tt.pdf"
                            target="_blank"> MCA Even Semester Timetable <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Ph.D.</h3>
                            {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="buttton more-info-link"
                            href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                            target="_blank"> Rules and Regulations of Ph.D. Program <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div className="row laboratory-facilities">
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Computing Laboratory</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className="card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">Hardware</h4>
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">HP EliteDesk 800 g1 Small Form Factor
                                Systemsi7 @3.40GHz, RAM 4GB, HDD 500GB</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                              <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 5</p>
                            </ul>
                            <h4 className="card-title  mb-2"> Software</h4>
                            <p>Dual boot with Windows 7 and Ubuntu 14.04, NetBeans 7, MSDN, Dev C++
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CSEDeptAssets/images/comp-laboratory.jpg"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Data Engineering Laboratory</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className=" card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">Hardware</h4>
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">HP EliteDesk 800 g1 SFF Systems i7 @3.40GHz,
                                4GB RAM, 500GB HDD</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                            </ul>
                            <h4 className="card-title  mb-2">Software</h4>
                            <p>Cognos BIClementine Version 11.0, Oracle 11g (Unlimited user
                              Licenses), MSDN Academic Alliance Software, Informatica (25 user
                              licenses )</p>
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CSEDeptAssets/images/data-eng.jpg"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Open Source Development Laboratory</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className=" card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">Hardware</h4>
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">HP Proliant 8300 Small Form Factor systems
                                i7@ 3.40GHz, RAM 8GB, HDD 500GB</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 45</p>
                            </ul>
                            <h4 className="card-title  mb-2">Software</h4>
                            <p>Dual boot with Windows XP and Ubuntu 12.0, NetBeans 7, Oracle Client
                              10</p>
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CSEDeptAssets/images/opensrc.jpg"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Software Engineering Laboratory</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className=" card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">Hardware</h4>
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 50</p>
                              <li className="text-black">Acer Veriton Intel Core i7 with 1TB HDD, 8 GB
                                RAM</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 40</p>
                            </ul>
                            <h4 className="card-title  mb-2">Software</h4>
                            <p>Rational Testing Suite, MSDN, NetBeans 7, Microsoft Assembler, Dev
                              C++ </p>
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CSEDeptAssets/images/swlab.jpg" alt="Photo of sunset"
                          className="img-fluid" /> </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Cloud Laboratory</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className=" card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">Hardware</h4>
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">i7 processor, 16GB RAM</li>

                              <li className="text-black">Windows OS, Ubuntu OS</li>

                            </ul>

                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CSEDeptAssets/images/cloud-lab.jpg"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Wireless and Security Laboratory</h3>
                      </header>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card item-card card-block">
                            {/*<p className=" card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">Hardware</h4>
                            <ul className="ps-0 list-unstyled">
                              <li className="text-black">HP Proliant 8300 Micro Tower systemsi5
                                @3.40GHz, RAM 2GB, 500GB HDD</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 30</p>
                              <li className="text-black">Dell Optiplex 760Intel Core2 duo CPU E8400
                                @3.00GHz, 300GB HDD, 4GB RAM</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 10</p>
                              <li className="text-black">HCL Dual core2Intel® Core™ 2 Duo CPU
                                E4500@2.20 GHz, 1GB RAM, 160GB HDD</li>
                              <p className="list-para pb-0 mb-3">Number of Systems: 4</p>
                            </ul>
                            <h4 className="card-title  mb-2">Software</h4>
                            <p>ONE Simulator, NS3, Rational Rose Tools, MSDN, Dev C++</p>
                          </div>
                        </div>
                        <div className="col-md-6"> <img src="CSEDeptAssets/images/wireless.jpg"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">Servers</h3>
                      </header>
                      <div className="row">
                        {/*<div className="col-md-6">
                        <div className="card item-card card-block">
                          <p className=" card-text mb-3">A server is a computer or system that provides resources, data, services, or programs to other computers, known as clients, over a network. In theory, whenever computers share resources with client machines they are considered servers. There are many types of servers, including web servers, mail servers, and virtual servers.</p>
                        </div>
                      </div>*/}
                        <div className="col-md-12"> <img src="CSEDeptAssets/images/server.jpg"
                          alt="Photo of sunset" className="img-fluid" /> </div>
                      </div> <br />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="details-card">
                            <h4 className="card-title  mb-2">Windows File Server</h4>
                            <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                              Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD </p>
                          </div>
                          <div className="details-card">
                            <h4 className="card-title  mb-2">Oracle Server</h4>
                            <p className="text-black"> <b>Hardware: </b>HP Proliant DL160 Gen8 Intel®
                              Xeon® CPU E5 -2630 @2.30GHz with 16GB RAM, 2*1TB </p>
                          </div>
                          <div className="details-card">
                            <h4 className="card-title  mb-2">McAFEE Server</h4>
                            <p className="text-black"> <b>Hardware: </b>HCL Intel® Xeon® CPU 3.40GHz
                              with 4GB RAM, 3*120GB HDD </p>
                          </div>
                          <div className="details-card">
                            <h4 className="card-title  mb-2">Proxy Server</h4>
                            <p className="text-black"> <b>Hardware: </b>Hp Compaq Elite 8300 SFF (i5
                              -3470) CPU @3.40GHz, RAM 2GB, HDD 500GB </p>
                          </div>
                          <div className="details-card">
                            <h4 className="card-title  mb-2">COGNOS Server</h4>
                            <p className="text-black"> <b>Hardware: </b>HP Proliant DL360 Intel® Xeon®
                              CPU 3.20GHz, 3GB RAM, 160GB HDD </p>
                          </div>
                          <div className="details-card">
                            <h4 className="card-title  mb-2">Rational Rose Server</h4>
                            <p className="text-black"> <b>Hardware: </b>IBM e-server Intel® Xeon™ CPU
                              2.40 GHz, 1GB RAM, 40GB HDD </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="details-card">
                            <h4 className="card-title  mb-2">Linux Server</h4>
                            <p className="text-black"> <b>Hardware: </b>HP Proliant DL360e Gen8 intel®
                              Xeon® CPU E5-2420 @1.90GHz with 32GB RAM, 2*2TB HDD) </p>
                          </div>
                          <div className="details-card">
                            <h4 className="card-title  mb-2">High Performance Computing Server (8
                              node Cluster)</h4>
                            <p className="text-black"> <b>Hardware: </b>HP Proliant DL380P Gen82 x
                              Intel® xeon® CPU E5-2640 (2.5 GHz / 6-core/15MB / 95w) Processor64
                              GB DDR RAM, HP SA 410i RAID controller with 1 GB FBWCHP SN1000E 16GB
                              Dual Port, FC HBA/1200WIntel® xeon® CPU E5-2640 (2.5 GHz /
                              6-core/15MB / 95w) ProcessorIntel 7500 chip set with node controller
                            </p>
                          </div>
                          <div className="details-card">
                            <h4 className="card-title  mb-2">Big Data Server</h4>
                            <p className="text-black"> <b>Hardware: </b>HP Proliant DL980 Gen78 x Intel®
                              xeon® CPU E7-2830 (2.13 GHz / 8-core/24MB / 105w) ProcessorIntel
                              7500 chip set with node controller, 512 GB DDR RAMHP SA 410i RAID
                              controller with 1 GB FBWC6x600GB 10K HDD, DVD + RW 12 Nos. of PCI
                              slots4x dual port Giga Bit Ethernet NIC and 2x 10GBPS Ethernet
                              CardHP SN1000E 16GB Dual Port, FC HBA/1200WRack Mountable Hot
                              pluggable Redundant Power Supplies and Redundant fans/ Server
                              Manageability SoftwareOS: CentOS 6.5 Server with Hadoop2.2 </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                      id="nav-AdjunctFaculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor</a>




                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                      Staff</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                      data-toggle="tab" role="tab"
                      aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                  </div>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">
                      {/*<h4 className=" mb-4">Faculty</h4>*/}
                      {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                      <section id="profile-info">

                        {/* <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17106"
                            > <img src="CSEDeptAssets/images/33.jpg" alt=""
                              className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17106"
                                  >Prof. Arijit Das</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 919038675482 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:arijitdas[AT]nitw[DOT]ac[DOT]in">arijitdas[AT]nitw[DOT]ac[DOT]in</a> </p>
                                  <p>AI, NLP</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-bprao
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-bprao
"
                                    target="_blank">Prof. Balaprakasa Rao Killi</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2716<br />
                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 7002457102 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:bsprao[AT]nitw[DOT]ac[DOT]in">bsprao[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Software Defined Networking.Internet of
                                    Things.Reinforcement Learning.Game Theory.Network
                                    Optimization.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-chapram
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/8.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-chapram
"
                                    target="_blank">Prof. Ch. Sudhakar</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2731<br />

                                    <i className="fa fa-mobile fa-lg" aria-hidden="true"></i> 9440647945 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chapram[AT]nitw[DOT]ac[DOT]in">chapram[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Unix Internals, Advanced Operating Systems, Cloud
                                    Computing, Distributed Systems, High Performance
                                    Computing, Operating Systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16333"
                              target="_blank" className="img-fluid"> <img
                                src="CSEDeptAssets/images/2.jpg" alt="" className="img-fluid" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16333
"
                                    target="_blank">Prof. D V L N Somayajulu</a> </h5>
                                  <span>Professor (HAG)</span>

                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i>-<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> -<br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:soma[AT]nitw[DOT]ac[DOT]in">soma[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Databases, Information Extraction, Query Processing, Big
                                    Data and Privacy</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-esbabu
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-esbabu
"
                                    target="_blank">Prof. E. Suresh Babu</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2717<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9440959713 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:esbabu[AT]nitw[DOT]ac[DOT]in">esbabu[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Wireless Networks, Internet of Things (IoT), IoT
                                    Security, Fog Security, Edge Security, Blockchain
                                    Technology</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-iep
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/19.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-iep
"
                                    target="_blank">Prof. I. Earnest Paul</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> -<br />


                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9494466490 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:iep[AT]nitw[DOT]ac[DOT]in">iep[AT]nitw[DOT]ac[DOT]in</a> </p>
                                  <p>Visual Analytics; Video Content Analysis; Computer
                                    Vision; Deep Learning; Soft Computing; Machine Learning
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-ilaiahkavati"
                              target="_blank"> <img src="CSEDeptAssets/images/23.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-ilaiahkavati" target="_blank">Prof. Ilaiah Kavati</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2718<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9848916272 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:ilaiahkavati[AT]nitw[DOT]ac[DOT]in">ilaiahkavati[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Image Processing; Internet of Things; Biometrics,
                                    Security</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-kramesh
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/7.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-kramesh
"
                                    target="_blank">Prof. K. Ramesh</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2712<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9849432598 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:kramesh[AT]nitw[DOT]ac[DOT]in">kramesh[AT]nitw[DOT]ac[DOT]in</a>
                                    {/* , <a href="">srirameesh@yahoo.com</a> */}


                                  </p>
                                  <p>Serverless Architectures and Coding infrastructures in
                                    Service-oriented, Distributed, Secured Computing
                                    Environments in pursuit of Model Driven
                                    Framework-oriented Systems and Applications Software.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-kadambari
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/14.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-kadambari
"
                                    target="_blank">Prof. K. V. Kadambari</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2740<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9705460461 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:kadambari[AT]nitw[DOT]ac[DOT]in">kadambari[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Computational Neuroscience, Machine Learning, Artificial
                                    Intelligence, Neuro-Imaging. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-msandhya"
                              target="_blank"> <img src="CSEDeptAssets/images/21.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-msandhya"
                                    target="_blank">Prof. M. Sandhya</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2721<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9394468554 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:msandhya[AT]nitw[DOT]ac[DOT]in">msandhya[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p> Biometrics Image Processing Pattern Recognition</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-msv
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/22.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-msv
"
                                    target="_blank">Prof. M. Srinivas</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2714<br />
                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> - <br />
                                    <i className="fa fa-envelope"
                                      aria-hidden="true"></i> <a
                                        href="mailto:msv[AT]nitw[DOT]ac[DOT]in">msv[AT]nitw[DOT]ac[DOT]in</a> </p>
                                  <p>Machine Learning; Medical Imaging; Computer Vision; Deep
                                    Learning ; and Sparse Representation</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-mkbajpai
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/30.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-mkbajpai
"
                                    target="_blank">Prof. Manish Kumar Bajpai</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2705<br />
                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9425156289 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:mkbajpai[AT]nitw[DOT]ac[DOT]in">mkbajpai[AT]nitw[DOT]ac[DOT]in
                                    </a> </p>
                                  <p>High Performance Computing, Augmented Intelligence</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-manjubalabisi
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/18.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-manjubalabisi
"
                                    target="_blank">Prof. Manjubala Bisi</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2720<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9502940360 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:manjubalabisi[AT]nitw[DOT]ac[DOT]in">manjubalabisi[AT]nitw[DOT]ac[DOT]in
                                    </a> </p>
                                  <p>Software Engineering (Software Reliability Modelling and
                                    Prediction); Machine Learning; Soft Computing
                                    Techniques, Optimization Techniques</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-nidhisonkar"
                              target="_blank"> <img src="https://www.nitw.ac.in/CSEDeptAssets/images/31.jpg
                              " alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-nidhisonkar
"
                                    target="_blank">Prof. Nidhi Sonkar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2709<br />
                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 8871358404 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:nidhisonkar[AT]nitw[DOT]ac[DOT]in">nidhisonkar[AT]nitw[DOT]ac[DOT]in
                                    </a> </p>
                                  <p>Delay Tolerant Network, Wireless Network, Mobile Communication, Artificial Intelligence.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>







                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-prkrishna
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/6.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-prkrishna
"
                                    target="_blank">Prof. P. Radha Krishna</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2703<br />
                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> - <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:prkrishna[AT]nitw[DOT]ac[DOT]in">prkrishna[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Data Mining, Big Data, Machine Learning, Databases and
                                    Workflow Systems. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-pvsr"
                              target="_blank"> <img src="CSEDeptAssets/images/15.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-pvsr"
                                    target="_blank">Prof. P. Venkata Subba Reddy</a>
                                  </h5> <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2741<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 8332969417 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:pvsr[AT]nitw[DOT]ac[DOT]in"> pvsr[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Algorithms and Graph Theory</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-priyankac"
                              target="_blank"> <img src="CSEDeptAssets/images/16.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-priyankac"
                                    target="_blank">Prof. Priyanka Chawla</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2719<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 7982750652 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:priyankac[AT]nitw[DOT]ac[DOT]in">
                                      priyankac[AT]nitw[DOT]ac[DOT]in </a> </p>
                                  <p>Cloud Computing, Fog Computing, Software Engineering,
                                    Nature Inspired Computing, Artificial Intelligence </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-rbvs66"
                              target="_blank"> <img src="CSEDeptAssets/images/5.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-rbvs66

"
                                    target="_blank">Prof. R. B. V. Subramaanyam</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2736<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9491346969, 9441434454
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    {/* <a href="mailto:rbvs66@gmail.com">rbvs66@gmail.com</a>, */}
                                    <a href=""> rbvs66[AT]nitw[DOT]ac[DOT]in</a> </p>
                                  <p>Data Mining - Association Rules, Sequential Patterns,
                                    Temporal data mining, Fuzzy Data Mining, Big Data
                                    Analytics ; Distributed Data Mining; Graph Databases;
                                    Pattern Recognition; Soft Computing;</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-rpadma
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/9.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-rpadma
"
                                    target="_blank">Prof. R. Padmavathy</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-8738<br />


                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9440173819
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:rpadma[AT]nitw[DOT]ac[DOT]in"> rpadma[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Cryptanalysis and Network Security </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-raju"
                              target="_blank"> <img src="CSEDeptAssets/images/12.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-raju
"
                                    target="_blank">Prof. Raju Bhukya</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2732<br />
                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9700553922, 8332969733
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:raju[AT]nitw[DOT]ac[DOT]in"> raju[AT]nitw[DOT]ac[DOT]in</a>
                                    {/* , <a
                                                                                href="">drrajunitw@gmail.com</a> */}
                                  </p>
                                  <p>Bioinformatics, Agri-Informatics, Internet of things</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-rmlswamy
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/28.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-rmlswamy
"
                                    target="_blank">Prof. Ramalingaswamy Cheruku</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2710<br />
                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9573827143 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:rmlswamy[AT]nitw[DOT]ac[DOT]in">rmlswamy[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Soft Computing, Optimization, Deep Learning and Its
                                    Applications, ANNs, Machine Learning, Bio & Nature
                                    inspired Algorithms, Data Mining, Fuzzy logic.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-rashrr
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-rashrr
"
                                    target="_blank">Prof. Rashmi Ranjan Rout</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2742<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 08332969418 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:rashrr[AT]nitw[DOT]ac[DOT]in">rashrr[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Mobile Computing; Wireless Ad-hoc and Sensor Networks;
                                    Online Social Networks; Cyber Physical Systems; Internet
                                    of Things; Network Security; Cloud Computing</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-ravic
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/1.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-ravic
"
                                    target="_blank"> Prof. S. Ravi Chandra</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2730<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> - <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:ravic[AT]nitw[DOT]ac[DOT]in">ravic[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p> Data Privacy, Software Engineering, Software
                                    Architecture, Design Patterns, Service Oriented
                                    Architecture </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-sanghu
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/29.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-sanghu
"
                                    target="_blank">Prof. Sangharatna Godboley</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2715<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 7013306805 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:sanghu[AT]nitw[DOT]ac[DOT]in">sanghu[AT]nitw[DOT]ac[DOT]in
                                    </a> </p>
                                  <p>Program Analysis; Software Testing; Fuzzing;
                                    Verification; Smart Contracts; Machine Learning</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-sanjaya
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/25.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-sanjaya
"
                                    target="_blank">Prof. Sanjaya Kumar Panda</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2723<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9861126947 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:sanjaya[AT]nitw[DOT]ac[DOT]in">
                                      sanjaya[AT]nitw[DOT]ac[DOT]in</a> </p>
                                  <p>Cloud and Fog Computing, Recommender Systems and Internet
                                    of Things </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-shivadarshan
                              "
                              target="_blank"> <img src="https://www.nitw.ac.in/CSEDeptAssets/images/32.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-shivadarshan
"
                                    target="_blank">Prof. Shiva Darshan SL</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2708<br />


                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9743413637 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:shivadarshan[AT]nitw[DOT]ac[DOT]in">shivadarshan[AT]nitw[DOT]ac[DOT]in</a>

                                  </p>
                                  <p>The research interests include the development of cyber security systems and relevant domains through the use of a dynamic approach for malware vulnerability assessment, machine learning, and deep learning concepts for security.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-sriramk
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-sriramk
"
                                    target="_blank">Prof. Sriram Kailasam</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2722<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 8894275490 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:sriramk[AT]nitw[DOT]ac[DOT]in"> sriramk[AT]nitw[DOT]ac[DOT]in
                                    </a> </p>
                                  <p>Distributed Systems, Scalable algorithms for Formal
                                    Concept Analysis, Big Data Analytics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-sujit.das
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/24.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-sujit.das
"
                                    target="_blank">Prof. Sujit Das</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2724<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9434588993 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:sujit.das[AT]nitw[DOT]ac[DOT]in">sujit.das[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Soft Computing, Artificial Intelligence, Evolutionary
                                    Computation, Machine learning, and Multi-criteria
                                    Decision Making</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-trk
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/11.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-trk
"
                                    target="_blank">Prof. T. Ramakrishnudu</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2734<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9866876842 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:trk[AT]nitw[DOT]ac[DOT]in">trk[AT]nitw[DOT]ac[DOT]in</a> </p>
                                  <p>Distributed data mining., Big Data Analytics, Web Mining,
                                    Text Mining, Urban Computing, Social Media Analysis,
                                    Natural Language Processing </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-tapan"
                            > <img src="https://nitw.ac.in/api/static/files/Prof._Tapan_Kumar_Sahoo_2024-1-23-17-23-19.jpg" alt=""
                              className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-tapan"
                                  >Prof. Tapan Kumar Sahoo</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> -<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 7978180153 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:arijitdas[AT]nitw[DOT]ac[DOT]in">tapan[AT]nitw[DOT]ac[DOT]in </a> </p>
                                  <p> Pattern Analysis and Machine Learning</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-usnraju
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-usnraju
"
                                    target="_blank">Prof. U. S. N. Raju</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info">

                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2739<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9440149146 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:usnraju[AT]nitw[DOT]ac[DOT]in">usnraju[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Computer Vision and Image Processing, Digital Olfaction.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-venkat.kagita
                              "
                              target="_blank"> <img src="CSEDeptAssets/images/27.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-venkat.kagita
"
                                    target="_blank">Prof. Venkateswara Rao Kagita</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2713<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 6281746931 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:venkat.kagita[AT]nitw[DOT]ac[DOT]in">venkat.kagita[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                  <p>Recommender Systems; Machine Learning; Deep Learning;
                                    Data Mining;</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/cs-venkannau
                              "
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Venkanna_U_2023-11-29-16-48-39.jpg" alt=""
                                className="img-fluid" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/cs-venkannau
"
                                    target="_blank">Prof. Venkanna U</a>
                                  </h5> <span>Associate Professor</span>
                                  <p className="contact-info">
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2707<br />

                                    <i className="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9440973184 <br /> <i
                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:venkannau[AT]nitw[DOT]ac[DOT]in ">venkannau[AT]nitw[DOT]ac[DOT]in </a>
                                  </p>
                                  <p>Internet of Things (IoT), Software Defined Networks (SDN), Network Security</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </section>
                    </div>



                    <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                      aria-labelledby="nav-AdjunctFaculty-tab">

                      <div className="col-md-12 profile-info-individual">
                        <div className="row">

                          {/* <div className="col-md-9">
                            <div className="card">
                              <div className="d-flex align-items-center">
                                <div className="image"> <img src="CSEDeptAssets/images/af1.jpg" alt=""
                                  className="img-fluid" /> </div>
                                <div className="ml-3 w-100">
                                  <h6 className="mb-0 mt-0"> Dr. Shukla Darpan Krishnakumar </h6>
                                  <div className=" mt-2">
                                    <p className="contact-info"> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:darpanshukla91@gmail.com">darpanshukla91@gmail.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div className="col-md-9">
                            <div className="card">
                              <div className="d-flex align-items-center">
                                <div className="image"> <img src="CSEDeptAssets/images/af2.jpg" alt=""
                                  className="img-fluid" /> </div>
                                <div className="ml-3 w-100">
                                  <h6 className="mb-0 mt-0"> Dr. V. C. V. Rao </h6>
                                  <div className=" mt-2">
                                    <p className="contact-info"> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vcvrao287@gmail.com">vcvrao287@gmail.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>




                          {/* <div className="col-md-9">
                            <div className="card">
                              <div className="d-flex align-items-center">
                                <div className="image"> <img src=" https://nitw.ac.in/api/static/files/Dr._T._RAMESH_2024-4-3-11-28-2.jpg" alt=""
                                  className="img-fluid" width="150" height="180" /> </div>
                                <div className="ml-3 w-100">
                                  <h6 className="mb-0 mt-0"> Prof. T. Ramesh </h6>
                                  <div className=" mt-2">
                                    <p className="contact-info"> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rmesht@gmail.com">rmesht@gmail.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}





                        </div>
                      </div>
                    </div>





                    <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                      aria-labelledby="nav-NonTeachingStaff-tab">
                      <div className="row academic_group">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st1.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Smt. K S S S Padmasri</h6> <span>Senior
                                  Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9493479894 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:padmasri[AT]nitw[DOT]ac[DOT]in">padmasri[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st2.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Smt. Shalini Avala</h6> <span>Technical
                                  Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8978265083 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:shalinia[AT]nitw[DOT]ac[DOT]in">shalinia[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st3.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri.Rayabarapu Shivamallikarjun</h6>
                                <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9108288132 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rayabarapus[AT]nitw[DOT]ac[DOT]in">rayabarapus[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st4.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Rapelli Jeevan Kumar</h6>
                                <span>Senior Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9014613744 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rapellij[AT]nitw[DOT]ac[DOT]in">rapellij[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st5.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Deekonda Ramesh</h6> <span>Senior
                                  Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969378 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:dr72[AT]nitw[DOT]ac[DOT]in">dr72[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Smt._D._Aruna_Mary_2024-4-10-16-20-35.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Smt. D. Aruna Mary</h6> <span>Junior Asst. (Upg)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 8332969239 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:dhugipati@gmail.com">dhugipati@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st6.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Banoth Ramesh</h6> <span>Junior
                                  Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8445594954 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rameshb[AT]nitw[DOT]ac[DOT]in">rameshb[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st7.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Smt. K Venkatamma</h6> <span>Technician
                                  (Upgd)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9550844958 </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st8.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. Kandukuri Jayaraj</h6>
                                <span>Caretaker SG-II</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969611 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kjayaraj[AT]nitw[DOT]ac[DOT]in">kjayaraj[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st9.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. G Kumarswamy</h6> <span>Senior
                                  Caretaker</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9398676745 </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CSEDeptAssets/images/st10.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. B. Sunil Kumar</h6> <span>Office
                                  Attendant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9959236188 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bsunil[AT]nitw[DOT]ac[DOT]in">bsunil[AT]nitw[DOT]ac[DOT]in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </div>

                    </div>
                    <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">
                      {/*<h4 className="">Ph.D. Awardee</h4>
                    <p>
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






                      <div className="row phd-std-row">



                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b></b>
                  </p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Vishnu Vardhan</h6>
            <span>Part-Time Research Scholar</span>
          </div>
        </div> */}




                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd2.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Computational Neuroscience</b>
                  </p>
                
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Lavanya Madhuri Bollipo</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9989685689 <br/>  <a href="mailto:lavanyasandeep@gmail.com">lavanyasandeep@gmail.com</a> </p>
                  
            
          </div>
        </div> */}




                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img"> 
              <img src="CSEDeptAssets/images/phd3.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Cryptography</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">T Preethi</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  7893543695
     <br/>  <a href="mailto:preethi3737@gmail.com" >preethi3737@gmail.com</a> </p>
            
          </div>
        </div> */}





                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd4.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Social Network Analysis</b>
                  </p>
                </div>
              </div>
            </div>
            <h6 className="std-title">Abhilash M H</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  7893543695
     <br/>  <a href="mailto:abhi.6882@gmail.com" >abhi.6882@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd5.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Distributed Algorithms</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">S Sathyanarayanan</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9487673636
     <br/>  <a href="mailto:mail2satviv@gmail.com" >mail2satviv@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd6.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">G Spoorthy</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9701296256
     <br/>  <a href="mailto:sspoorthy6@gmail.com" >sspoorthy6@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd7.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Computer Vision and Image
    Processing</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Kanaparthi Suresh Kumar</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9885174480
     <br/>  <a href="mailto:sureshkonline@gmail.com" >sureshkonline@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd8.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Chaitanya Kanchibhotla</h6>
            <span>Part-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9949943938
     <br/>  <a href="mailto:reachchaitanyak@gmail.com" >reachchaitanyak@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd9.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Software Defined Networks </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Somesula Manoj Kumar</h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  9642628253 
     <br/>  <a href="mailto:manuroyal060@gmail.com" >manuroyal060@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd10.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Mobile computing and security </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">D Mallikarjun Reddy </h6>
            <span>Full-Time Research Scholar (IDRBT)  </span>
            <hr/>
            
            <p className="contact-info">  7207558884  
     <br/>  <a href="mailto:arjun753016@gmail.com" >arjun753016@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd11.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Privacy Preserving Data Publishing </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Hemkumar D </h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9591259817  
     <br/>  <a href="mailto:dhkmedar@gmail.com" >dhkmedar@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd12.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">N. Satya Krishna </h6>
            <span>Full-Time Research Scholar (IDRBT) </span>
            <hr/>
            
            <p className="contact-info">  8341502855  
     <br/>  <a href="mailto:satya.krishna.nunna@gmail.com" >satya.krishna.nunna@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd13.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Service Oriented Architecture </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Vinay Raj  </h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9963850192  
     <br/>  <a href="mailto:vinayrajlodh@gmail.com" >vinayrajlodh@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd14.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Cryptography  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">B Satish Babu  </h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9885968533  
     <br/>  <a href="mailto:satishbabu4u@gmail.com" >satishbabu4u@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd15.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Big Data Analytics  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Avinash Potluri</h6>
            <span>Full-Time Research Scholar (IDRBT)    </span>
            <hr/>
            
            <p className="contact-info">  9490909404  
     <br/>  <a href="mailto:potluri.avinash1@gmail.com" >potluri.avinash1@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd16.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b></b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Amdewar Godavari Ramlu </h6>
            <span>Full-Time Research Scholar      </span>
            <hr/>
            
            <p className="contact-info"><a href="mailto:amdewar_g@yahoo.co.in" >amdewar_g@yahoo.co.in </a> </p>
            
          </div>
        </div> */}



                        {/*         
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd17.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b></b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Gudeme Jaya Rao</h6>
            <span>Full-Time Research Scholar (IDRBT)     </span>
            
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd18.jpg" className="img-fluid image"  alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Graph Algorithms   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Chakradhar Padamutham</h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  8686780637   </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd19.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Cancellable Biometrics   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mahesh Kumar Morampudi</h6>
            <span>Full-Time Research Scholar (IDRBT)    </span>
            <hr/>
            
            <p className="contact-info">  8522983826  
     <br/>  <a href="mailto:morampudimahesh@gmail.com" >morampudimahesh@gmail.com</a> </p>
            
          </div>
        </div> */}





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd20.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining and Machine Learning  </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">K.V.T.K.N. Prashanth</h6>
                            <span>Full-Time Research Scholar       </span>
                            <hr />

                            <p className="contact-info">  8978567535
                              <br />  <span><a href="mailto:kasturiprashanth@gmail.com" >kasturiprashanth@gmail.com</a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd21.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Application Security in Computer Networks  </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">P.V. Shalini</h6>
                            <span>Full-Time Research Scholar (IDRBT)  </span>
                            <hr />

                            <p className="contact-info">  8374046101
                              <br />  <span><a href="mailto:pvshalini@gmail.com" >pvshalini@gmail.com </a></span> </p>

                          </div>
                        </div>






                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd22.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Software Engineering and Machine Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">B. Uma Maheswara Sharma</h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9542954304  
     <br/>  <a href="mailto:sarma.hcu@gmail.com" >sarma.hcu@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd23.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Bioinformatics, Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Chandra Mohan D</h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  9032796381   
     <br/>  <a href="mailto:chandu.nitw44@gmail.com" >chandu.nitw44@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd24.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Wireless vehicular sensor networks </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Satish Vemireddy</h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  7382758996   
     <br/>  <a href="mailto:svreddyiitb@gmail.com" >svreddyiitb@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd25.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Image Processing  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Sweta Panigrahi</h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  9523685956   
     <br/>  <a href="mailto:Sweta.panigrahi6@gmail.com" >Sweta.panigrahi6@gmail.com   </a> </p>
            
          </div>
        </div> */}





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd26.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Location Based Services for Network Applications   </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Sanjib Kumar Raul</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info">  7008701931
                              <br /> <span> <a href="mailto:Sanjib.roul@gmail.com" >Sanjib.roul@gmail.com    </a> </span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd27.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Security & Privacy   </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Vinod Kumar Mahor</h6>
                            <span>Part-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info">  9440745665
                              <br />  <span><a href="mailto:VM712136@student.nitw.ac.in" >VM712136@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd28.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining   </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ravi Kanth Kotha</h6>
                            <span>Full-Time Research Scholar (IDRBT)     </span>
                            <hr />

                            <p className="contact-info">  8939668158
                              <br /> <span> <a href="mailto:ravikanth1027@gmail.com" >ravikanth1027@gmail.com    </a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd29.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>IOT and Big Data   </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Priyanka Parimi</h6>
                            <span>Full-Time Research Scholar      </span>
                            <hr />

                            <p className="contact-info">  8897804040
                              <br />  <span><a href="mailto:priyanka.parimi@gmail.com" >priyanka.parimi@gmail.com    </a> </span></p>

                          </div>
                        </div>





                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd30.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Biometrics   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mukku Nisanth Kartheek</h6>
            <span>Full-Time Research Scholar (IDRBT)</span>
            <hr/>
            
            <p className="contact-info">  9443168421      
     <br/>  <a href="mailto:nisanthkartheek@gmail.com" >nisanthkartheek@gmail.com     </a> </p>
            
          </div>
        </div>
         */}




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd31.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Information Security   </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Cheetoori Jayanth Babu</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  9989710345
                              <br />  <span><a href="mailto:jayanth_babu786@student.nitw.ac.in" >jayanth_babu786@student.nitw.ac.in     </a></span> </p>

                          </div>
                        </div>





                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd32.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Image Processing   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Debanjan Pathak</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  9002505683       
     <br/>  <a href="mailto:debanjan21pathak@gmail.com" >debanjan21pathak@gmail.com    </a> </p>
            
          </div>
        </div> */}





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd33.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data Analytics   </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Sachin Sood</h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9490956589
                              <br />  <span><a href="mailto:sachinsood@yahoo.com" >sachinsood@yahoo.com   </a> </span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd34.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining   </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Chilamul Narsinhachari Vyankatachari</h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9490956253
                              <br />  <span><a href="mailto:chnchary2012@gmail.com" >chnchary2012@gmail.com   </a> </span></p>

                          </div>
                        </div>







                        {/*         
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd35.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Privacy Preserving of Data in Cloud Computing    </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">V Umasankara Rao</h6>
            <span>Full-Time Research Scholar (IDRBT)  </span>
            <hr/>
            
            <p className="contact-info">  8500266233        
     <br/>  <a href="mailto:shankarit54@gmail.com" >shankarit54@gmail.com   </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd36.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Bioinformatics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Amilpur Santhosh</h6>
            <span>Full-Time Research Scholar (IDRBT)  </span>
            <hr/>
            
            <p className="contact-info">  8466928626        
     <br/>  <a href="mailto:santosh0511@gmail.com" >santosh0511@gmail.com   </a> </p>
            
          </div>
        </div> */}




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd37.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Security</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Manchala Pravali</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  8465970174
                              <br /><span>  <a href="mailto:pravali.m[AT]nitw[DOT]ac[DOT]in " >pravali.m[AT]nitw[DOT]ac[DOT]in    </a></span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd38.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining with Big Data</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">U Shivani Sri Varshini</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  9866585482
                              <br />  <span><a href="mailto:shivanisrivarshini.u@gmail.com" >shivanisrivarshini.u@gmail.com </a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd39.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine learning, Data science, big data, spiking neural network </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Vishnu Kumar</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  8459242253
                              <br /> <span> <a href="mailto:vksingh843025@gmail.com" >vksingh843025@gmail.com </a></span> </p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd40.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Image Processing</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Venkatesh Akula</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  9494038143
                              <br /> <span> <a href="mailto:venkateshakula.mv@gmail.com" >venkateshakula.mv@gmail.com</a> </span></p>

                          </div>
                        </div>









                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd41.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Praveen Guguglothu</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  9652301729
                              <br /> <span> <a href="mailto:ramjijyothi@gmail.com" >ramjijyothi@gmail.com</a></span> </p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd42.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Internet of Things and Block chain</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Bhukya Padma</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  8143683822
                              <br /> <span> <a href="mailto:padmajyo@student.nitw.ac.in" >padmajyo@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd43.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Medical imaging</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Murukessan</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  87547 99558
                              <br />  <span><a href="mailto:muruap87@student.nitw.ac.in" >muruap87@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd44.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Biometrics</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Dilip Kumar Vallabhadas</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  97030 42113
                              <br /> <span> <a href="mailto:vdilip@student.nitw.ac.in" >vdilip@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd45.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Computer Vision and Machine Learning </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Medipelly Rampavan</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  93982 10479
                              <br />  <span><a href="mailto:rampavan58@student.nitw.ac.in" >rampavan58@student.nitw.ac.in  </a> </span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._G._Punnam_Chander_2024-4-2-10-5-6.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Soft Computing Techniques </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">G Punnam Chander</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  97005 85826
                              <br />  <span><a href="mailto:punnamchander@student.nitw.ac.in" >punnamchander@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd47.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Distributed Data Mining and Data Analytics  </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Dheeraj Kodati</h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  90309 78791
                              <br /> <span> <a href="mailto:dheerajkodaticse@student.nitw.ac.in" >dheerajkodaticse@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd48.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data Mining,Big Data Analytics </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">P Ushashree</h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9912056225
                              <br /> <span> <a href="mailto:up720075@student.nitw.ac.in" >up720075@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Image Processing</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Ala Harika </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9676614771
                              <br /><span>  <a href="mailto:ah720077@student.nitw.ac.in" >ah720077@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>








                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd50.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>wireless networks</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Dubba Sudha </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  7893170336
                              <br /> <span> <a href="mailto:ds720078@student.nitw.ac.in" >ds720078@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd51.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine learning</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Hemraj Singh  </h6>
                            <span>IDRBT</span>
                            <hr />

                            <p className="contact-info">  7895817663
                              <br /><span>  <a href="mailto:hs720079@student.nitw.ac.in" >hs720079@student.nitw.ac.in </a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd52.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Software Testing and Program Analysis </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Golla Monika Rani </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  7378880412
                              <br /> <span> <a href="mailto:gm720080@student.nitw.ac.in" >gm720080@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd53.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data, Internet of Things  </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Utukuru Sairam  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  8143364133
                              <br />  <span><a href="mailto:us720081@student.nitw.ac.in" >us720081@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>









                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd54.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Nabendu Bhui </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 8370823809            
     <br/>  <a href="mailto:nb720082@student.nitw.ac.in" >nb720082@student.nitw.ac.in </a> </p>
            
          </div>
        </div> */}








                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd55.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Bioinformatics </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Banoth Prasad   </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info"> 9989370473
                              <br /><span>  <a href="mailto:bp720083@student.nitw.ac.in" >bp720083@student.nitw.ac.in </a></span> </p>

                          </div>
                        </div>








                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd56.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Soft computing </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Chilukamari Rajesh  </h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info"> 9492933548
                              <br /> <span> <a href="mailto:cr720084@student.nitw.ac.in" >cr720084@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd57.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Big Data, High Performance Computing, Machine Learning, Deep Learning  </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Yelleti Vivek  </h6>
                            <span>Full-Time Research Scholar (IDRBT) </span>
                            <hr />

                            <p className="contact-info"> 96529221176
                              <br /> <span> <a href="mailto:yv720085@student.nitw.ac.in" >yv720085@student.nitw.ac.in </a></span></p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd58.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>IoT </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Aguru Aswini Devi </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9493241602
                              <br /><span> <a href="mailto:Aa720086@student.nitw.ac.in" >Aa720086@student.nitw.ac.in</a></span></p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd59.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Cloud Computing, Fog Computing, Algorithms, Computer Networks</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Thanedar Md Asif  </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 9494802627
                              <br /><span> <a href="mailto:tm720089@student.nitw.ac.in" >tm720089@student.nitw.ac.in</a></span></p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd60.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Data mining, Data Analytics, Machine learning, IOT </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Dasari A Rachana  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8886895588
                              <br /> <span><a href="mailto:dasari.rachana@gmail.com" >dasari.rachana@gmail.com</a></span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd61.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Cryptography, distributed computing, IOT </b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> M Prakash  </h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info"> 9110569954
                              <br /><span> <a href="mailto:mp721084@student.nitw.ac.in" >mp721084@student.nitw.ac.in</a></span></p>

                          </div>
                        </div>







                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd62.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Cyber Security</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Gautam Rampalli  </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info"> 9963310199
                              <br /> <span><a href="mailto:gr721085@student.nitw.ac.in" >gr721085@student.nitw.ac.in</a></span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd63.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Banovoth Raja Sekhar  </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info"> 8985385800
                              <br /><span> <a href="mailto:br721086@student.nitw.ac.in" >br721086@student.nitw.ac.in</a></span></p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd64.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Soft Computing</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Satya Shekar Varma P   </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info"> 9666665578
                              <br /><span> <a href="mailto:ss721087@student.nitw.ac.in" >ss721087@student.nitw.ac.in</a></span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd65.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Challa Vijaya M Lakshmi   </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info"> 7674023663
                              <br /><span> <a href="mailto:cv721088@student.nitw.ac.in" >cv721088@student.nitw.ac.in</a></span></p>

                          </div>
                        </div>









                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd66.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Soft Computing</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Srinivas Arukonda   </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  6309060423
                              <br /> <span><a href="mailto:sa721089@student.nitw.ac.in" > sa721089@student.nitw.ac.in  </a> </span></p>

                          </div>
                        </div>






                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd67.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Sheelam Tharun Reddy   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9652655149
                              <br /><span> <a href="mailto:tharunreddy536@gmail.com" > tharunreddy536@gmail.com  </a></span></p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd68.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Venkata Suneetha T   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9618220469
                              <br /><span> <a href="mailto:tv21csrep02@student.nitw.ac.in" > tv21csrep02@student.nitw.ac.in </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd69.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Vedavathi K   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9908024376
                              <br /><span> <a href="mailto:vk21csrep12@student.nitw.ac.in" > vk21csrep12@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd70.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Archana Pallakonda   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9100800810
                              <br /><span> <a href="mailto:ap23csr1r06@student.nitw.ac.in" > ap23csr1r06@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd71.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Shaik Akbar  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9949803625
                              <br /><span> <a href="mailto:sa712141@student.nitw.ac.in" > sa712141@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd72.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Saurabh Gupta   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9460874353
                              <br /><span> <a href="mailto:sg22csr2r01@student.nitw.ac.in" > sg22csr2r01@student.nitw.ac.in </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd73.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Amar Kumar Yadav   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8977927198
                              <br /><span> <a href="mailto:ay712193@student.nitw.ac.in" > ay712193@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd74.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Yerrolla chanti   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9963084035
                              <br /><span> <a href="mailto:yc23csr1p01@student.nitw.ac.in" > yc23csr1p01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd75.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Koyya Deepthi Krishna Yadav   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9059640840
                              <br /><span> <a href="mailto:kd23csr1r04@student.nitw.ac.in" > kd23csr1r04@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd76.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Prasad Emmadi    </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9963028007
                              <br /><span> <a href="mailto:pe21csrep08@student.nitw.ac.in" > pe21csrep08@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd77.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Pedada Saraswathi  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8500962448
                              <br /><span> <a href="mailto:ps23csr1p12@student.nitw.ac.in" > ps23csr1p12@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd78.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Mustyala Sarika   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9666747038
                              <br /><span> <a href="mailto:ms23csr1r02@student.nitw.ac.in" > ms23csr1r02@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd79.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Banothu Ramji  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9177629539
                              <br /><span> <a href="mailto:br22csr2p01@student.nitw.ac.in" > br22csr2p01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd80.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Nalluri Sunny    </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9000461500
                              <br /><span> <a href="mailto:sn712137@student.nitw.ac.in" > sn712137@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd81.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Rachana Behera   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8895491675, 9348395843
                              <br /><span> <a href="mailto:rb23csr1r08@student.nitw.ac.in" > rb23csr1r08@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd82.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Navya   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8790551372
                              <br /><span> <a href="mailto:np23csr1r03@student.nitw.ac.in" > np23csr1r03@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd83.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Manoj Kumar Vaddepalli   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9908943941
                              <br /><span> <a href="mailto:mk22csr2r03@student.nitw.ac.in" > mk22csr2r03@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd84.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Perunalla Praveen   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9032627173
                              <br /><span> <a href="mailto:ppcsr1p11@student.nit.ac.In" > ppcsr1p11@student.nit.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd85.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Pithani Ashwini   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  7382671544
                              <br /><span> <a href="mailto:pa21csrer01@student.nitw.ac.in" > pa21csrer01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd86.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sure Mamatha   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  6304062752
                              <br /><span> <a href="mailto:sm23csr1p03@student.nitw.ac.in" > sm23csr1p03@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd87.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Maddala Janakidevi  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9912523455
                              <br /><span> <a href="mailto:mj21csrep14@student.nitw.ac.in" > mj21csrep14@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd88.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Srikanth Bethu   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9989707702
                              <br /><span> <a href="mailto:sb21csrep04@student.nitw.ac.in" > sb21csrep04@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd89.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Venkata Anusha Kolluru   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9951615749
                              <br /><span> <a href="mailto:21csrep03@student.nitw.ac.in" > 21csrep03@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd90.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Raghu Babu Gangolu   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9886753777
                              <br /><span> <a href="mailto:rb22csr1p02@student.nitw.ac.in" > rb22csr1p02@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd91.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Balingan Sangameshwar    </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9030172123
                              <br /><span> <a href="mailto:bs23csr1p10@student.nitw.ac.in" > bs23csr1p10@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd92.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Syed Nawaz Pasha    </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9959054349
                              <br /><span> <a href="mailto:sn22csr1p01@student.nitw.ac.in" > sn22csr1p01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd93.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Slokashree Padhi   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8763487531
                              <br /><span> <a href="mailto:sp21csres01@student.nitw.ac.in" > sp21csres01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd94.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Gudipati Sravya   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8333975689
                              <br /><span> <a href="mailto:gs21csrer06@student.nitw.ac.in" > gs21csrer06@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd95.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Km Poonam   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9625469757
                              <br /><span> <a href="mailto:kp712140@student.nitw.ac.in" > kp712140@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd96.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Adluri Vijaya Lakshmi    </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8074752546
                              <br /><span> <a href="mailto:av21csrep01@student.nitw.ac.in" > av21csrep01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd97.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Indira Priyadarshini Tummala  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9948511117
                              <br /><span> <a href="mailto:ip21csrep11@student.nitw.ac.in" > ip21csrep11@student.nitw.ac.in </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd98.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Dara Deepthi    </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  7032078176
                              <br /><span> <a href="mailto:dd23csr1r07@student.nitw.ac.in" > dd23csr1r07@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd99.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Bukya Rani   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8008438924
                              <br /><span> <a href="mailto:br21csrer05@stusent.nitw.ac.in" > br21csrer05@stusent.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd100.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> M.Radha   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9741531571
                              <br /><span> <a href="mailto:mr23csr1p13@studenr.nitw.ac.in" > mr23csr1p13@studenr.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd101.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Kopparthi Poorna Sai Rama Krishna    </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9494311669
                              <br /><span> <a href="mailto:kp23csr1p02@student.nitw.ac.in" > kp23csr1p02@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd102.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Puvvula Kiranmai   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9032315262
                              <br /><span> <a href="mailto:pkcs23r1p07@student.nitw.ac.in" > pkcs23r1p07@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd103.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Laxmi Narayana Pondhu    </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8008304439
                              <br /><span> <a href="mailto:ln21csrep15@student.nitw.ac.in" > ln21csrep15@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd104.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Srishti Sakshi Sinha   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9660670329
                              <br /><span> <a href="mailto:ss23csr1r01@student.nitw.ac.in" > ss23csr1r01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd105.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Renuka cheeturi   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  6305511992
                              <br /><span> <a href="mailto:cr22csr1r03@student.nitw.ac.in" > cr22csr1r03@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd106.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> M Shirisha   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8008775776
                              <br /><span> <a href="mailto:SM21CSREP05@student.nitw.ac.in" > SM21CSREP05@student.nitw.ac.in </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd107.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Veerender Aerranagula   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">
                              <br /><span> <a href="mailto:va23csr1p04@student.nitw.ac.in" > va23csr1p04@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd108.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> K Siva Sai Ram Prasad   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9704648566
                              <br /><span> <a href="mailto:ss23csr1p09@student.nitw.ac.in" > ss23csr1p09@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd109.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Varsha Kumari   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9971286806
                              <br /><span> <a href="mailto:vk712138@student.nitw.ac.in " > vk712138@student.nitw.ac.in   </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd110.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Sravanthi Anantoju   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9885589085
                              <br /><span> <a href="mailto:sacsr1p15@student.nitw.ac.in" > sacsr1p15@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd111.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Chakradharamahanthi Madhavi   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8309684220
                              <br /><span> <a href="mailto:cm23csr1p08@student.nitw.ac.in" > cm23csr1p08@student.nitw.ac.in </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd112.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Srilakshmi V  </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8618785372
                              <br /><span> <a href="mailto:sv712134[AT]nitw[DOT]ac[DOT]in" > sv712134[AT]nitw[DOT]ac[DOT]in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd113.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Busireddy Nagarjuna Reddy   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9490133645
                              <br /><span> <a href="mailto:bn23csr1p06@student.nitw.ac.in" > bn23csr1p06@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd114.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Y Gnyanadeepa   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9000399729
                              <br /><span> <a href="mailto:yg21csres02@student.nitw.ac.in" > yg21csres02@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd115.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Jyotsna Garikipati    </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info"> 9985347852
                              <br /><span> <a href="mailto:Jg22csr2p03@student.nitw.ac.in" > Jg22csr2p03@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd116.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> R Shanmuka Shalini   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9951178555
                              <br /><span> <a href="mailto:rs21csrep09@student.nitw.ac.in" > rs21csrep09@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd117.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Alfred Raju M    </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  8374162405
                              <br /><span> <a href="mailto:ar22csr2r02@student.nitw.ac.in"> ar22csr2r02@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd118.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> D Suguna Kumari   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9177537059
                              <br /><span> <a href="mailto:ds21csrep10@student.nitw.ac.in" > ds21csrep10@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd119.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Shailendra Kumar   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  7205371069
                              <br /><span> <a href="mailto:sk22csr1r02@student.nitw.ac.in" > sk22csr1r02@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd120.jpg" className="img-fluid image" alt="" />
                              {/* <div className="overlay">
                <div className="std-details ">
                  <p>
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div> */}
                            </div>
                            <h6 className="std-title"> Dopathi Haritha   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info">  9603221414
                              <br /><span> <a href="mailto:dh23csr1p14@student.nitw.ac.in" > dh23csr1p14@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>
                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Siripuri_Divya_2023-10-16-12-24-54.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Bioinformatics and machine learning.</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Siripuri Divya   </h6>
                            <span>Part-Time Research Scholar   </span>
                            <hr />

                            <p className="contact-info"> 8555012874
                              <br /><span> <a href="mailto:sd22csr2p04@student.nitw.ac.in" > sd22csr2p04@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd121.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Software Engineering with Machine Learning</b>
                                  </p>



                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Chirimilla Ramya   </h6>
                            <span>Full Time (Self Finance)   </span>
                            <hr />

                            <p className="contact-info"> <span> <a href="mailto:cr23csr1s01@student.nitw.ac.in" > cr23csr1s01@student.nitw.ac.in  </a></span></p>

                          </div>
                        </div>

                        
                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="CSEDeptAssets/images/phd122.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p>
                                    Research Area : <b>Computer Vision & Image Processing</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mahomad Riyaz</h6>
                            <span>Part-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  9493814843
                              <br />  <span><a href="mailto:mr23csr1p05@student.nitw.ac.in" >mr23csr1p05@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>




























                      </div>



                    </div>
                  </div>
                </div>
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">
                  <div className="row std-society">
                    {/* <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                        target="_blank"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16345"
                          target="_blank">
                          <h6>Prof. R. Padmavathy</h6> <span> Faculty Advisor </span>
                          <hr />
                        </a>
                          <p> <i className="fa fa-phone" aria-hidden="true"></i> 9490165347 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:cse_hod[AT]nitw[DOT]ac[DOT]in"
                              style={{ color: "#808080" }}>cse_hod[AT]nitw[DOT]ac[DOT]in</a> </p>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://erp.nitw.ac.in/ext/profile/cs-ravic"
                        target="_blank"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://erp.nitw.ac.in/ext/profile/cs-ravic"
                          target="_blank">
                          <h6>Prof. S. Ravi Chandra</h6> <span> Faculty Advisor </span>
                          <hr />
                        </a>
                          <p> <i className="fa fa-phone" aria-hidden="true"></i> 8702462730 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:ravic[AT]nitw[DOT]ac[DOT]in"
                              style={{ color: "#808080" }}>ravic[AT]nitw[DOT]ac[DOT]in</a> </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                        target="_blank"> <img src="CSEDeptAssets/images/13.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://erp.nitw.ac.in/ext/profile/cs-usnraju"
                          target="_blank">
                          <h6>Prof. U. S. N. Raju</h6> <span> Treasurer </span>
                          <hr />
                        </a>
                          <p> <i className="fa fa-phone" aria-hidden="true"></i> 9440149146 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:usnraju[AT]nitw[DOT]ac[DOT]in"
                              style={{ color: "#808080" }}>usnraju[AT]nitw[DOT]ac[DOT]in</a> </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">


                  <div className="row notable-alumni">
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CSEDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr.Pushmeet Kohli</h6>

                          <p><b>Batch:</b> B.Tech(CSE)(2004)<br />
                            <b>Present Position:</b> Principal Scientist and Team Lead, DeepMind,
                            London, UK
                          </p>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Prof. Pavithra Prabhakar</h6>

                          <p><b>Batch: </b>B.Tech(CSE)(2004)<br />
                            <b>Present Position: </b>Professor, Kansas State University, Manhattan,
                            United States
                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="CSEDeptAssets/images/vrajanna.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>V Rajanna</h6>



                          <p>
                            <b>Present Position :</b> GM & VP, TCS- Hyderabad


                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="CSEDeptAssets/images/aarthi.jpg  " alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Aarthi Subramanyan</h6>
                          <p>
                            <b>Present Position :</b> Group Chief Digital Officer, Tata Sons Private
                            Limited, Mumbai
                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="CSEDeptAssets/images/murali.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Prof. Murali Annavaram</h6>
                          <p>
                            <b>Present Position:</b>Professor at University of Southern California

                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/*<img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>*/}
                        <div className="data">
                          <h6>Sridhar Muppala</h6>



                          <p>
                            <b>Present Position :</b> CEO, Silicon Valley

                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/*<img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>*/}
                        <div className="data">
                          <h6>Movva Ramu</h6>
                          <p>
                            <b>Present Position :</b> Microsoft Research Centre, USA
                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/*<img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>*/}
                        <div className="data">
                          <h6>K V Sridhar</h6>
                          <p>
                            <b>Present Position :</b> CEO Analytics CA, USA
                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>




                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/*<img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>*/}
                        <div className="data">
                          <h6>Jitendra Lakotia</h6>
                          <p>
                            <b>Present Position :</b> Former VP of Cognos. USA
                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/*<img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>*/}
                        <div className="data">
                          <h6>Amarnadh Reddy</h6>
                          <p>
                            <b>Present Position :</b> CTO, CGG, Hyderabad
                          </p>
                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        {/*<img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>*/}
                        <div className="data">
                          <h6>Feroz khan</h6>
                          <p>
                            <b>Present Position: </b>Dy.Director, SAP Labs

                          </p>

                          {/*<p> Peggy and Gary Edwards Chair in Engineering,<br/>Professor, Kansas State University, Manhattan, United States</p>*/}
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                  aria-labelledby="v-pills-AcademicResearchGroup-tab">
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Big Data Analytics, Artificial Intelligence / Machine Learning, Data Science
                        </h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/5.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-rbvs66" target="_blank">Prof. R. B. V. Subramaanyam</a></h6>
                            <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9491346969 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rbvs66[AT]nitw[DOT]ac[DOT]in">rbvs66[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/6.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-prkrishna" target="_blank">Prof. P. Radha Krishna</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9704988816 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:prkrishna[AT]nitw[DOT]ac[DOT]in">prkrishna[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/11.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-trk" target="_blank">Prof. T. Ramakrishnudu</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9866876842 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:trk[AT]nitw[DOT]ac[DOT]in">trk[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/14.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-kadambari" target="_blank">Prof. K. V. Kadambari</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9705460461<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kvkadambari[AT]nitw[DOT]ac[DOT]in">kvkadambari[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/22.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-msv" target="_blank">Prof. M. Srinivas</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8897064421<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:msv[AT]nitw[DOT]ac[DOT]in">msv[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/27.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-venkat.kagita" target="_blank">Prof. Venkateswara Rao Kagita</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 6281746931 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:venkat.kagita[AT]nitw[DOT]ac[DOT]in">venkat.kagita[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Security</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/9.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-rpadma" target="_blank">Prof. R. Padmavathy</a></h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9381265691 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rpadma[AT]nitw[DOT]ac[DOT]in">rpadma[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-rashrr" target="_blank">Prof. Rashmi Ranjan Rout</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969418<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rashrr[AT]nitw[DOT]ac[DOT]in">rashrr[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-ravic" target="_blank">Prof. S. Ravi Chandra</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969414 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ravic[AT]nitw[DOT]ac[DOT]in">ravic[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/21.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-msandhya" target="_blank">Prof. M. Sandhya</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9394468554<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:msandhya[AT]nitw[DOT]ac[DOT]in">msandhya[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/23.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-ilaiahkavati" target="_blank">Prof. Ilaiah Kavati</a> </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9848916272 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ilaiahkavati[AT]nitw[DOT]ac[DOT]in">ilaiahkavati[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-esbabu" target="_blank">Prof. E. Suresh Babu</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440959713 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:esbabu[AT]nitw[DOT]ac[DOT]in">esbabu[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Networks</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-rashrr" target="_blank">Prof. Rashmi Ranjan Rout</a></h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969418<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rashrr[AT]nitw[DOT]ac[DOT]in">rashrr[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-kramesh" target="_blank">Prof. K. Ramesh</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9849432598 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kramesh[AT]nitw[DOT]ac[DOT]in">kramesh[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-sriramk" target="_blank">Prof. Sriram Kailasam</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8894275490 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sriramk[AT]nitw[DOT]ac[DOT]in">sriramk[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-bprao" target="_blank">Prof. Balaprakasa Rao Killi</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7002457102 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:bsprao[AT]nitw[DOT]ac[DOT]in">bsprao[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-esbabu" target="_blank">Prof. E. Suresh Babu</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440959713 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:esbabu[AT]nitw[DOT]ac[DOT]in">esbabu[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Software Engineering</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/1.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-ravic" target="_blank">Prof. S. Ravi Chandra</a></h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969414 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ravic[AT]nitw[DOT]ac[DOT]in">ravic[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-kramesh" target="_blank">Prof. K. Ramesh</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9849432598 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kramesh[AT]nitw[DOT]ac[DOT]in">kramesh[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/16.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-priyankac" target="_blank">Prof. Priyanka Chawla</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7982750652 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:priyankac[AT]nitw[DOT]ac[DOT]in">priyankac[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/18.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-manjubalabisi" target="_blank">Prof. Manjubala Bisi</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9502940360 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:esbabu[AT]nitw[DOT]ac[DOT]in">manjubalabisi[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/29.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-sanghu" target="_blank">Prof. Sangharatna Godboley</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7013306805<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:cse_hod[AT]nitw[DOT]ac[DOT]in">sanghu[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Cloud & Distributed Computing, IOT</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/8.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-chapram" target="_blank">Prof. Ch. Sudhakar</a></h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440647945 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:chapram[AT]nitw[DOT]ac[DOT]in">chapram[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/10.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-rashrr" target="_blank">Prof. Rashmi Ranjan Rout</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969418<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rashrr[AT]nitw[DOT]ac[DOT]in">rashrr[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/17.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-sriramk" target="_blank">Prof. Sriram Kailasam</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8894275490 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sriramk[AT]nitw[DOT]ac[DOT]in">sriramk[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/25.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-sanjaya" target="_blank">Prof. Sanjaya Kumar Panda</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9861126947<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sanjay[AT]nitw[DOT]ac[DOT]in">sanjay[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/20.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-esbabu" target="_blank">Prof. E. Suresh Babu</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440959713 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:esbabu[AT]nitw[DOT]ac[DOT]in">esbabu[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/26.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-bprao" target="_blank">Prof. Balaprakasa Rao Killi</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7002457102 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:bsprao[AT]nitw[DOT]ac[DOT]in">bsprao[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Algorithms, BioInformatics, Computer Vision, Image Processing, Model-driven
                          Frameworks, Soft Computing</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/7.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-kramesh" target="_blank">Prof. K. Ramesh</a></h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9849432598 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kramesh[AT]nitw[DOT]ac[DOT]in">kramesh[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/12.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-raju" target="_blank">Prof. Raju Bhukya</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9700553922 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:raju[AT]nitw[DOT]ac[DOT]in">raju[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/15.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-pvsr" target="_blank">Prof. P. Venkata Subba Reddy</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8790590436<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:pvsr[AT]nitw[DOT]ac[DOT]in">pvsr[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/13.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-usnraju" target="_blank">Prof. U. S. N. Raju</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440149146 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:usnraju[AT]nitw[DOT]ac[DOT]in">usnraju[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/19.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-iep" target="_blank">Prof. I. Earnest Paul</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9494466490<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:iep[AT]nitw[DOT]ac[DOT]in">iep[AT]nitw[DOT]ac[DOT]in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/24.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-sujit.das" target="_blank">Prof. Sujit Das</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8536009758<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sujit.das[AT]nitw[DOT]ac[DOT]in">sujit.das[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="CSEDeptAssets/images/28.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0"><a href="https://erp.nitw.ac.in/ext/profile/cs-rmlswamy" target="_blank">Prof. Ramalingaswamy Cheruku</a></h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9773827143 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rmlswamy[AT]nitw[DOT]ac[DOT]in">rmlswamy[AT]nitw[DOT]ac[DOT]in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className={`tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  `} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>


                {/* Events */}
                {/* <div className={`tab-pane ${activeTab === 'v-pills-Events' ? 'show active' : ''} fade  `} id="v-pills-Events" role="tabpanel"
                  aria-labelledby="v-pills-Events-tab">
                    
                </div> */}


                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">








                  <div className="details">
                    <h4>2+4 Mixer </h4>
                    <span>14th October 2023</span><br />

                    <p>CSES hosted a captivating mixer, bridging the gap between the 2nd and 4th year CSE students. The event aimed to help the second year students build connections and gain valuable insights from those who had successfully navigated a similar academic path.</p>
                    <p>A focal point of the evening was the discussion on internship preparation. The fourth year students shared their experiences, offering practical advice on crafting resumes and mastering interviews. The event addressed any lingering doubts in the minds of second year students, empowering them to approach their academic journey with confidence.</p>

                  </div>
                  <img src="CSEDeptAssets/images/pg13.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg14.jpg" alt="" className="img-fluid mb-5" />
                  {/* <img src="CSEDeptAssets/images/pg15.jpg" alt="" className="img-fluid mb-5" /> */}





                  <div className="details">
                    <h4>KYB </h4>
                    <span>7th October 2023</span><br />

                    <p>In the dynamic realm of academia, it is important to know how the journey ahead unfolds, acquainting a new environment and a whole new world out there. CSES recognizes the need for easing the transition and addressing the crucial needs of the newly joined first-year Computer Science and Engineering students.</p>
                    <p>This event was conducted by the second year CSE students under the guidance of the seniors, wherein they explained the prototype of the voyage ahead to the freshers. They introduced various fields such as AI/ML, blockchain and competitive programming, along with the different opportunities available for internships, placements, research and higher studies, and how to prepare for them. </p>

                  </div>
                  <img src="CSEDeptAssets/images/pg8.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg9.jp`g" alt="" className="img-fluid mb-5" />






                  <div className="details">
                    <h4>Inaugural Ceremony </h4>
                    <span>5th October 2023</span><br />

                    <p>CSES's inaugural saw an astounding turn out as it opened for the new academic year, 2023-24. The event was concise but impactful, featuring enlightening speeches from distinguished guests.</p>
                    <p>The event began with the symbolic lighting of the lamp and the presentation of plants to esteemed guests. Prof. D. Srinivasacharya, the Chief Guest and Dean of Student Welfare, encouraged students to explore opportunities to enhance their programming and problem-solving skills. He also explained the working of the Society. Prof. R. Padmavathi (Head of Department - CSE), Prof. Ravi Chandra (Faculty Advisor) and Prof. U.S.N. Raju (Treasurer) were also present for the event, which marked the beginning of an exciting journey for the latest team of CSES.</p>

                  </div>

                  <img src="CSEDeptAssets/images/pg1.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg2.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg3.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg4.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg5.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg6.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg7.jp`g" alt="" className="img-fluid mb-5" />



                  <div className="details">
                    <h4>Gradvine</h4>
                    <span>1th September 2023</span><br />

                    <p>CSES, in collaboration with Gradvine, conducted 'A to Z of Studying Abroad' - a seminar on pursuing overseas education. Gradvine is a leading college application and education consulting platform and has guided many students to secure spots in the top universities.</p>
                    <p>Mr. Sreekar Sannapareddy, an alumnus of Dartmouth College and co-founder of Gradvine, was the main speaker. He provided valuable insights on the diverse career options and academic opportunities abroad. There was an impressive turnout of over 120 enthusiastic participants thereby making the event a huge success. Additionally, a free One-on-One guidance session from Ivy League graduates was made available to all the webinar attendees.</p>

                  </div>
                  <img src="CSEDeptAssets/images/pg10.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg11.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg12.jpg" alt="" className="img-fluid mb-5" />



                  <div className="details">
                    <h4>Second International Conference on Computing, Communication and Learning (CoCoLe) </h4>
                    <span>29th to 31st August 2023</span>
                  </div>

                  <img src="CSEDeptAssets/images/pg15.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg16.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg17.jpg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/pg18.jpg" alt="" className="img-fluid mb-5" />







                  <div className="details">
                    <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                    <span>19th to 30th September 2022</span>
                  </div>

                  <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5" />
                  <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5" />



                </div>


                {/* Awards and Honors Content */}
                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">
                  <div className="row">



                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/25.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Sanjaya Kumar Panda</h6>
                            <span>IEI Young Engineers Award 2023</span><div className=" mt-2">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/29.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Sangharatna Godboley</h6>
                            <span>Recognition in the Research Excellence (Teaching Faculty - National)</span>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/25.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Sanjaya Kumar Panda</h6>
                            <span>Best Paper Award (ICADML 2021)</span>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/27.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Venkateswara Rao Kagita</h6>
                            <span>SIRE 2023</span>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/27.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Venkateswara Rao Kagita</h6>
                            <span>IEEE Senior Member</span>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/29.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Sangharatna Godboley</h6>
                            <span>SERB International Travel Grant</span>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/2.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. D V L N Somayajulu</h6>
                            <span>Lifetime Achievement Award by Bharat Education Excellence</span>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 mb-3">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src="CSEDeptAssets/images/10.jpg" alt="" className="img-fluid" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Rashmi Ranjan Rout</h6>
                            <span>Best Paper Award (INDICON 2021)</span>
                          </div>
                        </div>
                      </div>
                    </div>









                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                <p>Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default CSEDeptPage;
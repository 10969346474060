import { Typography } from "@mui/material";
import React from "react";
import Slideshow from "./SlideShow";
import "@brainhubeu/react-carousel/lib/style.css";
import imagesData from '../constants/placements/placementScrollImages'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import TopRecruiters from "./TopRecruiters";
import PlacementCharts from "./PlacementCharts";
import StudentPlace from '../constants/StudentPlaced.jpg';
import HigestCtc from '../constants/HighestCtc.jpg';

import "../components/Styles.css";
import { OldServerUrl } from "../BasePath";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);




var placement_stats = [
  {
    year: 2023,
    avg: 17.29,
    placed: 1404,
    max: 88.0,
    link: "https://oldweb.nitw.ac.in/media/uploads/2023/04/28/impetus.pdf",
  },

  {
    year: 2022,
    avg: 17.0,
    placed: 1108,
    max: 62.5,
    link: "https://oldweb.nitw.ac.in/media/uploads/2022/06/06/placement-statistics-2021-2022docx.pdf",
  },
  // {
  //   year: 2021,
  //   avg: 13.0,
  //   max: 51.0,
  //   link: "https://nitw.ac.in/media/uploads/2021/07/15/placement-statistics-2020-2021_to-upload.pdf",
  // },
  // {
  //   year: 2020,
  //   avg: 17,
  //   max: 43.31,
  //   link: "https://nitw.ac.in/media/uploads/2020/10/18/2019-20-placement-stats_website.pdf",
  // },
  // {
  //   year: 2019,
  //   avg: 16,
  //   max: 45,
  //   link: `${OldServerUrl}/main/placemtents/ay18-19/`,
  // },
  // {
  //   year: 2018,
  //   avg: 17,
  //   max: 39,
  //   link: `${OldServerUrl}/main/Placement/Statistics2017-18/`,
  // },
];

// No of Students Placed in 2019 - 20 : 792
// No of Students Placed in 2020 - 21 : 815
// No of Students Placed in 2021 - 22 : 1108

// No of Students Placed in 2022 - 23 : 1404

const placementStat2=  [
   {
    year: 2021,
    avg: 13.0,
    max: 51.0,
    placed: 815,
    link: "https://nitw.ac.in/media/uploads/2021/07/15/placement-statistics-2020-2021_to-upload.pdf",
  },
  {
    year: 2020,
    avg: 17,
    max: 43.31,
    placed: 792,
    link: "https://nitw.ac.in/media/uploads/2020/10/18/2019-20-placement-stats_website.pdf",
  },
]


const PlacementCard2 = () => {
  
  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginTop: "5vh", backgroundColor: "#fff" }}>
        <Typography
          variant="h3"
          fontWeight="bold"
          margin="auto"
          textAlign="center"
          // color="#b80924"
          color="#b80924"
          paddingTop="3vh"
        >
          Our Placements
        </Typography>
        {/* <div className="text-center"><a href="https://nitw.ac.in/path/?dept=/placement" target="_blank" className="text-center" style={{fontWeight:"bold",color:'#269ff9',textDecoration:"underline" }}>Visit Centre for Career Planning and Development</a> */}
        <div className="text-center"><a href="https://nitw.ac.in/ccpd" target="_blank" className="text-center" style={{fontWeight:"bold",color:'#269ff9',textDecoration:"underline" }}>Visit Centre for Career Planning and Development</a>
        </div>
           {/* Add slideshow here */}
           <div className="row"style={{padding:"10px"}}>
          {/* <div className="col-md-3 col-sm-12 " style={{paddingTop:"30px"}} ><img src={StudentPlace}/></div> */}
          {/* <div className="col-md-3 col-sm-12 " style={{paddingTop:"30px",paddingBottom:"20px"}} ><img src={HigestCtc}/></div> */}
          <div className="col-md-6 col-sm-12 " >
            <PlacementCharts/>
            {/* <TopRecruiters/> */}
            </div>
          <div className="col-md-6 col-sm-12 " ><Slideshow images={imagesData} /></div>
           </div>
           
      </div>
    </ThemeProvider>
  );
};

export default PlacementCard2;
